import React from 'react';
import { Grid, Dropdown, Menu, Button } from 'antd';
import {
    MenuOutlined,
    DownOutlined,
    ArrowLeftOutlined,
} from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from '../../hooks';
import authService from '../../services/authService';
import { StyledHeader, StyledTitle, StyledSpace, MenuButton } from './styles';

const { useBreakpoint } = Grid;
export interface NavBarProps {
    children?: React.ReactNode;
}

const NavBar = ({ children }: NavBarProps) => {
    const screen = useBreakpoint();
    const { store } = useStore();
    const user = store.authStore.currentUser;

    const logout = () => {
        authService.Logout();
    };

    const menu = (
        <Menu>
            <Menu.Item key="settings">
                <Link to="/settings#profile">
                    <FormattedMessage
                        id="app.navbar.menu.user-settings"
                        defaultMessage="Minhas configurações"
                    />
                </Link>
            </Menu.Item>
            <Menu.Item key="logout">
                <Link to="/login" onClick={logout}>
                    <FormattedMessage
                        id="app.navbar.menu.logout"
                        defaultMessage="Sair"
                    />
                </Link>
            </Menu.Item>
        </Menu>
    );

    return (
        <StyledHeader>
            {(screen.xs ||
                (screen.sm && !screen.lg) ||
                store.uiStateStore.showBackButton) && (
                <>
                    {store.uiStateStore.backAction ? (
                        <Button
                            type="text"
                            icon={<ArrowLeftOutlined />}
                            onClick={() => store.uiStateStore.backAction()}
                            style={{ marginRight: 9 }}
                        />
                    ) : (
                        <Button
                            type="text"
                            icon={<MenuOutlined />}
                            onClick={() => store.uiStateStore.toggleSider()}
                            style={{ marginRight: 9 }}
                        />
                    )}
                </>
            )}

            <StyledTitle>
                {store.uiStateStore.navBarMobileTitle && (
                    <FormattedMessage
                        id={store.uiStateStore.navBarMobileTitle}
                        defaultMessage=""
                    />
                )}
            </StyledTitle>

            <StyledSpace size="small">
                {children}

                <Dropdown overlay={menu}>
                    <MenuButton type="link" onClick={e => e.preventDefault()}>
                        {!screen.xs && (
                            <span className="ant-avatar-user">
                                {user?.name} <DownOutlined />
                            </span>
                        )}
                    </MenuButton>
                </Dropdown>
            </StyledSpace>
        </StyledHeader>
    );
};

NavBar.defaultProps = {
    children: null,
};
export default observer(NavBar);
