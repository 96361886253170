import api from './api';
// import history from '../helpers/history';

interface AccountData {
    name: string;
    email: string;
    cpf?: string;
    password?: string;
}

interface AccountGoogleData {
    name: string;
    cpf?: string;
}

interface Response {
    success: boolean;
    status?: number;
    error?: string;
}

async function createAccount({
    name,
    email,
    cpf,
    password,
}: AccountData): Promise<Response> {
    try {
        await api.post('/api/v2/users', {
            name,
            email,
            cpf,
            password,
        });
        return { success: true };
    } catch (err: any) {
        return {
            success: false,
            status: err.response.data.status,
            error: err.response.data.error,
        };
    }
}

async function createAccountWithGoogle(
    { name, cpf }: AccountGoogleData,
    tokenId: string,
): Promise<Response> {
    try {
        await api.post('/api/v2/users/register-with-google', {
            name,
            tokenId,
            cpf,
        });
        return { success: true };
    } catch (err: any) {
        return {
            success: false,
            status: err.response.status,
            error: err.response.data.message,
        };
    }
}

// async function resendCode(email: string): Promise<Response> {
//     try {
//         await api.post('/reSendCode', { email, testemail: email });
//         return { success: true };
//     } catch (err) {
//         return { success: false };
//     }
// }

// async function validateCode(code: string): Promise<Response> {
//     try {
//         await api.post('/enableUser', { token: code.toLowerCase() });
//         return { success: true };
//     } catch (err) {
//         return { success: false };
//     }
// }

// async function savePassword(
//     email: string,
//     password: string,
//     optIn: boolean,
// ): Promise<Response> {
//     try {
//         await api.post('/create-password', { email, password, optIn });
//         return { success: true };
//     } catch (err) {
//         return {
//             success: false,
//             status: err.response.status,
//             error: err.response.data,
//         };
//     }
// }

function Logout(): void {
    localStorage.clear();
}

export default {
    createAccount,
    createAccountWithGoogle,
    // resendCode,
    // validateCode,
    // savePassword,
    Logout,
};
