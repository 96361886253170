/* eslint-disable @typescript-eslint/no-unused-vars */
import { action, observable, makeAutoObservable } from 'mobx';

export default class SubjectSearchStore {
    @observable searchResponse;

    @observable returnedSelfie;

    rootStore;

    transportLayer;

    // eslint-disable-next-line
    constructor(rootStore, transportLayer) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
        this.transportLayer = transportLayer;
    }

    // eslint-disable-next-line
    @action async searchSubject(subjectSearchData): Promise<any> {
        const response = await this.transportLayer.searchSubject(
            subjectSearchData,
        );
        this.setReturnedSelfie(response.selfie);
        delete response.selfie;
        this.setSearchResponse(response);
        return response;
    }

    // eslint-disable-next-line
    @action setSearchResponse(searchResponse): void {
        this.searchResponse = searchResponse;
    }

    // eslint-disable-next-line
    @action setReturnedSelfie(selfie): void {
        this.returnedSelfie = selfie;
    }
}
