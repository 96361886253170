import { ShareAltOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const StyledFooter = styled.div`
    min-height: 24px;
    width: 100%;
    margin-left: -48px;

    display: flex;
    justify-content: center;

    z-index: 1;
    position: fixed;
    bottom: 0;

    background: #fff;

    @media (min-width: 576px) and (max-width: 991px) {
        width: 100%;
        height: 80px;
        padding: 0 48px 0 48px;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        border-top: 2px;
        border-top-style: solid;
        border-top-color: #d9d9d9;
    }

    @media (min-width: 992px) {
        width: 80%;
        height: 80px;
        padding: 0 48px 0 48px;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        border-top: 2px;
        border-top-style: solid;
        border-top-color: #d9d9d9;
    }

    @media (min-width: 1370px) {
        width: 100%;
        padding-right: 20%;
    }
`;

export const StyledMobileButtons = styled.div`
    display: flex;
    justify-content: center;

    width: 100%;
    padding: 12px 0 8px 0;
    border-top: 1px solid #bfbfbf;
`;

export const StyledMobileButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 12px 0 12px;
    > button {
        width: 40px;
        height: 40px;
    }
`;

export const StyledShareAltOutlined = styled(ShareAltOutlined)`
    font-size: 18px;
`;

export const StyledDesktopButtons = styled.div`
    display: flex;
    flex-wrap: wrap;

    > button {
        margin-top: 5px;

        &:not(:last-child) {
            margin-right: 8px;
        }

        &:first-child {
            svg {
                margin-right: 5px;
            }
        }

        &:not(:first-child) {
            svg {
                margin-left: 5px;
            }
        }
    }
`;
