import React from 'react';
import { Switch } from 'react-router-dom';
// import Recover from 'pages/public/Recover';
// import Register from '../pages/public/Register';
import PageNotFound from 'pages/public/PageNotFound';
import Facematch from 'pages/private/Facematch';
import Login from '../pages/public/Login';
import RegisterWithGoogle from '../pages/public/RegisterWithGoogle';
import AppRoute from './AppRoute';
import EmptyLayout from '../pages/_layouts/EmptyLayout';
import DashboardLayout from '../pages/_layouts/DashboardLayout';
import Settings from '../pages/private/Settings';
import SubjectValidation from '../pages/private/SubjectValidation';
import Subject from '../pages/private/Subject';

function Routes(): React.ReactElement {
    return (
        <Switch>
            {/* Not Authenticated */}
            <AppRoute
                exact
                path={['/login', '/']}
                component={Login}
                layout={EmptyLayout}
                isPrivate={false}
                redirectTo="/dashboard"
                titleId="aa"
            />
            {/* Remove comments after homolog */}
            {/* <AppRoute
                path="/create-account"
                component={Register}
                layout={EmptyLayout}
                isPrivate={false}
                redirectTo="/dashboard"
                titleId="aa"
            /> */}
            <AppRoute
                path="/create-account-with-google"
                component={RegisterWithGoogle}
                layout={EmptyLayout}
                isPrivate={false}
                redirectTo="/dashboard"
                titleId="aa"
            />
            {/* Remove comments after implemeting email */}
            {/* <AppRoute
                path="/recover-password"
                component={Recover}
                layout={EmptyLayout}
                isPrivate={false}
                redirectTo="/dashboard"
                titleId="aa"
            /> */}
            {/* Authenticated */}
            <AppRoute
                exact
                path="/dashboard"
                component={SubjectValidation}
                layout={DashboardLayout}
                isPrivate
                redirectTo="/dashboard"
                titleId="app.route.dashboard.title"
            />
            <AppRoute
                exact
                path="/settings"
                component={Settings}
                layout={DashboardLayout}
                isPrivate
                redirectTo="/dashboard"
                titleId="app.route.settings.title"
            />
            <AppRoute
                exact
                path="/subject"
                component={Subject}
                layout={DashboardLayout}
                isPrivate
                redirectTo="/subject"
                titleId="app.route.enroll.title"
            />
            <AppRoute
                exact
                path="/facematch"
                component={Facematch}
                layout={DashboardLayout}
                isPrivate
                redirectTo="/facematch"
                titleId="app.route.facematch.title"
            />
            <AppRoute
                path="*"
                component={PageNotFound}
                layout={EmptyLayout}
                titleId="app.route.dashboard.title"
                redirectTo="/dashboard"
                isPrivate={false}
            />
        </Switch>
    );
}

export default Routes;
