import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Tabs, Grid } from 'antd';
import { FileDoneOutlined, FormOutlined } from '@ant-design/icons';
import { useStore } from 'hooks';
import { observer } from 'mobx-react';
import SubjectValidationForm from './Form';
import { Container } from './styles';
import Result from './Result';

const { TabPane } = Tabs;
const { useBreakpoint } = Grid;

const SubjectValidation = (): React.ReactElement => {
    const screen = useBreakpoint();
    const history = useHistory();
    const location = useLocation();
    const [selectedTab, setSelectedTab] = useState('#form');
    const { store } = useStore();
    const [resultExists, setResultExists] = useState(false);

    useEffect(() => {
        if (location.hash) {
            setSelectedTab(location.hash);
        } else {
            const hash = '#form';
            setSelectedTab(hash);
            history.replace(hash);
        }
    }, [history, location.hash]);

    useEffect(() => {
        if (store.subjectValidationStore.result) {
            setResultExists(true);
        } else {
            setResultExists(false);
        }
    }, [store.subjectValidationStore.result]);

    const handleTabChange = key => {
        setSelectedTab(key);
        history.push(key);
    };

    return (
        <Container mobile={!screen.md}>
            <Tabs activeKey={selectedTab} onChange={handleTabChange}>
                <TabPane
                    tab={
                        <>
                            <FormOutlined />
                            <FormattedMessage
                                id="app.subject-validation.result.form"
                                defaultMessage="Formulário"
                            />
                        </>
                    }
                    key="#form"
                    style={{
                        padding: !screen.md ? '32px 20px 40px 20px' : '0px',
                    }}
                >
                    <SubjectValidationForm handleTabChange={handleTabChange} />
                </TabPane>
                <TabPane
                    tab={
                        <>
                            <FileDoneOutlined />
                            <FormattedMessage
                                id="app.subject-validation.result.result"
                                defaultMessage="Resultado"
                            />
                        </>
                    }
                    key="#result"
                    disabled={!resultExists}
                    style={{
                        padding: !screen.md ? '32px 20px 40px 20px' : '0px',
                    }}
                >
                    <Result />
                </TabPane>
            </Tabs>
        </Container>
    );
};

export default observer(SubjectValidation);
