import React from 'react';
import { Layout } from 'antd';

import { StyledLayout } from './styles';

const { Content } = Layout;

interface EmptyLayoutProps {
    children: React.ReactChildren;
}

const EmptyLayout = ({ children }: EmptyLayoutProps): React.ReactElement => {
    return (
        <StyledLayout>
            <div id="modal-root" />
            <Content>{children}</Content>
        </StyledLayout>
    );
};

export default EmptyLayout;
