import {
    UploadOutlined,
    CameraOutlined,
    DeleteOutlined,
} from '@ant-design/icons';
import { Button, message, Modal, Image, Tooltip } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useStore } from 'hooks';
import { StyledTakePhotoButton } from 'pages/private/SubjectValidation/Form/styles';
import React, { useCallback, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactJson from 'react-json-view';
import { FileUpload } from '../Subject/EnrollForm/styles';
import WebcamCapture from '../../../components/Webcam';
import { Container } from '../Subject/styles';
import { ImagePreview, ResultContent, ResultData } from './styles';
import { imagePlaceHolder, cnhImagePlaceHolder } from '../../../helpers/images';

interface ImageContent {
    file: File | Blob | undefined;
    preview: string | undefined;
}
const Facematch = (): React.ReactElement => {
    const screen = useBreakpoint();
    const hiddenFileInputRef = useRef<HTMLInputElement>(null);
    const hiddenFileInputRefSelfie = useRef<HTMLInputElement>(null);
    const { store } = useStore();
    const [doc, setDoc] = useState<ImageContent | undefined>();
    const [isTakeDocPhotoVisible, setIsTakeDocPhotoVisible] = useState(false);
    const [selfie, setSelfie] = useState<ImageContent | undefined>();
    const [verifyLoading, setVerifyLoading] = useState(false);
    const [isTakeSelfieVisible, setIsTakeSelfieVisible] = useState(false);
    const [result, setResult] = useState(null);
    const { formatMessage } = useIntl();

    const handleClickUploadDocPhotoButton = () => {
        hiddenFileInputRef.current?.click();
    };

    const handleClickUploadSelfieButton = () => {
        hiddenFileInputRefSelfie.current?.click();
    };

    const showTakeDocPhotoModal = () => {
        setIsTakeDocPhotoVisible(true);
    };

    const showTakeSelfieModal = () => {
        setIsTakeSelfieVisible(true);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleRemoveDocPhoto = () => {
        if (doc) setDoc(undefined);
        store.subjectVerifyWithDocStore.setDocPhoto(undefined);
    };

    const handleRemoveSelfie = () => {
        if (selfie) setSelfie(undefined);
        store.subjectVerifyWithDocStore.setSelfie(undefined);
    };

    const convertBase64 = useCallback(file => {
        return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                // const base64Img = fileReader.result.
                resolve(fileReader.result);
            };

            fileReader.onerror = error => {
                reject(error);
            };
        });
    }, []);

    const uploadImage = useCallback(
        async (e, type: 'selfie' | 'doc') => {
            if (e.target.files[0]) {
                const file = e.target.files[0];
                if (file.type === 'image/jpeg' || file.type === 'image/png') {
                    const base64Img = await convertBase64(file);
                    switch (type) {
                        case 'doc':
                            setDoc({
                                file,
                                preview: base64Img?.toString(),
                            });
                            store.subjectVerifyWithDocStore.setDocPhoto(
                                base64Img?.toString(),
                            );
                            break;
                        case 'selfie':
                            setSelfie({
                                file,
                                preview: base64Img?.toString(),
                            });
                            store.subjectVerifyWithDocStore.setSelfie(
                                base64Img?.toString(),
                            );
                            break;

                        default:
                            break;
                    }
                } else {
                    message.error(
                        formatMessage({
                            id: 'app.settings.profile.errors.file-type',
                            defaultMessage:
                                'Você só pode fazer upload de arquivos JPG/PNG.',
                        }),
                    );
                }
            }
        },
        [convertBase64, formatMessage, store.subjectVerifyWithDocStore],
    );

    const DataURIToBlob = (dataURI: string) => {
        const splitDataURI = dataURI.split(',');
        const byteString =
            splitDataURI[0].indexOf('base64') >= 0
                ? atob(splitDataURI[1])
                : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i += 1)
            ia[i] = byteString.charCodeAt(i);

        return new Blob([ia], { type: mimeString });
    };

    const handleTakeDocPhoto = () => {
        setDoc({
            file: DataURIToBlob(store.subjectVerifyWithDocStore.docPhoto),
            preview: store.subjectVerifyWithDocStore.docPhoto,
        });
        if (store.subjectVerifyWithDocStore.docPhoto) {
            setIsTakeDocPhotoVisible(false);
        }
    };

    const handleTakeDocPhotoCancel = () => {
        setIsTakeDocPhotoVisible(false);
        store.subjectVerifyWithDocStore.setDocPhoto(undefined);
    };

    const handleStoreDocPhoto = webcamPhoto => {
        store.subjectVerifyWithDocStore.setDocPhoto(webcamPhoto);
    };

    const handleGetDocPhoto = () => {
        return store.subjectVerifyWithDocStore.docPhoto;
    };

    const handleTakeSelfie = () => {
        setSelfie({
            file: DataURIToBlob(store.subjectVerifyWithDocStore.selfie),
            preview: store.subjectVerifyWithDocStore.selfie,
        });
        if (store.subjectVerifyWithDocStore.selfie) {
            setIsTakeSelfieVisible(false);
        }
    };

    const handleTakeSelfieCancel = () => {
        setIsTakeSelfieVisible(false);
        store.subjectVerifyWithDocStore.setSelfie(undefined);
    };

    const handleStoreSelfie = webcamPhoto => {
        store.subjectVerifyWithDocStore.setSelfie(webcamPhoto);
    };

    const handleGetSelfie = () => {
        return store.subjectVerifyWithDocStore.selfie;
    };

    const renderArrayAsList = (messages: string[]) => {
        const messageList = messages.map(messageContent => (
            <li>{messageContent}</li>
        ));
        return (
            <ul
                style={{
                    marginLeft: 20,
                    textAlign: 'left',
                }}
            >
                {messageList}
            </ul>
        );
    };

    const handleFaceMatch = () => {
        if (result) {
            setResult(null);
        }
        if (selfie?.file && doc?.file) {
            setVerifyLoading(true);
            store.subjectVerifyWithDocStore
                .faceMatch(doc?.file, selfie?.file)
                .then(res => {
                    setResult(res);
                    setVerifyLoading(false);
                })
                .catch(err => {
                    if (err.response.data.message.description) {
                        const msg = (
                            <>
                                <span>
                                    {err.response.data.message.description}
                                </span>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                    }}
                                >
                                    {err.response.data.message.error_fields
                                        .doc_image && (
                                        <>
                                            <br />
                                            <div>
                                                <div
                                                    style={{
                                                        textAlign: 'left',
                                                    }}
                                                >
                                                    Imagem do Documento:
                                                </div>
                                                {renderArrayAsList(
                                                    err.response.data.message
                                                        .error_fields.doc_image,
                                                )}
                                            </div>
                                        </>
                                    )}

                                    {err.response.data.message.error_fields
                                        .selfie && (
                                        <>
                                            <br />
                                            <div>
                                                <div
                                                    style={{
                                                        textAlign: 'left',
                                                    }}
                                                >
                                                    Selfie:
                                                </div>
                                                {renderArrayAsList(
                                                    err.response.data.message
                                                        .error_fields.selfie,
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </>
                        );
                        message.error(msg, 8);
                    } else {
                        message.error(err.response.data.message);
                    }
                    setVerifyLoading(false);
                });
        }
    };

    const formatFilename = (filename: string) => {
        if (filename.length >= 23) {
            const splitedFilename = filename.split('.');
            const extension = splitedFilename[splitedFilename.length - 1];

            const noExtensionFilename = filename.replace(`.${extension}`, '');
            const firstPart = noExtensionFilename.substring(0, 10);
            const secondPart = noExtensionFilename.substring(
                noExtensionFilename.length,
                noExtensionFilename.length - 10,
            );
            return `${firstPart}...${secondPart}.${extension}`;
        }
        return filename;
    };

    return (
        <Container mobile={!screen.md}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <ResultContent mobile={!screen.md}>
                    <div>
                        <span style={{ color: '#000' }}>CNH:</span>
                        <div>
                            <Button
                                onClick={handleClickUploadDocPhotoButton}
                                icon={<UploadOutlined />}
                                disabled={
                                    store.subjectVerifyWithDocStore.docPhoto
                                }
                            >
                                <span>
                                    <FormattedMessage
                                        id="app.subject-validation.form.add-photo"
                                        defaultMessage="Adicionar foto"
                                    />
                                    <FileUpload
                                        type="file"
                                        accept=".jpg,.jpeg,.png"
                                        style={{ display: 'none' }}
                                        ref={hiddenFileInputRef}
                                        multiple={false}
                                        onChange={file => {
                                            uploadImage(file, 'doc');
                                        }}
                                    />
                                </span>
                            </Button>
                            <StyledTakePhotoButton
                                onClick={showTakeDocPhotoModal}
                                icon={<CameraOutlined />}
                                disabled={!!doc?.file}
                            >
                                <FormattedMessage
                                    id="app.subject-validation.form.take-photo"
                                    defaultMessage="Take photo"
                                />
                            </StyledTakePhotoButton>
                            <Button
                                onClick={handleRemoveDocPhoto}
                                icon={<DeleteOutlined />}
                            />
                            <ImagePreview>
                                <Image
                                    alt="doc-photo"
                                    src={doc?.preview || cnhImagePlaceHolder()}
                                    width="250px"
                                    height="auto"
                                    preview={!!doc}
                                />
                            </ImagePreview>
                            {doc?.file instanceof File &&
                                formatFilename(doc.file.name)}
                        </div>
                    </div>
                    <Tooltip
                        title={
                            !doc?.preview || !selfie?.preview
                                ? 'Envie os documentos antes de verificar'
                                : null
                        }
                    >
                        <Button
                            type="primary"
                            onClick={handleFaceMatch}
                            loading={verifyLoading}
                            disabled={!doc?.preview || !selfie?.preview}
                            style={{ marginLeft: 5, marginRight: 5 }}
                        >
                            Verificar
                        </Button>
                    </Tooltip>
                    <div>
                        <span style={{ color: '#000' }}>Selfie:</span>
                        <div>
                            <Button
                                onClick={handleClickUploadSelfieButton}
                                icon={<UploadOutlined />}
                                disabled={
                                    store.subjectVerifyWithDocStore.selfie
                                }
                            >
                                <span>
                                    <FormattedMessage
                                        id="app.subject-validation.form.add-photo"
                                        defaultMessage="Adicionar foto"
                                    />
                                    <FileUpload
                                        type="file"
                                        accept=".jpg,.jpeg,.png"
                                        style={{ display: 'none' }}
                                        ref={hiddenFileInputRefSelfie}
                                        multiple={false}
                                        onChange={file => {
                                            uploadImage(file, 'selfie');
                                        }}
                                    />
                                </span>
                            </Button>
                            <StyledTakePhotoButton
                                onClick={showTakeSelfieModal}
                                icon={<CameraOutlined />}
                                disabled={!!selfie?.file}
                            >
                                <FormattedMessage
                                    id="app.subject-validation.form.take-photo"
                                    defaultMessage="Take photo"
                                />
                            </StyledTakePhotoButton>
                            <Button
                                onClick={handleRemoveSelfie}
                                icon={<DeleteOutlined />}
                            />
                        </div>
                        <ImagePreview>
                            <Image
                                alt="selfie"
                                src={selfie?.preview || imagePlaceHolder()}
                                height="auto"
                                width="300px"
                                preview={!!selfie}
                            />
                        </ImagePreview>
                        {selfie?.file instanceof File &&
                            formatFilename(selfie.file.name)}
                    </div>
                </ResultContent>
            </div>
            <ResultData>
                {result && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h2>Resultado:</h2>
                        <ReactJson
                            src={result}
                            name={null}
                            enableClipboard={false}
                            displayDataTypes={false}
                            displayObjectSize={false}
                        />
                    </div>
                )}
            </ResultData>
            <Modal
                title={
                    <FormattedMessage
                        id="app.subject-validation.form.photo"
                        defaultMessage="Foto"
                    />
                }
                visible={isTakeDocPhotoVisible}
                onOk={handleTakeDocPhoto}
                onCancel={handleTakeDocPhotoCancel}
                okText={
                    <FormattedMessage
                        id="app.settings.profile.button.save"
                        defaultMessage="Salvar"
                    />
                }
                cancelText={
                    <FormattedMessage
                        id="app.settings.profile.button.cancel"
                        defaultMessage="Cancelar"
                    />
                }
            >
                <WebcamCapture
                    storeWebcamPhoto={handleStoreDocPhoto}
                    getStoredWebcamPhoto={handleGetDocPhoto}
                />
            </Modal>
            <Modal
                title={
                    <FormattedMessage
                        id="app.subject-validation.form.photo"
                        defaultMessage="Foto"
                    />
                }
                visible={isTakeSelfieVisible}
                onOk={handleTakeSelfie}
                onCancel={handleTakeSelfieCancel}
                okText={
                    <FormattedMessage
                        id="app.settings.profile.button.save"
                        defaultMessage="Salvar"
                    />
                }
                cancelText={
                    <FormattedMessage
                        id="app.settings.profile.button.cancel"
                        defaultMessage="Cancelar"
                    />
                }
            >
                <WebcamCapture
                    storeWebcamPhoto={handleStoreSelfie}
                    getStoredWebcamPhoto={handleGetSelfie}
                />
            </Modal>
        </Container>
    );
};

export default Facematch;
