/* eslint-disable @typescript-eslint/no-unused-vars */
import { observable, action, makeObservable } from 'mobx';
import { message } from 'antd';

import ptBRMessages from '../assets/locales/pt-BR.json';
import enUSMessages from '../assets/locales/en-US.json';

export default class UiStateStore {
    @observable messages: Record<string, Record<string, string>> = {
        'pt-BR': ptBRMessages,
        'en-US': enUSMessages,
    };

    // @observable currentLocaleNotNow: string = this.messages[
    //     localStorage.getItem('locale') || navigator.language
    // ]
    //     ? localStorage.getItem('locale') || navigator.language
    //     : 'pt-BR';

    @observable currentLocale = 'pt-BR';

    @observable sideBarCollapsed = false;

    @observable policesAndTermsMenuCollapsed = false;

    @observable navBarMobileTitle = '';

    @observable currentRecoverWizard = {
        step: 1,
        email: '',
    };

    @observable backAction;

    @observable showBackButton;

    @observable showFooter = true;

    @observable showSupport = false;

    @observable isMobile = false;

    @observable showPropertiesPanel;

    globalMsg = message;

    rootStore;

    // eslint-disable-next-line
    constructor(rootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    // eslint-disable-next-line
    @action setCurrentLocale(value) {
        this.currentLocale = value;
    }

    // eslint-disable-next-line
    @action toggleSider() {
        this.sideBarCollapsed = !this.sideBarCollapsed;
    }

    // eslint-disable-next-line
    @action setSideBarCollapsed(value: boolean) {
        this.sideBarCollapsed = value;
    }

    // eslint-disable-next-line
    @action setPolicesAndTermsMenuCollapsed(value: boolean) {
        this.policesAndTermsMenuCollapsed = value;
    }

    // eslint-disable-next-line
    @action togglePolicesAndTermsMenu() {
        this.policesAndTermsMenuCollapsed = !this.policesAndTermsMenuCollapsed;
    }

    // eslint-disable-next-line
    @action setNavBarTitleForMobile(key) {
        this.navBarMobileTitle = key;
    }

    // eslint-disable-next-line
    @action setCurrentRecoverWizard(step: number, email: string) {
        this.currentRecoverWizard = {
            step,
            email,
        };
    }

    // eslint-disable-next-line
    @action resetRecoverWizard() {
        this.currentRecoverWizard = {
            step: 1,
            email: '',
        };
    }

    // eslint-disable-next-line
    @action setMessage(type, content: any, duration, onClose?) {
        switch (type) {
            case 'loading':
                return this.globalMsg.loading(content, duration);
            case 'success':
                return this.globalMsg.success(content, duration, onClose);
            case 'error':
                return this.globalMsg.error(content, duration, onClose);
            case 'warning':
                return this.globalMsg.warning(content, duration, onClose);
            default:
                return this.globalMsg.info(content, duration, onClose);
        }
    }

    @action destroyMessage(): void {
        this.globalMsg.destroy();
    }

    @action setNavBarBackAction(callback: VoidFunction | null): void {
        this.backAction = callback;
    }

    @action setShowBackButton(value: boolean): void {
        this.showBackButton = value;
    }

    @action setShowFooter(value: boolean): void {
        this.showFooter = value;
    }

    @action setShowSupport(value: boolean): void {
        this.showSupport = value;
    }

    @action setIsMobile(value: boolean): void {
        this.isMobile = value;
    }

    // eslint-disable-next-line
    @action setShowPropertiesPanel(value): void {
        this.showPropertiesPanel = value;
    }
}
