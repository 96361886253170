import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: space-evenly;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const Content = styled.section`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    height: 40vh;
    @media (max-width: 768px) {
        align-items: center;
    }
`;

export const TextStyle = styled.p`
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
        'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 48px;
    color: #b3b3b3;
    margin: 0;
    b {
        color: #333;
    }
    @media (max-width: 768px) {
        font-size: 28px;
        text-align: center;
    }
`;

export const LinkStyle = styled(Link)`
    text-decoration: none;
    background: #009cda;
    color: #ffffff;
    border-radius: 2px;
    padding: 8px 16px;
    font-size: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 130px;
`;
