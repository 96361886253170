import React, { useCallback, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Form, Image, Input, message, Modal } from 'antd';
import {
    UploadOutlined,
    DeleteOutlined,
    CameraOutlined,
} from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
// import subjectValidationService from 'services/subjectValidationService';
import { isValidCpf } from 'helpers/validation';
// import FileBase64 from 'react-file-base64';

import { useStore } from 'hooks';
import { birthDateMask, cpfMask } from '../../../../helpers/mask';
import {
    Container,
    Content,
    FileUpload,
    Section,
    SectionTitle,
    StyledTakePhotoButton,
} from './styles';
import Footer from '../Footer';
import WebcamCapture from '../../../../components/Webcam';

interface SubjectValidationProps {
    handleTabChange: (string) => void;
}
const SubjectValidationForm = ({
    handleTabChange,
}: SubjectValidationProps): React.ReactElement => {
    const [form] = useForm();
    const { formatMessage } = useIntl();
    const screen = useBreakpoint();
    const { store } = useStore();
    const [loading, setLoading] = useState(false);
    const [fileUploaded, setFileUploaded] = useState<File>();
    const hiddenFileInputRef = useRef<HTMLInputElement>(null);
    const [isTakePhotoVisible, setIsTakePhotoVisible] = useState(false);
    const [photo, setPhoto] = useState(null);

    const showTakePhotoModal = () => {
        setIsTakePhotoVisible(true);
    };

    const handleStoreWebcamPhoto = webcamPhoto => {
        store.subjectValidationStore.setTakedPhoto(webcamPhoto);
    };

    const handleGetStoredWebcamPhoto = () => {
        return store.subjectValidationStore.takedPhoto;
    };

    const handleTakePhoto = () => {
        setPhoto(store.subjectValidationStore.takedPhoto);
        form.setFieldsValue({
            selfie: store.subjectValidationStore.takedPhoto
                .toString()
                .split(',')[1],
        });
        setIsTakePhotoVisible(false);
    };

    const handleTakePhotoCancel = () => {
        setIsTakePhotoVisible(false);
        store.subjectValidationStore.setTakedPhoto('');
    };

    const handleClickUploadButton = () => {
        hiddenFileInputRef.current?.click();
    };

    const handleRemoveFile = () => {
        if (hiddenFileInputRef.current?.value) {
            setFileUploaded(undefined);
        }
        form.setFieldsValue({
            selfie: '',
        });
        store.subjectValidationStore.setTakedPhoto(null);

        if (photo) {
            setPhoto(null);
        }
    };

    const convertBase64 = useCallback(file => {
        return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                // const base64Img = fileReader.result.
                resolve(fileReader.result);
            };

            fileReader.onerror = error => {
                reject(error);
            };
        });
    }, []);

    const uploadImage = useCallback(
        async e => {
            if (e.target.files[0]) {
                const file = e.target.files[0];
                if (file.type === 'image/jpeg' || file.type === 'image/png') {
                    setFileUploaded(file);
                    const base64Img = await convertBase64(file);
                    const base64ImgAcceptedFormat = base64Img
                        ?.toString()
                        .split(',')[1];
                    form.setFieldsValue({
                        selfie: base64ImgAcceptedFormat,
                    });
                } else {
                    message.error(
                        formatMessage({
                            id: 'app.settings.profile.errors.file-type',
                            defaultMessage:
                                'Você só pode fazer upload de arquivos JPG/PNG.',
                        }),
                    );
                }
            }
        },
        [setFileUploaded, convertBase64, form, formatMessage],
    );

    const handleSend = () => {
        setLoading(true);
        form.validateFields()
            .then(values => {
                store.subjectValidationStore
                    .fetchSubjectValidation(values)
                    .then(() => {
                        message.success(
                            formatMessage({
                                id: 'app.subject-validation.form.request-success',
                                defaultMessage:
                                    'Consulta realizada com sucesso.',
                            }),
                        );
                        setLoading(false);

                        handleTabChange('#result');
                    });
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const handleClear = () => {
        form.resetFields();
    };

    const cpfValidator = (
        _rule: any,
        value: any,
        callback: (error?: string) => void,
    ) => {
        if (isValidCpf(value)) {
            return callback();
        }
        return callback(
            formatMessage({
                id: 'app.settings.profile.errors.cpf.invalid',
                defaultMessage: 'CPF inválido',
            }),
        );
    };
    return (
        <Container mobile={!screen.md}>
            <Content>
                <Form
                    name="subject-validation-form"
                    form={form}
                    layout="vertical"
                    requiredMark={false}
                    style={{ width: 320, marginTop: 8 }}
                    validateTrigger="onSubmit"
                >
                    <Section>
                        <SectionTitle>
                            <FormattedMessage
                                id="app.subject-validation.form.subject-data"
                                defaultMessage="Dados"
                            />
                        </SectionTitle>
                        <Form.Item
                            name="full_name"
                            label={
                                <FormattedMessage
                                    id="app.subject-validation.form.full_name"
                                    defaultMessage="Nome completo *"
                                />
                            }
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: formatMessage({
                                        id: 'app.subject-validation.form.errors.required-field',
                                        defaultMessage: 'Campo obrigatório',
                                    }),
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="cpf"
                            label={
                                <FormattedMessage
                                    id="app.subject-validation.form.cpf"
                                    defaultMessage="CPF *"
                                />
                            }
                            normalize={cpfMask}
                            rules={[
                                {
                                    required: true,
                                    pattern: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
                                    message: formatMessage({
                                        id: 'app.subject-validation.form.errors.cpf-pattern',
                                        defaultMessage:
                                            'Preencha um CPF válido, como 123.456.789-00',
                                    }),
                                },
                                {
                                    validator: cpfValidator,
                                },
                            ]}
                        >
                            <Input placeholder="Ex: 123.456.789-00" />
                        </Form.Item>
                        <Form.Item
                            name="birth_date"
                            label={
                                <FormattedMessage
                                    id="app.subject-validation.form.birth_date"
                                    defaultMessage="Data de nascimento"
                                />
                            }
                            normalize={birthDateMask}
                            rules={[
                                {
                                    // pattern: /^\d{2}\.\d{2}\.\d{4}$/,
                                    message: formatMessage({
                                        id: 'app.subject-validation.form.errors.cpf-pattern',
                                        defaultMessage:
                                            'Preencha uma data de nascimento válida',
                                    }),
                                },
                            ]}
                        >
                            <Input placeholder="Ex: 01/01/1999" />
                        </Form.Item>

                        <Form.Item
                            name="mother_name"
                            label={
                                <FormattedMessage
                                    id="app.subject-validation.form.mother_name"
                                    defaultMessage="Nome da mãe"
                                />
                            }
                            normalize={(value: string) => {
                                if (value.length === 0) {
                                    return null;
                                }
                                return value;
                            }}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="father_name"
                            label={
                                <FormattedMessage
                                    id="app.subject-validation.form.father_name"
                                    defaultMessage="Nome do pai"
                                />
                            }
                            normalize={(value: string) => {
                                if (value.length === 0) {
                                    return null;
                                }
                                return value;
                            }}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="rg"
                            label={
                                <FormattedMessage
                                    id="app.subject-validation.form.rg"
                                    defaultMessage="Registro geral (RG)"
                                />
                            }
                            normalize={(value: string) => {
                                if (value.length === 0) {
                                    return null;
                                }
                                return value;
                            }}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="rg_issuer"
                            label={
                                <FormattedMessage
                                    id="app.subject-validation.form.rg_issuer"
                                    defaultMessage="Emissor da identidade"
                                />
                            }
                            normalize={(value: string) => {
                                if (value.length === 0) {
                                    return null;
                                }
                                return value;
                            }}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="rg_uf"
                            label={
                                <FormattedMessage
                                    id="app.subject-validation.form.rg_uf"
                                    defaultMessage="Estado da identidade"
                                />
                            }
                            normalize={(value: string) => {
                                if (value.length === 0) {
                                    return null;
                                }
                                return value;
                            }}
                            rules={[
                                {
                                    max: 2,
                                    message: (
                                        <FormattedMessage
                                            id="app.my-signature.modal.error.max-40-char"
                                            defaultMessage="Máximo de 2 caracteres"
                                        />
                                    ),
                                },
                                {
                                    min: 2,
                                    message: (
                                        <FormattedMessage
                                            id="app.my-signature.modal.error.max-40-char"
                                            defaultMessage="Mínimo de 2 caracteres"
                                        />
                                    ),
                                },
                            ]}
                        >
                            <Input maxLength={2} minLength={2} />
                        </Form.Item>

                        <Form.Item
                            name="cnh_number"
                            label={
                                <FormattedMessage
                                    id="app.subject-validation.form.cnh_number"
                                    defaultMessage="Número da CNH"
                                />
                            }
                            normalize={(value: string) => {
                                if (value.length === 0) {
                                    return null;
                                }
                                return value;
                            }}
                            rules={[
                                {
                                    max: 11,
                                    message: (
                                        <FormattedMessage
                                            id="app.my-signature.modal.error.max-40-char"
                                            defaultMessage="Máximo de 11 caracteres"
                                        />
                                    ),
                                },
                                {
                                    min: 11,
                                    message: (
                                        <FormattedMessage
                                            id="app.my-signature.modal.error.max-40-char"
                                            defaultMessage="Mínimo de 11 caracteres"
                                        />
                                    ),
                                },
                            ]}
                        >
                            <Input maxLength={11} minLength={11} />
                        </Form.Item>
                        <Form.Item
                            name="selfie"
                            label={
                                <FormattedMessage
                                    id="app.subject-validation.form.photo"
                                    defaultMessage="Foto"
                                />
                            }
                            style={{
                                textAlign: 'center',
                            }}
                        >
                            <Button
                                onClick={handleClickUploadButton}
                                icon={<UploadOutlined />}
                                disabled={
                                    store.subjectValidationStore.takedPhoto
                                }
                            >
                                <span>
                                    <FormattedMessage
                                        id="app.subject-validation.form.add-photo"
                                        defaultMessage="Adicionar foto"
                                    />
                                    <FileUpload
                                        type="file"
                                        accept=".jpg,.jpeg,.png"
                                        style={{ display: 'none' }}
                                        ref={hiddenFileInputRef}
                                        multiple={false}
                                        onChange={file => {
                                            uploadImage(file);
                                        }}
                                    />
                                </span>
                            </Button>
                            <StyledTakePhotoButton
                                onClick={showTakePhotoModal}
                                icon={<CameraOutlined />}
                                disabled={!!fileUploaded}
                            >
                                <FormattedMessage
                                    id="app.subject-validation.form.take-photo"
                                    defaultMessage="Take photo"
                                />
                            </StyledTakePhotoButton>

                            <Button
                                onClick={handleRemoveFile}
                                icon={<DeleteOutlined />}
                            />
                        </Form.Item>
                        {fileUploaded?.name}
                        {photo && <Image alt="avatar" src={photo} />}
                    </Section>
                </Form>
                <Modal
                    title={
                        <FormattedMessage
                            id="app.subject-validation.form.photo"
                            defaultMessage="Foto"
                        />
                    }
                    visible={isTakePhotoVisible}
                    onOk={handleTakePhoto}
                    onCancel={handleTakePhotoCancel}
                    okText={
                        <FormattedMessage
                            id="app.settings.profile.button.save"
                            defaultMessage="Salvar"
                        />
                    }
                    cancelText={
                        <FormattedMessage
                            id="app.settings.profile.button.cancel"
                            defaultMessage="Cancelar"
                        />
                    }
                >
                    <WebcamCapture
                        storeWebcamPhoto={handleStoreWebcamPhoto}
                        getStoredWebcamPhoto={handleGetStoredWebcamPhoto}
                    />
                </Modal>
            </Content>
            <Footer
                onSend={handleSend}
                loading={loading}
                onClear={handleClear}
            />
        </Container>
    );
};
export default SubjectValidationForm;
