/* eslint-disable import/prefer-default-export */
function isEmail(value: string): boolean {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value,
    );
}

function isValidCpf(strCPF: string): boolean {
    let soma;
    let resto;
    soma = 0;

    const cpfTratado = strCPF.replace(/[^0-9]/g, '');

    if (cpfTratado === '00000000000') return false;

    for (let i = 1; i <= 9; i += 1)
        soma += parseInt(cpfTratado.substring(i - 1, i), 10) * (11 - i);
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpfTratado.substring(9, 10), 10)) return false;

    soma = 0;
    for (let i = 1; i <= 10; i += 1)
        soma += parseInt(cpfTratado.substring(i - 1, i), 10) * (12 - i);
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpfTratado.substring(10, 11), 10)) return false;
    return true;
}

export { isEmail, isValidCpf };
