/* eslint-disable import/prefer-default-export */
import { Button } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const TakePhotoButton = styled(Button)`
    width: 30%;
    margin: 20px 0px 0px 0px;

    svg {
        margin-right: 10px;
    }
`;

export const RetakePhotoButton = styled(Button)`
    width: 30%;
    margin: 20px 0px 0px 0px;

    svg {
        margin-right: 10px;
    }
`;
