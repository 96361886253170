/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Card, Progress } from 'antd';
import {
    CheckCircleOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';

export const Container = styled.div`
    padding: 20px;
    display: flex:
    flex-direction: row;
    @media (max-width: 578px) {
        flex-directions: column;
    }
`;

export const CardGrid = styled(Card.Grid)`
    flex: 1;
`;

export const CardGridTittle = styled.h2`
    margin-bottom: 20px;

    font-size: 24px;
    line-height: 32px;

    display: flex;
    align-items: center;

    color: #262626;
`;

export const CardGridInfo = styled.div`
    margin-bottom: 20px;
    padding: 20px;
    background-color: #f7f8fb;
    max-height: min-content;
    border-radius: 20px;
    color: #262626;
`;

export const CardGridItem = styled.div`
    margin-bottom: 20px;
    padding: 10px;
    background-color: #fff;
    font-size: 16px;
    max-height: min-content;
    border-radius: 8px;
    color: #262626;
`;

export const CardFail = styled(Card)`
    display: flex;
    border: none;
    align-items: center;
    justify-content: center;
`;

export const CardFailInfo = styled.div`
    background-color: #f7f8fb;
    padding: 10px;
    border-radius: 8px;
    svg {
        color: red;
        margin: 0px 20px;
    }
`;

export const ExclamationCircleIcon = styled(ExclamationCircleOutlined)`
    color: red;
    margin: 0px 5px;
`;

export const CheckCircleIcon = styled(CheckCircleOutlined)`
    color: green;
    margin: 0px 5px;
`;

export const ProgressSimilarity = styled(Progress)``;
