import api from './api';

// eslint-disable-next-line
async function subjectValidation(data: any): Promise<any> {
    let dateISOStringFormat;
    if (data.birth_date) {
        const day = data.birth_date.split('/')[0];
        const month = data.birth_date.split('/')[1];
        const year = data.birth_date.split('/')[2];

        dateISOStringFormat = `${year}-${`0${month}`.slice(
            -2,
        )}-${`0${day}`.slice(-2)}`;
    }

    const subjectData = {
        full_name: data.full_name,
        cpf: data.cpf.replace(/\D+/g, ''),
        birth_date: dateISOStringFormat,
        mother_name: data.mother_name,
        father_name: data.father_name,
        rg: data.rg,
        rg_issuer: data.rg_issuer,
        rg_uf: data.rg_uf,
        cnh_number: data.cnh_number,
        selfie: data?.selfie,
    };
    const response = await api.post('/api/subject-validation', subjectData);
    const result = response.data;
    return result;
}

export default {
    subjectValidation,
};
