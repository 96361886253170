import React, { useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Form, Input } from 'antd';
import { UserOutlined, MailOutlined, IdcardOutlined } from '@ant-design/icons';
import { isValidCpf } from 'helpers/validation';
import { useStore } from '../../../hooks';
import {
    Title,
    Subtitle,
    AlreadyRegistered,
    RegisterCard,
    LogoBRy,
    Container,
    LogoSigner,
} from './styles';
import authService from '../../../services/authService';
import logoGray from '../../../assets/images/logo-bry-gray.svg';
import { cpfMask } from '../../../helpers/mask';

const { useForm } = Form;

interface RegisterFormData {
    name: string;
    cpf: string;
}

interface LocationProps {
    name: string;
    tokenId: string;
    email: string;
}

const CreateAccountWithGogle = (): React.ReactElement => {
    const intl = useIntl();
    const { store } = useStore();
    const [form] = useForm();
    const history = useHistory();
    const { formatMessage } = useIntl();
    const location = useLocation<LocationProps>();
    const [tokenId, setTokenId] = useState('');
    const handleSubmit = async (data: RegisterFormData) => {
        const registerFormData = {
            ...data,
            cpf: data.cpf.replace(/\D+/g, ''),
        };
        const responseCreate = await authService.createAccountWithGoogle(
            registerFormData,
            tokenId,
        );
        if (responseCreate.success) {
            const responseLogin = await store.authStore.signInWithGoogle(
                tokenId,
            );
            if (responseLogin?.success) {
                history.push('/dashboard');
            }
        } else if (responseCreate.status === 400) {
            store.uiStateStore.setMessage(
                'error',
                intl.formatMessage({
                    id: 'app.register-with-google.not-possible-register',
                    defaultMessage: 'Não foi possível realizar seu cadastro.',
                }),
                3,
            );
        } else {
            store.uiStateStore.setMessage(
                'error',
                intl.formatMessage({
                    id: 'app.register.error.unknow',
                    defaultMessage: 'Erro desconhecido',
                }),
                3,
            );
        }
    };

    useEffect(() => {
        store.uiStateStore.resetRecoverWizard();
    }, [store]);

    useEffect(() => {
        if (location.state) {
            setTokenId(location.state.tokenId);
            form.setFieldsValue({
                name: location.state.name,
            });
            form.setFieldsValue({
                email: location.state.email,
            });
        }
    }, [form, location.state]);

    const cpfValidator = (
        _rule: any,
        value: any,
        callback: (error?: string) => void,
    ) => {
        if (isValidCpf(value)) {
            return callback();
        }
        return callback(
            formatMessage({
                id: 'app.settings.profile.errors.cpf.invalid',
                defaultMessage: 'CPF inválido',
            }),
        );
    };
    return (
        <Container>
            <LogoSigner>{/* <img src={logo} alt="Logo" /> */}</LogoSigner>
            <RegisterCard>
                <Title>
                    <FormattedMessage
                        id="app.register.create-account"
                        defaultMessage="Crie sua conta"
                    />
                </Title>
                <Subtitle>
                    <FormattedMessage
                        id="app.register-with-google.enter-data"
                        defaultMessage="*Esses dados foram recuperados ao realizar login com o Google:"
                    />
                </Subtitle>
                <Form
                    form={form}
                    name="create-account-form"
                    size="large"
                    onFinish={data => handleSubmit(data)}
                    validateTrigger="handleSubmit"
                >
                    <Form.Item
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: intl.formatMessage({
                                    id: 'app.register.error.name-required',
                                    defaultMessage: 'Preencha seu nome.',
                                }),
                            },
                        ]}
                        style={{ textAlign: 'left' }}
                    >
                        <Input
                            type="text"
                            placeholder={intl.formatMessage({
                                id: 'app.register.name-placeholder',
                                defaultMessage: 'Nome',
                            })}
                            prefix={
                                <UserOutlined style={{ color: '#009CDA' }} />
                            }
                            maxLength={40}
                        />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: intl.formatMessage({
                                    id: 'app.register.error.email-required',
                                    defaultMessage: 'Preencha seu e-mail.',
                                }),
                            },
                            {
                                type: 'email',
                                message: intl.formatMessage({
                                    id: 'app.register.error.email-pattern',
                                }),
                            },
                        ]}
                        style={{ textAlign: 'left' }}
                    >
                        <Input
                            type="email"
                            disabled
                            placeholder={intl.formatMessage({
                                id: 'app.register.email-placeholder',
                            })}
                            prefix={
                                <MailOutlined style={{ color: '#009CDA' }} />
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        name="cpf"
                        style={{ textAlign: 'left' }}
                        normalize={cpfMask}
                        rules={[
                            {
                                required: true,
                                pattern: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
                                message: formatMessage({
                                    id: 'app.subject-validation.form.errors.cpf-pattern',
                                    defaultMessage:
                                        'Preencha um CPF válido, como 123.456.789-00',
                                }),
                            },
                            {
                                validator: cpfValidator,
                            },
                        ]}
                    >
                        <Input
                            type="text"
                            placeholder={intl.formatMessage({
                                id: 'app.register.cpf-placeholder',
                                defaultMessage: 'CPF',
                            })}
                            prefix={
                                <IdcardOutlined style={{ color: '#009CDA' }} />
                            }
                            maxLength={14}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            htmlType="submit"
                            type="primary"
                            size="large"
                            style={{ width: '100%' }}
                            // disabled={isSubmitting}
                            // loading={isSubmitting}
                        >
                            <FormattedMessage
                                id="app.register.access"
                                defaultMessage="Cadastrar-se"
                            />
                        </Button>
                    </Form.Item>
                </Form>
                <AlreadyRegistered>
                    <FormattedMessage
                        id="app.register.already-registered"
                        defaultMessage="Possui uma conta? {link}"
                        values={{
                            link: (
                                <Link to="/login">
                                    <FormattedMessage
                                        id="app.register.sign-in"
                                        defaultMessage="Entrar"
                                    />
                                </Link>
                            ),
                        }}
                    />
                </AlreadyRegistered>
            </RegisterCard>
            <LogoBRy>
                <img src={logoGray} alt="Logo" />
            </LogoBRy>
        </Container>
    );
};

export default CreateAccountWithGogle;
