import React from 'react';

import { ArrowLeftOutlined, WarningOutlined } from '@ant-design/icons';
import { Container, Content, TextStyle, LinkStyle } from './styles';
import BrandingMain from '../../../assets/images/branding-main.svg';

const PageNotFound = (): React.ReactElement => {
    return (
        <Container>
            <Content>
                <img src={BrandingMain} width={400} alt="" />
                <TextStyle>
                    <b>404</b> Essa página não foi encontrada!
                </TextStyle>
                <LinkStyle to="/">
                    <ArrowLeftOutlined />
                    Voltar
                </LinkStyle>
            </Content>
            <WarningOutlined style={{ color: '#009cda', fontSize: 200 }} />
        </Container>
    );
};

export default PageNotFound;
