import { format } from 'date-fns';

const formatDate = (dateStr: string | Date): string => {
    const date = new Date(dateStr);
    const formattedDate = format(date, 'dd/MM/yyyy');
    return formattedDate;
};

const capitalizeFirstLetter = (string: string): string => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export { formatDate, capitalizeFirstLetter };
