import { Divider, Row } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    DatavalidResultInterface,
    SubjectValidationFormInterface,
} from 'interfaces/subjectValidation';
import {
    Container,
    CardGridInfo,
    CardGridTittle,
    CardGridItem,
    ProgressSimilarity,
    CardFailInfo,
    CardFail,
    ExclamationCircleIcon,
    CheckCircleIcon,
    CardGrid,
} from './styles';

interface DatavalidResultProps {
    formData: SubjectValidationFormInterface;
    result: DatavalidResultInterface;
}

const DatavalidResult = ({
    formData,
    result,
}: DatavalidResultProps): React.ReactElement => {
    return (
        <Container>
            {result.success && (
                <>
                    <Row gutter={[8, 8]}>
                        <CardGrid>
                            <CardGridTittle>
                                <FormattedMessage
                                    id="app.subject-validation.result.datavalid.personal-data"
                                    defaultMessage="Dados pessoais"
                                />
                            </CardGridTittle>
                            <CardGridInfo>
                                <CardGridItem>
                                    <FormattedMessage
                                        id="app.subject-validation.result.datavalid.name"
                                        defaultMessage="Nome completo: "
                                    />
                                    <strong> {formData.full_name}</strong>
                                    <strong>
                                        {result.data?.nome ? (
                                            <strong>
                                                <CheckCircleIcon />
                                            </strong>
                                        ) : (
                                            <strong>
                                                <ExclamationCircleIcon />
                                            </strong>
                                        )}
                                    </strong>
                                    <br />
                                    <br />
                                    <FormattedMessage
                                        id="app.subject-validation.result.datavalid.similarity"
                                        defaultMessage="Similaridade: "
                                    />
                                    <ProgressSimilarity
                                        strokeColor={{
                                            '85%': '#36b585',
                                            '45%': '#f9b120',
                                            '0%': '#f86948',
                                        }}
                                        percent={parseFloat(
                                            (
                                                result.data.nome_similaridade *
                                                100
                                            ).toFixed(2),
                                        )}
                                    />
                                </CardGridItem>
                                {formData.birth_date && (
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="app.subject-validation.result.datavalid.birth-date"
                                            defaultMessage="Data de nascimento: "
                                        />
                                        <strong> {formData.birth_date}</strong>
                                        <strong>
                                            {result.data?.data_nascimento ? (
                                                <strong>
                                                    <CheckCircleIcon />
                                                </strong>
                                            ) : (
                                                <strong>
                                                    <ExclamationCircleIcon />
                                                </strong>
                                            )}
                                        </strong>
                                    </CardGridItem>
                                )}
                            </CardGridInfo>
                        </CardGrid>
                        {Object.keys(result.data.filiacao).length !== 0 && (
                            <CardGrid>
                                <CardGridTittle>
                                    <FormattedMessage
                                        id="app.subject-validation.result.datavalid.parents"
                                        defaultMessage="Filiação"
                                    />
                                </CardGridTittle>
                                <CardGridInfo>
                                    {formData.mother_name && (
                                        <>
                                            <CardGridItem>
                                                <FormattedMessage
                                                    id="app.subject-validation.result.datavalid.mothers-name"
                                                    defaultMessage="Nome da mãe: "
                                                />
                                                <strong>
                                                    {formData.mother_name}
                                                </strong>
                                                <strong>
                                                    {result.data.filiacao
                                                        .nome_mae ? (
                                                        <strong>
                                                            <CheckCircleIcon />
                                                        </strong>
                                                    ) : (
                                                        <strong>
                                                            <ExclamationCircleIcon />
                                                        </strong>
                                                    )}
                                                </strong>
                                                <br />
                                                <br />
                                                <FormattedMessage
                                                    id="app.subject-validation.result.datavalid.similarity"
                                                    defaultMessage="Similaridade: "
                                                />
                                                <ProgressSimilarity
                                                    strokeColor={{
                                                        '85%': '#36b585',
                                                        '45%': '#f9b120',
                                                        '0%': '#f86948',
                                                    }}
                                                    percent={parseFloat(
                                                        (
                                                            result.data.filiacao
                                                                .nome_mae_similaridade *
                                                            100
                                                        ).toFixed(2),
                                                    )}
                                                />
                                            </CardGridItem>
                                        </>
                                    )}
                                    {formData.father_name && (
                                        <>
                                            <Divider />
                                            <CardGridItem>
                                                <FormattedMessage
                                                    id="app.subject-validation.result.datavalid.fathers-name"
                                                    defaultMessage="Nome do pai: "
                                                />
                                                <strong>
                                                    {formData.father_name}
                                                </strong>
                                                <strong>
                                                    {result.data.filiacao
                                                        .nome_pai ? (
                                                        <strong>
                                                            <CheckCircleIcon />
                                                        </strong>
                                                    ) : (
                                                        <strong>
                                                            <ExclamationCircleIcon />
                                                        </strong>
                                                    )}
                                                </strong>
                                                <br />
                                                <br />
                                                <FormattedMessage
                                                    id="app.subject-validation.result.datavalid.similarity"
                                                    defaultMessage="Similaridade: "
                                                />
                                                <ProgressSimilarity
                                                    strokeColor={{
                                                        '85%': '#36b585',
                                                        '45%': '#f9b120',
                                                        '0%': '#f86948',
                                                    }}
                                                    percent={parseFloat(
                                                        (
                                                            result.data.filiacao
                                                                .nome_pai_similaridade *
                                                            100
                                                        ).toFixed(2),
                                                    )}
                                                />
                                            </CardGridItem>
                                        </>
                                    )}
                                </CardGridInfo>
                            </CardGrid>
                        )}
                        {Object.keys(result.data.documento).length !== 0 && (
                            <CardGrid>
                                <CardGridTittle>
                                    <FormattedMessage
                                        id="app.subject-validation.result.datavalid.document-number"
                                        defaultMessage="Documento (RG)"
                                    />
                                </CardGridTittle>
                                <CardGridInfo>
                                    {/* <CardGridItem>
                                    <FormattedMessage
                                        id="app.subject-validation.result.datavalid.type"
                                        defaultMessage="Tipo: "
                                    />
                                    <strong>
                                        {result.data.documento.tipo}
                                    </strong>
                                </CardGridItem> */}
                                    {formData.rg_issuer && (
                                        <CardGridItem>
                                            <FormattedMessage
                                                id="app.subject-validation.result.datavalid.rg-issuer"
                                                defaultMessage="Orgão expedidor: "
                                            />
                                            <strong>
                                                {formData.rg_issuer}
                                            </strong>
                                            <strong>
                                                {result.data.documento
                                                    .orgao_expedidor ? (
                                                    <strong>
                                                        <CheckCircleIcon />
                                                    </strong>
                                                ) : (
                                                    <strong>
                                                        <ExclamationCircleIcon />
                                                    </strong>
                                                )}
                                            </strong>
                                        </CardGridItem>
                                    )}
                                    {formData.rg_uf && (
                                        <CardGridItem>
                                            <FormattedMessage
                                                id="app.subject-validation.result.datavalid.rg-state"
                                                defaultMessage="Estado: "
                                            />
                                            <strong>{formData.rg_uf}</strong>
                                            {result.data.documento
                                                .uf_expedidor ? (
                                                <strong>
                                                    <CheckCircleIcon />
                                                </strong>
                                            ) : (
                                                <strong>
                                                    <ExclamationCircleIcon />
                                                </strong>
                                            )}
                                        </CardGridItem>
                                    )}
                                    {formData.rg && (
                                        <CardGridItem>
                                            <FormattedMessage
                                                id="app.subject-validation.result.datavalid.number"
                                                defaultMessage="Número: "
                                            />
                                            <strong>{formData.rg}</strong>
                                            <strong>
                                                {result.data.documento
                                                    .numero ? (
                                                    <strong>
                                                        <CheckCircleIcon />
                                                    </strong>
                                                ) : (
                                                    <strong>
                                                        <ExclamationCircleIcon />
                                                    </strong>
                                                )}
                                            </strong>
                                            <br />
                                            <br />
                                            <FormattedMessage
                                                id="app.subject-validation.result.datavalid.similarity"
                                                defaultMessage="Similaridade: "
                                            />

                                            <ProgressSimilarity
                                                strokeColor={{
                                                    '85%': '#36b585',
                                                    '45%': '#f9b120',
                                                    '0%': '#f86948',
                                                }}
                                                percent={parseFloat(
                                                    (
                                                        result.data.documento
                                                            .numero_similaridade *
                                                        100
                                                    ).toFixed(2),
                                                )}
                                            />
                                        </CardGridItem>
                                    )}
                                </CardGridInfo>
                            </CardGrid>
                        )}
                        {Object.keys(result.data.cnh).length !== 0 && (
                            <CardGrid>
                                <CardGridTittle>
                                    <FormattedMessage
                                        id="app.subject-validation.result.datavalid.cnh"
                                        defaultMessage="Carteira de motorista (CNH)"
                                    />
                                </CardGridTittle>
                                <CardGridInfo>
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="app.subject-validation.result.datavalid.name"
                                            defaultMessage="Nome completo: "
                                        />
                                        <strong>{formData.full_name}</strong>
                                        <strong>
                                            {result.data.cnh.nome ? (
                                                <strong>
                                                    <CheckCircleIcon />
                                                </strong>
                                            ) : (
                                                <strong>
                                                    <ExclamationCircleIcon />
                                                </strong>
                                            )}
                                        </strong>
                                        <br />
                                        <br />
                                        <FormattedMessage
                                            id="app.subject-validation.result.datavalid.similarity"
                                            defaultMessage="Similaridade: "
                                        />

                                        <ProgressSimilarity
                                            strokeColor={{
                                                '85%': '#36b585',
                                                '45%': '#f9b120',
                                                '0%': '#f86948',
                                            }}
                                            percent={parseFloat(
                                                (
                                                    result.data.cnh
                                                        .nome_similaridade * 100
                                                ).toFixed(2),
                                            )}
                                        />
                                    </CardGridItem>
                                    {result.data.cnh.numero_registro && (
                                        <CardGridItem>
                                            <FormattedMessage
                                                id="app.subject-validation.result.datavalid.cnh-number"
                                                defaultMessage="Número da CNH: "
                                            />
                                            <strong>
                                                {formData.cnh_number}
                                            </strong>

                                            {result.data.cnh.numero_registro ? (
                                                <strong>
                                                    <CheckCircleIcon />
                                                </strong>
                                            ) : (
                                                <strong>
                                                    <ExclamationCircleIcon />
                                                </strong>
                                            )}
                                        </CardGridItem>
                                    )}
                                </CardGridInfo>
                            </CardGrid>
                        )}
                        {Object.keys(result.data.biometria_face).length !==
                            0 && (
                            <CardGrid>
                                <CardGridTittle>
                                    <FormattedMessage
                                        id="app.subject-validation.result.datavalid.photo"
                                        defaultMessage="Foto"
                                    />
                                </CardGridTittle>
                                <CardGridInfo>
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="app.subject-validation.result.datavalid.photo-probability"
                                            defaultMessage="Probabilidade da foto: "
                                        />
                                        <strong>
                                            {
                                                result.data.biometria_face
                                                    .probabilidade
                                            }
                                        </strong>
                                    </CardGridItem>
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="app.subject-validation.result.datavalid.photo-similarity"
                                            defaultMessage="Similaridade da foto: "
                                        />
                                        <ProgressSimilarity
                                            strokeColor={{
                                                '85%': '#36b585',
                                                '45%': '#f9b120',
                                                '0%': '#f86948',
                                            }}
                                            percent={parseFloat(
                                                (
                                                    result.data.biometria_face
                                                        .similaridade * 100
                                                ).toFixed(2),
                                            )}
                                        />
                                    </CardGridItem>
                                </CardGridInfo>
                            </CardGrid>
                        )}
                    </Row>
                </>
            )}
            {!result.success && !result.data.code && (
                <CardFail>
                    <CardFailInfo>
                        <ExclamationCircleIcon />
                        <FormattedMessage
                            id="app.subject-validation.result.datavalid.fail"
                            defaultMessage="A validação com o Datavalid falhou. Tente novamente mais tarde."
                        />
                    </CardFailInfo>
                </CardFail>
            )}
            {result.data.code === 'DV041' && (
                <CardFail>
                    <CardFailInfo>
                        <ExclamationCircleIcon />
                        <FormattedMessage
                            id="app.subject-validation.result.datavalid."
                            defaultMessage="Não foi possível reconhecer a face na imagem enviada. Tente novamente."
                        />
                    </CardFailInfo>
                </CardFail>
            )}
        </Container>
    );
};

export default observer(DatavalidResult);
