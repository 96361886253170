import api from './api';

// eslint-disable-next-line
async function enrollSubject(data: FormData): Promise<any>{
    const response = await api.post('/api/v2/subject', data, {
        headers: { 'content-type': 'multipart/form-data' },
    });
    const result = response.data;
    return result;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
async function searchSubject(data: any): Promise<any> {
    const response = await api.get(
        `/api/v2/subject/${data.cpf.replace(/\D+/g, '')}`,
    );
    const result = response.data;
    return result;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
async function deleteSubject(data: any): Promise<any> {
    const response = await api.delete(
        `/api/v2/subject/${data.cpf.replace(/\D+/g, '')}`,
    );
    const result = response.data;
    return result;
}

async function verifySubject(cpf: string, selfie: Blob): Promise<any> {
    const payload = new FormData();
    payload.append('selfie', selfie, 'selfie.jpeg');

    const response = await api.post(
        `/api/v2/subject/${cpf.replace(/\D+/g, '')}/verify`,
        payload,
        { headers: { 'content-type': 'multipart/form-data' } },
    );
    const result = response.data;
    return result;
}

async function faceMatch(doc: Blob | File, selfie: Blob | File): Promise<any> {
    const payload = new FormData();
    payload.append('selfie', selfie, 'selfie.jpeg');
    payload.append('doc_image', doc, 'doc_image.jpeg');

    const response = await api.post(`/api/v2/facematch`, payload, {
        headers: { 'content-type': 'multipart/form-data' },
    });
    const result = response.data;
    return result;
}

async function identifySubject(selfie: Blob): Promise<any> {
    const payload = new FormData();
    payload.append('selfie', selfie, 'selfie.jpeg');

    const response = await api.post('/api/v2/subject-identify/', payload, {
        headers: { 'content-type': 'multipart/form-data' },
        params: { getSelfie: true },
    });

    const result = response.data;
    return result;
}

export default {
    searchSubject,
    enrollSubject,
    deleteSubject,
    verifySubject,
    faceMatch,
    identifySubject,
};
