import { observer } from 'mobx-react';
import React from 'react';
import { Card, Row } from 'antd';
import { FormattedMessage } from 'react-intl';
import { BuscaPfInterface } from 'interfaces/subjectValidation';
import { capitalizeFirstLetter, formatDate } from 'helpers/format';
import {
    Container,
    CardGridInfo,
    CardGridTittle,
    CardGridItem,
} from './styles';

interface PersonalDataResultProps {
    result: BuscaPfInterface;
}
const PersonalDataResult = ({
    result,
}: PersonalDataResultProps): React.ReactElement => {
    return (
        <Container>
            {result && (
                <>
                    <Row gutter={[8, 8]}>
                        <Card.Grid>
                            <CardGridTittle>
                                <FormattedMessage
                                    id="app.subject-validation.result.spc-result.personal-data-result.personal-data"
                                    defaultMessage="Dados pessoais"
                                />
                            </CardGridTittle>
                            <CardGridInfo>
                                {result.nome ? (
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="app.subject-validation.result.spc-result.personal-data-result.full-name"
                                            defaultMessage="Nome completo: "
                                        />
                                        <strong>{result.nome}</strong>
                                    </CardGridItem>
                                ) : null}
                                {result.idade ? (
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="app.subject-validation.result.spc-result.personal-data-result.age"
                                            defaultMessage="Idade: "
                                        />
                                        <strong>{result.idade}</strong>
                                    </CardGridItem>
                                ) : null}
                                {result.data_nascimento ? (
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="app.subject-validation.result.spc-result.personal-data-result.birth-date"
                                            defaultMessage="Data de nascimento: "
                                        />
                                        <strong>
                                            {result.data_nascimento
                                                ? formatDate(
                                                      result?.data_nascimento,
                                                  )
                                                : false}
                                        </strong>
                                    </CardGridItem>
                                ) : null}
                                {result.sexo ? (
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="app.subject-validation.result.spc-result.personal-data-result.gender"
                                            defaultMessage="Sexo: "
                                        />
                                        <strong>
                                            {result.sexo
                                                ? capitalizeFirstLetter(
                                                      result.sexo,
                                                  )
                                                : false}
                                        </strong>
                                    </CardGridItem>
                                ) : null}
                                {result.estado_civil ? (
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="app.subject-validation.result.spc-result.personal-data-result.marital-status"
                                            defaultMessage="Estado civil: "
                                        />
                                        <strong>
                                            {result.estado_civil
                                                ? capitalizeFirstLetter(
                                                      result.estado_civil,
                                                  )
                                                : false}
                                        </strong>
                                    </CardGridItem>
                                ) : null}
                                {result.email ? (
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="app.subject-validation.result.spc-result.personal-data-result.email"
                                            defaultMessage="E-mail: "
                                        />
                                        <strong>{result.email}</strong>
                                    </CardGridItem>
                                ) : null}
                                <CardGridItem>
                                    <FormattedMessage
                                        id="app.subject-validation.result.spc-result.personal-data-result.is-foreign"
                                        defaultMessage="É estrangeiro?"
                                    />
                                    <strong>
                                        {' '}
                                        {result.pessoa_estrangeira === 'true'
                                            ? 'Sim'
                                            : 'Não'}
                                    </strong>
                                </CardGridItem>
                            </CardGridInfo>
                        </Card.Grid>
                        <Card.Grid>
                            <CardGridTittle>
                                <FormattedMessage
                                    id="app.subject-validation.result.spc-result.personal-data-result.address"
                                    defaultMessage="Endereço"
                                />
                            </CardGridTittle>
                            <CardGridInfo>
                                {result.endereco?.logradouro ? (
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="app.subject-validation.result.spc-result.personal-data-result.street"
                                            defaultMessage="Logradouro: "
                                        />
                                        <strong>
                                            {result.endereco.logradouro}
                                        </strong>
                                    </CardGridItem>
                                ) : null}
                                {result.endereco?.numero ? (
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="app.subject-validation.result.spc-result.personal-data-result.number"
                                            defaultMessage="Número: "
                                        />
                                        <strong>
                                            {result.endereco.numero}
                                        </strong>
                                    </CardGridItem>
                                ) : null}
                                {result.endereco?.bairro ? (
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="app.subject-validation.result.spc-result.personal-data-result.neighborhood"
                                            defaultMessage="Bairro: "
                                        />
                                        <strong>
                                            {' '}
                                            {result.endereco.bairro}
                                        </strong>
                                    </CardGridItem>
                                ) : null}
                                {result.endereco?.cep ? (
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="app.subject-validation.result.spc-result.personal-data-result.cep"
                                            defaultMessage="CEP: "
                                        />
                                        <strong>{result.endereco.cep}</strong>
                                    </CardGridItem>
                                ) : null}
                                {result.endereco?.cidade?.nome ? (
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="app.subject-validation.result.spc-result.personal-data-result.city"
                                            defaultMessage="Cidade: "
                                        />
                                        <strong>
                                            {result.endereco?.cidade?.nome}
                                        </strong>
                                    </CardGridItem>
                                ) : null}
                                {result.endereco?.cidade?.estado?.sigla_uf ? (
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="app.subject-validation.result.spc-result.personal-data-result.state"
                                            defaultMessage="Estado: "
                                        />
                                        <strong>
                                            {
                                                result.endereco?.cidade?.estado
                                                    ?.sigla_uf
                                            }
                                        </strong>
                                    </CardGridItem>
                                ) : null}
                            </CardGridInfo>
                        </Card.Grid>

                        <Card.Grid>
                            <CardGridTittle>
                                <FormattedMessage
                                    id="app.subject-validation.result.spc-result.personal-data-result.document"
                                    defaultMessage="Documento (RG)"
                                />
                            </CardGridTittle>
                            <CardGridInfo>
                                {result.numero_rg ? (
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="app.subject-validation.result.spc-result.personal-data-result.number"
                                            defaultMessage="Número: "
                                        />
                                        <strong>{result.numero_rg}</strong>
                                    </CardGridItem>
                                ) : null}
                                {result.cpf?.regiao_origem ? (
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="app.subject-validation.result.spc-result.personal-data-result.origin-region"
                                            defaultMessage="Região de origem: "
                                        />
                                        <strong>
                                            {result.cpf?.regiao_origem}
                                        </strong>
                                    </CardGridItem>
                                ) : null}
                                {result.estado_rg?.sigla_uf ? (
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="app.subject-validation.result.spc-result.personal-data-result.rg-state"
                                            defaultMessage="Estado: "
                                        />
                                        <strong>
                                            {result.estado_rg?.sigla_uf}
                                        </strong>
                                    </CardGridItem>
                                ) : null}
                                {result.cpf?.numero ? (
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="app.subject-validation.result.spc-result.personal-data-result.cpf"
                                            defaultMessage="CPF: "
                                        />
                                        <strong>{result.cpf?.numero}</strong>
                                    </CardGridItem>
                                ) : null}
                                {result.situacao_cpf?.descricao_situacao ? (
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="app.subject-validation.result.spc-result.personal-data-result.cpf-situation"
                                            defaultMessage="Situação do CPF: "
                                        />
                                        <strong>
                                            {result.situacao_cpf
                                                ?.descricao_situacao
                                                ? capitalizeFirstLetter(
                                                      result.situacao_cpf
                                                          ?.descricao_situacao,
                                                  )
                                                : false}
                                        </strong>
                                    </CardGridItem>
                                ) : null}
                                {result.situacao_cpf?.data_situacao ? (
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="app.subject-validation.result.spc-result.personal-data-result.situation-date"
                                            defaultMessage="Data da situação: "
                                        />
                                        <strong>
                                            {formatDate(
                                                result.situacao_cpf
                                                    ?.data_situacao,
                                            )}
                                        </strong>
                                    </CardGridItem>
                                ) : null}
                            </CardGridInfo>
                        </Card.Grid>
                    </Row>
                    <Row gutter={[8, 8]}>
                        <Card.Grid>
                            <CardGridTittle>
                                <FormattedMessage
                                    id="app.subject-validation.result.spc-result.personal-data-result.parents"
                                    defaultMessage="Filiação"
                                />
                            </CardGridTittle>
                            <CardGridInfo>
                                {result.nome_mae ? (
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="app.subject-validation.result.spc-result.personal-data-result.mothers-name"
                                            defaultMessage="Nome da mãe: "
                                        />
                                        <strong>{result.nome_mae}</strong>
                                    </CardGridItem>
                                ) : null}
                                {result.nome_pai ? (
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="app.subject-validation.result.spc-result.personal-data-result.fathers-name"
                                            defaultMessage="Nome do pai: "
                                        />
                                        <strong>{result.nome_pai}</strong>
                                    </CardGridItem>
                                ) : null}
                            </CardGridInfo>
                        </Card.Grid>
                        <Card.Grid>
                            <CardGridTittle>
                                <FormattedMessage
                                    id="app.subject-validation.result.spc-result.personal-data-result.other-informations"
                                    defaultMessage="Outras informações"
                                />
                            </CardGridTittle>
                            <CardGridInfo>
                                {result.numero_titulo_eleitor ? (
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="app.subject-validation.result.spc-result.personal-data-result.electoral-number"
                                            defaultMessage="Título de eleitor: "
                                        />
                                        <strong>
                                            {result.numero_titulo_eleitor}
                                        </strong>
                                    </CardGridItem>
                                ) : null}
                                {result.telefone_residencial.numero ? (
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="app.subject-validation.result.spc-result.personal-data-result.phone"
                                            defaultMessage="Telefone: "
                                        />
                                        <strong>
                                            {
                                                result.telefone_residencial
                                                    ?.numero_ddd
                                            }
                                        </strong>{' '}
                                        <strong>
                                            {
                                                result.telefone_residencial
                                                    ?.numero
                                            }
                                        </strong>
                                    </CardGridItem>
                                ) : null}
                                {result.signo ? (
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="app.subject-validation.result.spc-result.personal-data-result.sign"
                                            defaultMessage="Signo: "
                                        />
                                        <strong>{result.signo}</strong>
                                    </CardGridItem>
                                ) : null}
                            </CardGridInfo>
                        </Card.Grid>
                        {result.alertas_documentos.map(documentAlert => {
                            return (
                                <Card.Grid>
                                    <CardGridTittle>
                                        <FormattedMessage
                                            id="app.subject-validation.result.spc-result.personal-data-result.documents-alert"
                                            defaultMessage="Alerta de documentos"
                                        />
                                    </CardGridTittle>
                                    <CardGridInfo>
                                        {documentAlert.motivo ? (
                                            <CardGridItem>
                                                <FormattedMessage
                                                    id="app.subject-validation.result.spc-result.personal-data-result.reason"
                                                    defaultMessage="Motivo: "
                                                />
                                                <strong>
                                                    {' '}
                                                    {documentAlert.motivo}
                                                </strong>
                                            </CardGridItem>
                                        ) : null}
                                        {documentAlert.entidade_origem ? (
                                            <CardGridItem>
                                                <FormattedMessage
                                                    id="app.subject-validation.result.spc-result.personal-data-result.origin-entity"
                                                    defaultMessage="Entidade de origem: "
                                                />
                                                <strong>
                                                    {' '}
                                                    {
                                                        documentAlert.entidade_origem
                                                    }
                                                </strong>
                                            </CardGridItem>
                                        ) : null}
                                        {documentAlert.observacao ? (
                                            <CardGridItem>
                                                <FormattedMessage
                                                    id="app.subject-validation.result.spc-result.personal-data-result.observation"
                                                    defaultMessage="Observação: "
                                                />
                                                <strong>
                                                    {' '}
                                                    {documentAlert.observacao}
                                                </strong>
                                            </CardGridItem>
                                        ) : null}
                                        {documentAlert.data_ocorrencia ? (
                                            <CardGridItem>
                                                <FormattedMessage
                                                    id="app.subject-validation.result.spc-result.personal-data-result.occurrence-date"
                                                    defaultMessage="Data da ocorrência: "
                                                />
                                                <strong>
                                                    {' '}
                                                    {formatDate(
                                                        documentAlert.data_ocorrencia,
                                                    )}
                                                </strong>
                                            </CardGridItem>
                                        ) : null}
                                        {documentAlert.data_inclusao ? (
                                            <CardGridItem>
                                                <FormattedMessage
                                                    id="app.subject-validation.result.spc-result.personal-data-result.inclusion-date"
                                                    defaultMessage="Data da inclusão: "
                                                />
                                                <strong>
                                                    {' '}
                                                    {formatDate(
                                                        documentAlert.data_inclusao,
                                                    )}
                                                </strong>
                                            </CardGridItem>
                                        ) : null}

                                        {documentAlert.tipo_documento_alerta
                                            .length > 0 ? (
                                            <CardGridItem>
                                                <FormattedMessage
                                                    id="app.subject-validation.result.spc-result.personal-data-result.document-types"
                                                    defaultMessage="Tipos de documento: "
                                                />
                                                <strong>
                                                    {' '}
                                                    {documentAlert.tipo_documento_alerta.map(
                                                        type => {
                                                            return (
                                                                <strong>
                                                                    {' '}
                                                                    {type.nome}
                                                                </strong>
                                                            );
                                                        },
                                                    )}
                                                </strong>
                                            </CardGridItem>
                                        ) : null}
                                    </CardGridInfo>
                                </Card.Grid>
                            );
                        })}
                        {result.ultimos_enderecos_informados.map(
                            lastAddress => {
                                return (
                                    <Card.Grid>
                                        <CardGridTittle>
                                            <FormattedMessage
                                                id="app.subject-validation.result.spc-result.personal-data-result.last-address"
                                                defaultMessage="Últimos endereços informados"
                                            />
                                        </CardGridTittle>
                                        <CardGridInfo>
                                            <CardGridItem>
                                                <FormattedMessage
                                                    id="app.subject-validation.result.spc-result.personal-data-result.street"
                                                    defaultMessage="Logradouro: "
                                                />
                                                <strong>
                                                    {' '}
                                                    {lastAddress.logradouro}
                                                </strong>
                                            </CardGridItem>
                                            <CardGridItem>
                                                <FormattedMessage
                                                    id="app.subject-validation.result.spc-result.personal-data-result.number"
                                                    defaultMessage="Número: "
                                                />
                                                <strong>
                                                    {' '}
                                                    {lastAddress.numero}
                                                </strong>
                                            </CardGridItem>
                                            <CardGridItem>
                                                <FormattedMessage
                                                    id="app.subject-validation.result.spc-result.personal-data-result.complement"
                                                    defaultMessage="Complemento: "
                                                />
                                                <strong>
                                                    {' '}
                                                    {lastAddress.complemento}
                                                </strong>
                                            </CardGridItem>
                                            <CardGridItem>
                                                <FormattedMessage
                                                    id="app.subject-validation.result.spc-result.personal-data-result.neighborhood"
                                                    defaultMessage="Bairro: "
                                                />
                                                <strong>
                                                    {' '}
                                                    {lastAddress.bairro}
                                                </strong>
                                            </CardGridItem>
                                            <CardGridItem>
                                                <FormattedMessage
                                                    id="app.subject-validation.result.spc-result.personal-data-result.cep"
                                                    defaultMessage="CEP: "
                                                />
                                                <strong>
                                                    {' '}
                                                    {lastAddress.cep}
                                                </strong>
                                            </CardGridItem>
                                            <CardGridItem>
                                                <FormattedMessage
                                                    id="app.subject-validation.result.spc-result.personal-data-result.city"
                                                    defaultMessage="Cidade: "
                                                />
                                                <strong>
                                                    {' '}
                                                    {lastAddress?.cidade.nome}
                                                </strong>
                                            </CardGridItem>
                                            <CardGridItem>
                                                <FormattedMessage
                                                    id="app.subject-validation.result.spc-result.personal-data-result.state"
                                                    defaultMessage="Estado: "
                                                />
                                                <strong>
                                                    {' '}
                                                    {
                                                        lastAddress?.cidade
                                                            ?.estado?.sigla_uf
                                                    }
                                                </strong>
                                            </CardGridItem>
                                        </CardGridInfo>
                                    </Card.Grid>
                                );
                            },
                        )}
                        {result.ultimos_telefones_informados.map(phone => {
                            return (
                                <Card.Grid>
                                    <CardGridTittle>
                                        <FormattedMessage
                                            id="app.subject-validation.result.spc-result.personal-data-result.last-phones"
                                            defaultMessage="Últimos telefones informados"
                                        />
                                    </CardGridTittle>
                                    <CardGridInfo>
                                        <CardGridItem>
                                            <FormattedMessage
                                                id="app.subject-validation.result.spc-result.personal-data-result.phone"
                                                defaultMessage="Telefone: "
                                            />
                                            <strong>
                                                {' '}
                                                {phone.numero_ddd}{' '}
                                                {phone.numero}
                                            </strong>
                                        </CardGridItem>
                                    </CardGridInfo>
                                </Card.Grid>
                            );
                        })}
                    </Row>
                </>
            )}
        </Container>
    );
};

export default observer(PersonalDataResult);
