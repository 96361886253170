/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Container = styled.div`
    padding: 20px;
`;

export const CardGridTittle = styled.h2`
    margin-bottom: 20px;

    font-size: 24px;
    line-height: 32px;

    display: flex;
    align-items: center;

    color: #262626;
`;

export const CardGridInfo = styled.div`
    margin-bottom: 20px;
    padding: 20px;
    background-color: #f7f8fb;
    max-height: min-content;
    border-radius: 20px;
    color: #262626;
    display: flex;
    flex-wrap: wrap;
`;

export const CardGridItem = styled.div`
    flex: auto;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #fff;
    font-size: 16px;
    max-height: min-content;
    border-radius: 8px;
    color: #262626;
    margin-right: 8px;
`;
