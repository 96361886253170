import React from 'react';
import { Layout } from 'antd';
import { observer } from 'mobx-react';
import SideBar from '../../../components/SideBar';
import { useStore } from '../../../hooks';

import { StyledLayout, StyledContent, StyledFooter } from './styles';
import NavBar from '../../../components/NavBar';

interface DashboardLayoutProps {
    children: React.ReactChildren;
}

const DashboardLayout = ({
    children,
}: DashboardLayoutProps): React.ReactElement => {
    const { store } = useStore();
    return (
        <StyledLayout>
            <SideBar />
            <Layout>
                <NavBar />
                <div id="modal-root" />
                <StyledContent>{children}</StyledContent>
                {store.uiStateStore.showFooter && (
                    <StyledFooter>
                        <div />
                    </StyledFooter>
                )}
            </Layout>
        </StyledLayout>
    );
};

export default observer(DashboardLayout);
