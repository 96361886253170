// import { ExclamationCircleOutlined } from '@ant-design/icons';
import { CameraOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Form, Input, Image, message, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { cpfMask } from 'helpers/mask';
import { isValidCpf } from 'helpers/validation';
import { useStore } from 'hooks';
import { SectionTitle } from 'pages/private/Settings/Profile/styles';
import { StyledTakePhotoButton } from 'pages/private/SubjectValidation/Form/styles';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactJson from 'react-json-view';
import WebcamCapture from '../../../../components/Webcam';
import {
    Section,
    Container,
    Content,
    DataContainer,
    ImagePreview,
    ResultContent,
    ResultData,
} from './styles';
import { imagePlaceHolder } from '../../../../helpers/images';

interface SearchResult {
    cpf: string;
    nome: string;
}

interface ImageContent {
    file: File | Blob;
    preview: string | undefined;
}

const SubjectVerifyForm = (): React.ReactElement => {
    const screen = useBreakpoint();
    const [form] = useForm();
    const { store } = useStore();
    // const { confirm } = Modal;
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [verifyLoading, setVerifyLoading] = useState(false);
    const [, setSearchResult] = useState<SearchResult | null>(null);
    const [verifyResult, setVerifyResult] = useState(null);
    const [subjectImage, setSubjectImage] = useState<string | undefined>(
        undefined,
    );
    const [cpfValidated, setCpfValidated] = useState(false);
    const [selfie, setSelfie] = useState<ImageContent | undefined>(undefined);
    const [isTakeSelfieVisible, setIsTakeSelfieVisible] = useState(false);

    const cpfValidator = (
        _rule: any,
        value: any,
        callback: (error?: string) => void,
    ) => {
        if (isValidCpf(value)) {
            return callback();
        }
        return callback(
            formatMessage({
                id: 'app.settings.profile.errors.cpf.invalid',
                defaultMessage: 'CPF inválido',
            }),
        );
    };

    const handleSearchSubject = () => {
        setSearchResult(null);
        setSubjectImage(undefined);
        setLoading(true);
        form.validateFields()
            .then(values => {
                store.subjectVerifyStore
                    .searchSubject(values)
                    .then(() => {
                        message.success(
                            formatMessage({
                                id: 'app.subject.search.form.success',
                                defaultMessage:
                                    'Pesquisa realizada com sucesso.',
                            }),
                        );
                        setSearchResult({
                            cpf: store.subjectVerifyStore.searchResponse.cpf,
                            nome: store.subjectVerifyStore.searchResponse.data
                                .cnh.nome,
                        });
                        setSubjectImage(
                            `data:image/png;base64,${store.subjectVerifyStore.returnedSelfie}`,
                        );
                        setLoading(false);
                    })
                    .catch(err => {
                        if (err.response)
                            message.error(err.response.data.message);
                        setLoading(false);
                    });
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const renderArrayAsList = (messages: string[]) => {
        const messageList = messages.map(messageContent => (
            <li>{messageContent}</li>
        ));
        return (
            <ul
                style={{
                    marginLeft: 20,
                    textAlign: 'left',
                }}
            >
                {messageList}
            </ul>
        );
    };

    const handleVerify = () => {
        if (selfie?.file) {
            setVerifyLoading(true);
            store.subjectVerifyStore
                .verifySubject(form.getFieldValue('cpf'), selfie?.file)
                .then(res => {
                    setVerifyResult(res);
                    setVerifyLoading(false);
                })
                .catch(err => {
                    if (err.response.data.message.description) {
                        const msg = (
                            <>
                                <span>
                                    {err.response.data.message.description}
                                </span>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                    }}
                                >
                                    {err.response.data.message.error_fields
                                        .selfie && (
                                        <>
                                            <br />
                                            <div>
                                                <div
                                                    style={{
                                                        textAlign: 'left',
                                                    }}
                                                >
                                                    Selfie:
                                                </div>
                                                {renderArrayAsList(
                                                    err.response.data.message
                                                        .error_fields.selfie,
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </>
                        );
                        message.error(msg, 8);
                    } else {
                        message.error(err.response.data.message);
                    }
                    setVerifyLoading(false);
                });
        }
    };

    const handleClear = () => {
        form.resetFields();
        setSearchResult(null);
        setVerifyResult(null);
        setSubjectImage(undefined);
    };

    const handleCpfInputChange = () => {
        const validCpf = isValidCpf(form.getFieldValue('cpf'));
        setCpfValidated(validCpf);
        if (form.getFieldValue('cpf').length === 14) {
            setCpfValidated(validCpf);
            form.validateFields();
        }
        if (form.getFieldValue('cpf').length === 0) {
            handleClear();
        }
    };

    const DataURIToBlob = (dataURI: string) => {
        const splitDataURI = dataURI.split(',');
        const byteString =
            splitDataURI[0].indexOf('base64') >= 0
                ? atob(splitDataURI[1])
                : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i += 1)
            ia[i] = byteString.charCodeAt(i);

        return new Blob([ia], { type: mimeString });
    };

    const handleStoreSelfie = webcamPhoto => {
        store.subjectVerifyStore.setSelfie(webcamPhoto);
    };

    const handleGetStoredSelfie = () => {
        return store.subjectVerifyStore.selfie;
    };

    const showTakeSelfieModal = () => {
        setIsTakeSelfieVisible(true);
    };

    const handleTakeSelfie = () => {
        setSelfie({
            file: DataURIToBlob(store.subjectVerifyStore.selfie),
            preview: store.subjectVerifyStore.selfie,
        });
        if (store.subjectVerifyStore.selfie) {
            form.setFieldsValue({
                selfie: DataURIToBlob(store.subjectVerifyStore.selfie),
            });
            setIsTakeSelfieVisible(false);
        }
    };

    const handleTakeSelfieCancel = () => {
        setIsTakeSelfieVisible(false);
        store.subjectVerifyStore.setSelfie('');
    };

    const handleRemoveSelfie = () => {
        form.setFieldsValue({
            selfie: '',
        });
        store.subjectVerifyStore.setSelfie(null);
        if (selfie) {
            setSelfie(undefined);
        }
        if (verifyResult) setVerifyResult(null);
    };

    return (
        <Container mobile={!screen.md}>
            <DataContainer mobile={!screen.md}>
                <Content mobile={!screen.md}>
                    <Form
                        name="subject-enroll-form"
                        form={form}
                        layout="vertical"
                        requiredMark={false}
                        style={{ width: 420, marginTop: 8 }}
                        validateTrigger="onSubmit"
                    >
                        <Section mobile={!screen.md}>
                            <SectionTitle>
                                <FormattedMessage
                                    id="app.subject-enroll.form.subject-data"
                                    defaultMessage="Dados do sujeito"
                                />
                            </SectionTitle>
                            <h3>
                                <FormattedMessage
                                    id="app.subject.verify.form.first.info"
                                    defaultMessage="Informe o sujeito que deseja comparar: "
                                />
                            </h3>
                            <Form.Item
                                name="cpf"
                                validateStatus="success"
                                label={
                                    <FormattedMessage
                                        id="app.subject-validation.form.cpf"
                                        defaultMessage="CPF *"
                                    />
                                }
                                normalize={cpfMask}
                                rules={[
                                    {
                                        required: true,
                                        pattern: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
                                        message: formatMessage({
                                            id: 'app.subject-validation.form.errors.cpf-pattern',
                                            defaultMessage:
                                                'Preencha um CPF válido, como 123.456.789-01',
                                        }),
                                    },
                                    {
                                        validator: cpfValidator,
                                    },
                                ]}
                            >
                                <Input.Search
                                    placeholder="Ex: 123.456.789-00"
                                    onChange={handleCpfInputChange}
                                    allowClear
                                    enterButton={
                                        <Button
                                            type="primary"
                                            onClick={handleSearchSubject}
                                            disabled={!cpfValidated}
                                            loading={loading}
                                        >
                                            Buscar
                                        </Button>
                                    }
                                />
                            </Form.Item>
                            <>
                                <h3 style={{ marginTop: 10 }}>
                                    <FormattedMessage
                                        id="app.subject.verify.form.second.info"
                                        defaultMessage="Capture a selfie: "
                                    />
                                </h3>
                                <StyledTakePhotoButton
                                    onClick={showTakeSelfieModal}
                                    icon={<CameraOutlined />}
                                    disabled={!!selfie}
                                >
                                    <FormattedMessage
                                        id="app.subject-validation.form.take-photo"
                                        defaultMessage="Take photo"
                                    />
                                </StyledTakePhotoButton>
                                <Button
                                    onClick={handleRemoveSelfie}
                                    icon={<DeleteOutlined />}
                                />
                            </>
                        </Section>
                    </Form>
                </Content>
                <ResultContent mobile={!screen.md}>
                    <div>
                        Imagem Cadastrada:
                        <ImagePreview>
                            <Image
                                alt="subjectImage"
                                src={subjectImage || imagePlaceHolder()}
                                width="300px"
                                height="auto"
                                preview={!!subjectImage}
                            />
                        </ImagePreview>
                    </div>
                    <Button
                        type="primary"
                        onClick={handleVerify}
                        loading={verifyLoading}
                        disabled={!subjectImage || !selfie}
                    >
                        Verificar
                    </Button>
                    <div>
                        Selfie:
                        <ImagePreview>
                            <Image
                                alt="subjectImage"
                                src={selfie?.preview || imagePlaceHolder()}
                                width="300px"
                                height="auto"
                                preview={!!selfie}
                            />
                        </ImagePreview>
                    </div>
                </ResultContent>
            </DataContainer>
            <ResultData>
                {verifyResult && (
                    <>
                        <h2 style={{ marginRight: 15 }}>Resultado: </h2>
                        <>
                            <ReactJson
                                src={verifyResult}
                                name={null}
                                enableClipboard={false}
                                displayDataTypes={false}
                                displayObjectSize={false}
                            />
                        </>
                    </>
                )}
            </ResultData>
            <Modal
                title={
                    <FormattedMessage
                        id="app.subject-validation.form.photo"
                        defaultMessage="Foto"
                    />
                }
                visible={isTakeSelfieVisible}
                onOk={handleTakeSelfie}
                onCancel={handleTakeSelfieCancel}
                okText={
                    <FormattedMessage
                        id="app.settings.profile.button.save"
                        defaultMessage="Salvar"
                    />
                }
                cancelText={
                    <FormattedMessage
                        id="app.settings.profile.button.cancel"
                        defaultMessage="Cancelar"
                    />
                }
            >
                <WebcamCapture
                    storeWebcamPhoto={handleStoreSelfie}
                    getStoredWebcamPhoto={handleGetStoredSelfie}
                />
            </Modal>
        </Container>
    );
};

export default SubjectVerifyForm;
