import styled from 'styled-components';
import { Button, Layout } from 'antd';

const { Footer, Content } = Layout;

export const StyledLayout = styled(Layout)`
    height: 100%;
    aside {
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
        height: 100%;
    }
`;

export const StyledContent = styled(Content)`
    overflow: auto;
    background-color: #fff;
`;

export const StyledFooter = styled(Footer)`
    background-color: #fff;
    border-top: 2px;
    border-top-style: solid;
    border-top-color: #d9d9d9;

    display: flex;
    justify-content: space-between;

    div {
        display: flex;
        flex-direction: row;
    }

    p {
        color: #8c8c8c;
        margin: 0 15px;
        cursor: pointer;
    }

    @media (max-width: 576px) {
        height: 70px;
        padding: 0 10px 0 10px;
        align-items: center;
    }
`;

export const StyledTextButton = styled(Button)`
    color: #8c8c8c !important;
    margin: 0 15px;
    padding: 0;
`;
