import { Form, Input, Image, message, Button } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { cpfMask } from 'helpers/mask';
import { isValidCpf } from 'helpers/validation';
import { useStore } from 'hooks';
import { SectionTitle } from 'pages/private/Settings/Profile/styles';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactJson from 'react-json-view';
import {
    Section,
    Container,
    FormContent,
    ResultContent,
    ImagePreview,
} from './styles';

const SubjectSearchForm = (): React.ReactElement => {
    const screen = useBreakpoint();
    const [form] = useForm();
    const { store } = useStore();
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [searchResult, setSearchResult] = useState(null);
    const [selfie, setSelfie] = useState(null);
    const [cpfValidated, setCpfValidated] = useState(false);

    const cpfValidator = (
        _rule: any,
        value: any,
        callback: (error?: string) => void,
    ) => {
        if (isValidCpf(value)) {
            return callback();
        }
        return callback(
            formatMessage({
                id: 'app.settings.profile.errors.cpf.invalid',
                defaultMessage: 'CPF inválido',
            }),
        );
    };

    const handleSend = () => {
        setSearchResult(null);
        setSelfie(null);
        setLoading(true);
        form.validateFields()
            .then(values => {
                store.subjectSearchStore
                    .searchSubject(values)
                    .then(() => {
                        message.success(
                            formatMessage({
                                id: 'app.subject.search.form.success',
                                defaultMessage:
                                    'Pesquisa realizada com sucesso.',
                            }),
                            5,
                        );
                        setSearchResult(
                            store.subjectSearchStore.searchResponse,
                        );
                        setSelfie(store.subjectSearchStore.returnedSelfie);
                        setLoading(false);
                    })
                    .catch(err => {
                        if (err.response)
                            message.error(err.response.data.message, 5);
                        setLoading(false);
                    });
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const handleClear = () => {
        form.resetFields();
        setSearchResult(null);
        setSelfie(null);
    };

    const handleCpfInputChange = () => {
        const validCpf = isValidCpf(form.getFieldValue('cpf'));
        setCpfValidated(validCpf);
        if (form.getFieldValue('cpf').length === 14) {
            setCpfValidated(validCpf);
            form.validateFields();
        }
        if (form.getFieldValue('cpf').length === 0) {
            handleClear();
        }
    };

    return (
        <Container mobile={!screen.md}>
            <FormContent>
                <Form
                    name="subject-enroll-form"
                    form={form}
                    layout="vertical"
                    requiredMark={false}
                    style={{ width: 320, marginTop: 8 }}
                    validateTrigger="onSubmit"
                >
                    <Section mobile={!screen.md}>
                        <SectionTitle>
                            <FormattedMessage
                                id="app.subject-enroll.form.subject-data"
                                defaultMessage="Dados do sujeito"
                            />
                        </SectionTitle>
                        <Form.Item
                            name="cpf"
                            label={
                                <FormattedMessage
                                    id="app.subject-validation.form.cpf"
                                    defaultMessage="CPF *"
                                />
                            }
                            normalize={cpfMask}
                            rules={[
                                {
                                    required: true,
                                    pattern: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
                                    message: formatMessage({
                                        id: 'app.subject-validation.form.errors.cpf-pattern',
                                        defaultMessage:
                                            'Preencha um CPF válido, como 123.456.789-00',
                                    }),
                                },
                                {
                                    validator: cpfValidator,
                                },
                            ]}
                        >
                            <Input.Search
                                placeholder="Ex: 123.456.789-00"
                                onChange={handleCpfInputChange}
                                allowClear
                                enterButton={
                                    <Button
                                        type="primary"
                                        onClick={handleSend}
                                        disabled={!cpfValidated}
                                        loading={loading}
                                    >
                                        Buscar
                                    </Button>
                                }
                            />
                        </Form.Item>
                    </Section>
                </Form>
            </FormContent>
            <ResultContent mobile={!screen.md}>
                {searchResult && (
                    <Section mobile={!screen.md}>
                        <SectionTitle>
                            <FormattedMessage
                                id="app.subject-enroll.form.result"
                                defaultMessage="Resultado"
                            />
                        </SectionTitle>
                        <ReactJson
                            src={searchResult}
                            name={null}
                            enableClipboard={false}
                            displayDataTypes={false}
                            displayObjectSize={false}
                            collapseStringsAfterLength={60}
                        />
                    </Section>
                )}
                {selfie && (
                    <ImagePreview>
                        <Image
                            alt="selfie"
                            src={`data:image/png;base64,${selfie}`}
                            width="300px"
                            height="auto"
                        />
                    </ImagePreview>
                )}
            </ResultContent>
        </Container>
    );
};

export default SubjectSearchForm;
