// import { ExclamationCircleOutlined } from '@ant-design/icons';
import { CameraOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Form, Image, message, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useStore } from 'hooks';
import { SectionTitle } from 'pages/private/Settings/Profile/styles';
import { StyledTakePhotoButton } from 'pages/private/SubjectValidation/Form/styles';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactJson from 'react-json-view';
import WebcamCapture from '../../../../components/Webcam';
import {
    Section,
    Container,
    Content,
    DataContainer,
    ImagePreview,
    ResultContent,
} from './styles';
import { imagePlaceHolder } from '../../../../helpers/images';
import Footer from '../Footer';

interface ImageContent {
    file: File | Blob;
    preview: string | undefined;
}

const SubjectIdentifyForm = (): React.ReactElement => {
    const screen = useBreakpoint();
    const [form] = useForm();
    const { store } = useStore();
    // const { confirm } = Modal;
    const [identifyLoading, setIdentifyLoading] = useState(false);
    const [identifyResult, setIdentifyResult] = useState(null);
    const [subjectImage, setSubjectImage] = useState<string | undefined>(
        undefined,
    );
    const [selfie, setSelfie] = useState<ImageContent | undefined>(undefined);
    const [isTakeSelfieVisible, setIsTakeSelfieVisible] = useState(false);

    const handleIdentify = () => {
        setSubjectImage(undefined);
        if (selfie?.file) {
            setIdentifyLoading(true);
            store.subjectIdentifyStore
                .identifySubject(selfie?.file)
                .then(res => {
                    setIdentifyResult(res);
                    setSubjectImage(store.subjectIdentifyStore.returnedSelfie);
                    setIdentifyLoading(false);
                })
                .catch(err => {
                    if (err.response)
                        message.error(err.response.data.message, 5);
                    setIdentifyLoading(false);
                });
        }
    };

    const DataURIToBlob = (dataURI: string) => {
        const splitDataURI = dataURI.split(',');
        const byteString =
            splitDataURI[0].indexOf('base64') >= 0
                ? atob(splitDataURI[1])
                : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i += 1)
            ia[i] = byteString.charCodeAt(i);

        return new Blob([ia], { type: mimeString });
    };

    const handleStoreSelfie = webcamPhoto => {
        store.subjectIdentifyStore.setSelfie(webcamPhoto);
    };

    const handleGetStoredSelfie = () => {
        return store.subjectIdentifyStore.selfie;
    };

    const showTakeSelfieModal = () => {
        setIsTakeSelfieVisible(true);
    };

    const handleTakeSelfie = () => {
        setSelfie({
            file: DataURIToBlob(store.subjectIdentifyStore.selfie),
            preview: store.subjectIdentifyStore.selfie,
        });
        if (store.subjectIdentifyStore.selfie) {
            form.setFieldsValue({
                selfie: DataURIToBlob(store.subjectIdentifyStore.selfie),
            });
            setIsTakeSelfieVisible(false);
        }
    };

    const handleTakeSelfieCancel = () => {
        setIsTakeSelfieVisible(false);
        store.subjectIdentifyStore.setSelfie('');
    };

    const handleRemoveSelfie = () => {
        form.setFieldsValue({
            selfie: '',
        });
        store.subjectIdentifyStore.setSelfie(null);
        if (selfie) {
            setSelfie(undefined);
        }
        if (identifyResult) {
            setIdentifyResult(null);
            setSubjectImage(undefined);
        }
    };

    const handleClear = () => {
        form.resetFields();
        handleRemoveSelfie();
        store.subjectIdentifyStore.setIdentifyResponse(null);
    };

    return (
        <Container mobile={!screen.md}>
            <DataContainer mobile={!screen.md}>
                <Content mobile={!screen.md}>
                    <Form
                        name="subject-identify-form"
                        form={form}
                        layout="vertical"
                        requiredMark={false}
                        style={{ width: 420, marginTop: 8 }}
                        validateTrigger="onSubmit"
                    >
                        <Section mobile={!screen.md}>
                            <SectionTitle>
                                <FormattedMessage
                                    id="app.subject-enroll.form.subject-data"
                                    defaultMessage="Dados do sujeito"
                                />
                            </SectionTitle>
                            <>
                                <Form.Item
                                    name="selfie"
                                    label={
                                        <FormattedMessage
                                            id="app.selfie.form.subject-data"
                                            defaultMessage="Capture a selfie: *"
                                        />
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Por favor, capture a selfie.',
                                        },
                                    ]}
                                >
                                    <StyledTakePhotoButton
                                        onClick={showTakeSelfieModal}
                                        icon={<CameraOutlined />}
                                        disabled={!!selfie}
                                    >
                                        <FormattedMessage
                                            id="app.subject-validation.form.take-photo"
                                            defaultMessage="Take photo"
                                        />
                                    </StyledTakePhotoButton>
                                    <Button
                                        onClick={handleRemoveSelfie}
                                        icon={<DeleteOutlined />}
                                    />
                                </Form.Item>
                                {selfie && (
                                    <ImagePreview>
                                        <Image
                                            alt="selfie"
                                            src={
                                                selfie?.preview ||
                                                imagePlaceHolder()
                                            }
                                        />
                                    </ImagePreview>
                                )}
                            </>
                        </Section>
                    </Form>
                </Content>
                <ResultContent mobile={!screen.md}>
                    {identifyResult && (
                        <Section mobile={!screen.md}>
                            <SectionTitle>
                                <FormattedMessage
                                    id="app.subject-enroll.form.result"
                                    defaultMessage="Resultado"
                                />
                            </SectionTitle>
                            <ReactJson
                                src={identifyResult}
                                name={null}
                                enableClipboard={false}
                                displayDataTypes={false}
                                displayObjectSize={false}
                                collapseStringsAfterLength={60}
                            />
                        </Section>
                    )}
                    {subjectImage && (
                        <ImagePreview>
                            <Image
                                alt="selfie"
                                src={`data:image/png;base64,${subjectImage}`}
                                width="300px"
                                height="auto"
                            />
                        </ImagePreview>
                    )}
                </ResultContent>
            </DataContainer>
            <Modal
                title={
                    <FormattedMessage
                        id="app.subject-validation.form.photo"
                        defaultMessage="Foto"
                    />
                }
                visible={isTakeSelfieVisible}
                onOk={handleTakeSelfie}
                onCancel={handleTakeSelfieCancel}
                okText={
                    <FormattedMessage
                        id="app.settings.profile.button.save"
                        defaultMessage="Salvar"
                    />
                }
                cancelText={
                    <FormattedMessage
                        id="app.settings.profile.button.cancel"
                        defaultMessage="Cancelar"
                    />
                }
            >
                <WebcamCapture
                    storeWebcamPhoto={handleStoreSelfie}
                    getStoredWebcamPhoto={handleGetStoredSelfie}
                />
            </Modal>
            <Footer
                onSend={handleIdentify}
                loading={identifyLoading}
                onClear={handleClear}
            />
        </Container>
    );
};

export default SubjectIdentifyForm;
