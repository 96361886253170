import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { Grid, Button } from 'antd';
import { CloseCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { useStore } from '../../../../hooks';
import {
    StyledFooter,
    StyledMobileButtons,
    StyledMobileButtonWrapper,
    StyledDesktopButtons,
} from './styles';

const { useBreakpoint } = Grid;

interface FooterProps {
    onSave: () => void;
    onCancel: () => void;
}

const ProfileFooter = ({
    onSave,
    onCancel,
}: FooterProps): React.ReactElement => {
    const screen = useBreakpoint();
    const history = useHistory();
    const { store } = useStore();

    const handleBack = useCallback(() => {
        history.goBack();
    }, [history]);

    useEffect(() => {
        store.uiStateStore.setShowFooter(false);
        if (screen.xs) {
            store.uiStateStore.setNavBarBackAction(handleBack);
        } else {
            store.uiStateStore.setNavBarBackAction(null);
        }
    }, [screen.xs, store.uiStateStore, history, handleBack]);

    return (
        <StyledFooter>
            {screen.xs ? (
                <StyledMobileButtons>
                    <StyledMobileButtonWrapper>
                        <Button
                            onClick={onCancel}
                            shape="circle"
                            icon={<CloseCircleOutlined />}
                        />
                        <span>
                            <FormattedMessage
                                id="app.settings.button.cancel"
                                defaultMessage="Cancelar"
                            />
                        </span>
                    </StyledMobileButtonWrapper>
                    <StyledMobileButtonWrapper>
                        <Button
                            onClick={onSave}
                            type="primary"
                            shape="circle"
                            icon={<SaveOutlined />}
                        />
                        <span>
                            <FormattedMessage
                                id="app.settings.button.save"
                                defaultMessage="Salvar"
                            />
                        </span>
                    </StyledMobileButtonWrapper>
                </StyledMobileButtons>
            ) : (
                <StyledDesktopButtons>
                    <Button icon={<CloseCircleOutlined />} onClick={onCancel}>
                        <span>
                            <FormattedMessage
                                id="app.settings.button.cancel"
                                defaultMessage="Cancelar"
                            />
                        </span>
                    </Button>
                    <Button
                        type="primary"
                        icon={<SaveOutlined />}
                        style={{ marginLeft: 8 }}
                        onClick={onSave}
                    >
                        <span>
                            <FormattedMessage
                                id="app.settings.button.save"
                                defaultMessage="Salvar"
                            />
                        </span>
                    </Button>
                </StyledDesktopButtons>
            )}
        </StyledFooter>
    );
};

export default observer(ProfileFooter);
