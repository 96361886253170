import React, { useCallback, useRef, useState } from 'react';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { Form, Input, Modal, Image, Button, message, Radio } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { Section, SectionTitle } from 'pages/private/Settings/Profile/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { isValidCpf } from 'helpers/validation';
import { StyledTakePhotoButton } from 'pages/private/SubjectValidation/Form/styles';
import {
    CameraOutlined,
    DeleteOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import { useStore } from 'hooks';
import ReactJson from 'react-json-view';
import { cpfMask } from '../../../../helpers/mask';
import WebcamCapture from '../../../../components/Webcam';
import { Container, Content, FileUpload, ImagePreview } from './styles';
import Footer from '../Footer';

interface ImageContent {
    file: File;
    preview: string | undefined;
}

const SubjectEnrollForm = (): React.ReactElement => {
    const screen = useBreakpoint();
    const [form] = useForm();
    const { store } = useStore();
    const [selfie, setSelfie] = useState(null);
    const [docPhoto, setDocPhoto] = useState(null);
    const hiddenFileInputRef = useRef<HTMLInputElement>(null);
    const [loading, setLoading] = useState(false);
    const [docPhotoUploaded, setDocPhotoUploaded] = useState<ImageContent>();
    const [isTakeSelfieVisible, setIsTakeSelfieVisible] = useState(false);
    const [isTakeDocPhotoVisible, setIsTakeDocPhotoVisible] = useState(false);
    const [docPhotoType, setDocPhotoType] = useState(1);
    const [enrollResult, setEnrollResult] = useState(null);
    const { formatMessage } = useIntl();

    const showTakeSelfieModal = () => {
        setIsTakeSelfieVisible(true);
    };

    const showTakeDocPhotoModal = () => {
        setIsTakeDocPhotoVisible(true);
    };

    const handleStoreSelfie = webcamPhoto => {
        store.subjectEnrollStore.setSelfie(webcamPhoto);
    };

    const handleGetStoredSelfie = () => {
        return store.subjectEnrollStore.selfie;
    };

    const handleStoreDocPhoto = webcamPhoto => {
        store.subjectEnrollStore.setDocPhoto(webcamPhoto);
    };

    const handleGetDocPhoto = () => {
        return store.subjectEnrollStore.docPhoto;
    };

    const DataURIToBlob = (dataURI: string) => {
        const splitDataURI = dataURI.split(',');
        const byteString =
            splitDataURI[0].indexOf('base64') >= 0
                ? atob(splitDataURI[1])
                : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i += 1)
            ia[i] = byteString.charCodeAt(i);

        return new Blob([ia], { type: mimeString });
    };

    const formatFilename = (filename: string) => {
        if (filename.length >= 23) {
            const splitedFilename = filename.split('.');
            const extension = splitedFilename[splitedFilename.length - 1];

            const noExtensionFilename = filename.replace(`.${extension}`, '');
            const firstPart = noExtensionFilename.substring(0, 10);
            const secondPart = noExtensionFilename.substring(
                noExtensionFilename.length,
                noExtensionFilename.length - 10,
            );
            return `${firstPart}...${secondPart}.${extension}`;
        }
        return filename;
    };

    const handleTakeSelfie = () => {
        setSelfie(store.subjectEnrollStore.selfie);
        if (store.subjectEnrollStore.selfie) {
            form.setFieldsValue({
                selfie: DataURIToBlob(store.subjectEnrollStore.selfie),
            });
            setIsTakeSelfieVisible(false);
        }
    };

    const handleTakeSelfieCancel = () => {
        setIsTakeSelfieVisible(false);
        store.subjectEnrollStore.setSelfie('');
    };

    const handleRemoveSelfie = () => {
        form.setFieldsValue({
            selfie: '',
        });
        store.subjectEnrollStore.setSelfie(null);
        if (selfie) {
            setSelfie(null);
        }
    };

    const handleTakeDocPhoto = () => {
        setDocPhoto(store.subjectEnrollStore.docPhoto);
        if (store.subjectEnrollStore.docPhoto) {
            form.setFieldsValue({
                identificationDoc: DataURIToBlob(
                    store.subjectEnrollStore.docPhoto,
                ),
            });
            setIsTakeDocPhotoVisible(false);
        }
    };

    const handleTakeDocPhotoCancel = () => {
        setIsTakeDocPhotoVisible(false);
        store.subjectEnrollStore.setDocPhoto('');
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleRemoveDocPhoto = () => {
        if (hiddenFileInputRef.current?.value) {
            setDocPhotoUploaded(undefined);
        }
        form.setFieldsValue({
            identificationDoc: '',
        });
        store.subjectEnrollStore.setDocPhoto(null);
        if (docPhoto) {
            setDocPhoto(null);
        }
    };

    const handleClickUploadDocPhotoButton = () => {
        hiddenFileInputRef.current?.click();
    };

    const cpfValidator = (
        _rule: any,
        value: any,
        callback: (error?: string) => void,
    ) => {
        if (isValidCpf(value)) {
            return callback();
        }
        return callback(
            formatMessage({
                id: 'app.settings.profile.errors.cpf.invalid',
                defaultMessage: 'CPF inválido',
            }),
        );
    };

    const convertBase64 = useCallback(file => {
        return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                // const base64Img = fileReader.result.
                resolve(fileReader.result);
            };

            fileReader.onerror = error => {
                reject(error);
            };
        });
    }, []);

    const uploadImage = useCallback(
        async e => {
            if (e.target.files[0]) {
                const file = e.target.files[0];
                if (
                    file.type === 'image/jpeg' ||
                    file.type === 'image/png' ||
                    (docPhotoType === 2 &&
                        (file.type === 'application/pdf' ||
                            file.type === 'application/octet-stream'))
                ) {
                    const base64Img = await convertBase64(file);
                    form.setFieldsValue({
                        identificationDoc: file,
                    });
                    if (docPhotoUploaded?.file || docPhoto) {
                        handleRemoveDocPhoto();
                    }
                    setDocPhotoUploaded({
                        file,
                        preview: base64Img?.toString(),
                    });
                } else {
                    message.error(
                        formatMessage({
                            id: 'app.settings.profile.errors.file-type',
                            defaultMessage:
                                'Você só pode fazer upload de arquivos JPG/PNG.',
                        }),
                    );
                }
            }
        },
        [
            convertBase64,
            docPhotoType,
            docPhoto,
            docPhotoUploaded?.file,
            form,
            formatMessage,
            handleRemoveDocPhoto,
        ],
    );

    const renderArrayAsList = (messages: string[]) => {
        const messageList = messages.map(messageContent => (
            <li>{messageContent}</li>
        ));
        return (
            <ul
                style={{
                    marginLeft: 20,
                    textAlign: 'left',
                }}
            >
                {messageList}
            </ul>
        );
    };

    const handleSend = () => {
        setLoading(true);
        setEnrollResult(null);
        form.validateFields()
            .then(values => {
                const subjectEnrollData = {
                    ...values,
                    field: docPhotoType === 1 ? 'doc_image' : 'qrcode',
                };
                store.subjectEnrollStore
                    .fetchSubjectEnroll(subjectEnrollData)
                    .then(() => {
                        message.success(
                            formatMessage({
                                id: 'app.subject-enroll.form.success',
                                defaultMessage:
                                    'Sujeito cadastrado com sucesso.',
                            }),
                        );
                        setEnrollResult(
                            store.subjectEnrollStore.enrollResponse,
                        );
                        setLoading(false);
                    })
                    .catch(err => {
                        if (err.response.data.message.content) {
                            setEnrollResult(err.response.data.message.content);
                        }
                        if (err.response.data.message.description) {
                            const msg = (
                                <>
                                    <span>
                                        {err.response.data.message.description}
                                    </span>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                        }}
                                    >
                                        {err.response.data.message?.error_fields
                                            ?.doc_image && (
                                            <>
                                                <br />
                                                <div>
                                                    <div
                                                        style={{
                                                            textAlign: 'left',
                                                        }}
                                                    >
                                                        Imagem do Documento:
                                                    </div>
                                                    {renderArrayAsList(
                                                        err.response.data
                                                            .message
                                                            .error_fields
                                                            .doc_image,
                                                    )}
                                                </div>
                                            </>
                                        )}

                                        {err.response.data.message?.error_fields
                                            ?.qrcode && (
                                            <>
                                                <br />
                                                <div>
                                                    <div
                                                        style={{
                                                            textAlign: 'left',
                                                        }}
                                                    >
                                                        QR code do Documento:
                                                    </div>
                                                    {renderArrayAsList(
                                                        err.response.data
                                                            .message
                                                            .error_fields
                                                            .qrcode,
                                                    )}
                                                </div>
                                            </>
                                        )}

                                        {err.response.data.message?.error_fields
                                            ?.selfie && (
                                            <>
                                                <br />
                                                <div>
                                                    <div
                                                        style={{
                                                            textAlign: 'left',
                                                        }}
                                                    >
                                                        Selfie:
                                                    </div>
                                                    {renderArrayAsList(
                                                        err.response.data
                                                            .message
                                                            .error_fields
                                                            .selfie,
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </>
                            );
                            message.error(msg, 8);
                        } else {
                            message.error(err.response.data.message);
                        }
                        setLoading(false);
                    });
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const handleClear = () => {
        form.resetFields();
        handleRemoveDocPhoto();
        handleRemoveSelfie();
        setEnrollResult(null);
        store.subjectEnrollStore.setEnrollResponse(null);
    };

    return (
        <Container mobile={!screen.md}>
            <Content>
                <Form
                    name="subject-enroll-form"
                    form={form}
                    layout="vertical"
                    requiredMark={false}
                    style={{ width: 320, marginTop: 8 }}
                    validateTrigger="onSubmit"
                >
                    <Section>
                        <SectionTitle>
                            <FormattedMessage
                                id="app.subject-enroll.form.subject-data"
                                defaultMessage="Dados do sujeito"
                            />
                        </SectionTitle>
                        <Form.Item
                            name="cpf"
                            label={
                                <FormattedMessage
                                    id="app.subject-validation.form.cpf"
                                    defaultMessage="CPF *"
                                />
                            }
                            normalize={cpfMask}
                            rules={[
                                {
                                    required: true,
                                    pattern: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
                                    message: formatMessage({
                                        id: 'app.subject-validation.form.errors.cpf-pattern',
                                        defaultMessage:
                                            'Preencha um CPF válido, como 123.456.789-00',
                                    }),
                                },
                                {
                                    validator: cpfValidator,
                                },
                            ]}
                        >
                            <Input placeholder="Ex: 123.456.789-00" />
                        </Form.Item>

                        <Form.Item
                            name="identificationDoc"
                            label={
                                <FormattedMessage
                                    id="app.identification-doc.form.subject-data"
                                    defaultMessage="Documento de Identificação *"
                                />
                            }
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Por favor, capture ou envie a foto do documento de identificação.',
                                },
                            ]}
                        >
                            <Radio.Group
                                onChange={e => {
                                    setDocPhotoType(e.target.value);
                                    handleRemoveDocPhoto();
                                }}
                                value={docPhotoType}
                                style={{ marginBottom: '10px' }}
                            >
                                <Radio value={1}>
                                    <span>
                                        <FormattedMessage
                                            id="app.identification-cnh.form.subject-data"
                                            defaultMessage="Foto do Documento"
                                        />
                                    </span>
                                </Radio>
                                <Radio value={2}>
                                    <span>
                                        <FormattedMessage
                                            id="app.identification-qrcode.form.subject-data"
                                            defaultMessage="Foto ou binário do QR code"
                                        />
                                    </span>
                                </Radio>
                            </Radio.Group>
                            <Button
                                onClick={handleClickUploadDocPhotoButton}
                                icon={<UploadOutlined />}
                                disabled={
                                    store.subjectValidationStore.takedPhoto
                                }
                            >
                                <span>
                                    <FormattedMessage
                                        id="app.subject-validation.form.add-photo"
                                        defaultMessage="Adicionar foto"
                                    />
                                    <FileUpload
                                        type="file"
                                        accept=".jpg,.jpeg,.png,.pdf,.bin"
                                        style={{ display: 'none' }}
                                        ref={hiddenFileInputRef}
                                        multiple={false}
                                        onChange={file => {
                                            uploadImage(file);
                                        }}
                                    />
                                </span>
                            </Button>
                            <StyledTakePhotoButton
                                onClick={showTakeDocPhotoModal}
                                icon={<CameraOutlined />}
                                disabled={
                                    !!docPhotoUploaded?.file || !!docPhoto
                                }
                            >
                                <FormattedMessage
                                    id="app.subject-validation.form.take-photo"
                                    defaultMessage="Take photo"
                                />
                            </StyledTakePhotoButton>
                            <Button
                                onClick={handleRemoveDocPhoto}
                                icon={<DeleteOutlined />}
                            />
                        </Form.Item>
                        {docPhotoUploaded?.file &&
                            formatFilename(docPhotoUploaded?.file.name)}
                        {docPhotoUploaded?.file.name && (
                            <ImagePreview>
                                <Image
                                    alt="doc-photo"
                                    src={docPhotoUploaded.preview}
                                />
                            </ImagePreview>
                        )}
                        {docPhoto && (
                            <ImagePreview>
                                <Image alt="doc-photo" src={docPhoto} />
                            </ImagePreview>
                        )}

                        <Form.Item
                            name="selfie"
                            label={
                                <FormattedMessage
                                    id="app.selfie.form.subject-data"
                                    defaultMessage="Selfie *"
                                />
                            }
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor, capture a selfie.',
                                },
                            ]}
                        >
                            <StyledTakePhotoButton
                                onClick={showTakeSelfieModal}
                                icon={<CameraOutlined />}
                                disabled={!!selfie}
                            >
                                <FormattedMessage
                                    id="app.subject-validation.form.take-photo"
                                    defaultMessage="Take photo"
                                />
                            </StyledTakePhotoButton>
                            <Button
                                onClick={handleRemoveSelfie}
                                icon={<DeleteOutlined />}
                            />
                        </Form.Item>
                        {selfie && (
                            <ImagePreview>
                                <Image alt="selfie" src={selfie} />
                            </ImagePreview>
                        )}
                    </Section>
                </Form>
                <Modal
                    title={
                        <FormattedMessage
                            id="app.subject-validation.form.photo"
                            defaultMessage="Foto"
                        />
                    }
                    visible={isTakeSelfieVisible}
                    onOk={handleTakeSelfie}
                    onCancel={handleTakeSelfieCancel}
                    okText={
                        <FormattedMessage
                            id="app.settings.profile.button.save"
                            defaultMessage="Salvar"
                        />
                    }
                    cancelText={
                        <FormattedMessage
                            id="app.settings.profile.button.cancel"
                            defaultMessage="Cancelar"
                        />
                    }
                >
                    <WebcamCapture
                        storeWebcamPhoto={handleStoreSelfie}
                        getStoredWebcamPhoto={handleGetStoredSelfie}
                    />
                </Modal>
                <Modal
                    title={
                        <FormattedMessage
                            id="app.subject-validation.form.photo"
                            defaultMessage="Foto"
                        />
                    }
                    visible={isTakeDocPhotoVisible}
                    onOk={handleTakeDocPhoto}
                    onCancel={handleTakeDocPhotoCancel}
                    okText={
                        <FormattedMessage
                            id="app.settings.profile.button.save"
                            defaultMessage="Salvar"
                        />
                    }
                    cancelText={
                        <FormattedMessage
                            id="app.settings.profile.button.cancel"
                            defaultMessage="Cancelar"
                        />
                    }
                >
                    <WebcamCapture
                        storeWebcamPhoto={handleStoreDocPhoto}
                        getStoredWebcamPhoto={handleGetDocPhoto}
                    />
                </Modal>
            </Content>
            <Content>
                {enrollResult && (
                    <Section>
                        <SectionTitle>
                            <FormattedMessage
                                id="app.subject-enroll.form.result"
                                defaultMessage="Resultado"
                            />
                        </SectionTitle>
                        <ReactJson
                            src={enrollResult}
                            name={null}
                            enableClipboard={false}
                            displayDataTypes={false}
                            displayObjectSize={false}
                        />
                    </Section>
                )}
            </Content>
            <Footer
                onSend={handleSend}
                loading={loading}
                onClear={handleClear}
            />
        </Container>
    );
};

export default SubjectEnrollForm;
