import React from 'react';
import { Grid, Menu } from 'antd';
import {
    SettingOutlined,
    CloseOutlined,
    SearchOutlined,
    UserOutlined,
    ScanOutlined,
} from '@ant-design/icons';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import { useStore } from '../../hooks';
import {
    StyledCloseButton,
    StyledDiv,
    // StyledSelect,
    StyledSider,
    StyledSpace,
} from './styles';
import BrandingMain from '../../assets/images/branding-main.svg';

const { useBreakpoint } = Grid;

const SideBar = () => {
    const location = useLocation();
    const history = useHistory();
    const screen = useBreakpoint();
    const { store } = useStore();
    // const { formatMessage } = useIntl();

    // const { Option } = Select;

    const handleMenuClick = () => {
        if (screen.xs) store.uiStateStore.toggleSider();
    };

    // const handleChangeLocal = useCallback(
    //     value => {
    //         store.uiStateStore.setCurrentLocale(value);
    //         localStorage.setItem('locale', value);
    //     },
    //     [store.uiStateStore],
    // );

    return (
        <StyledSider
            theme="light"
            breakpoint="lg"
            collapsedWidth="0"
            collapsed={store.uiStateStore.sideBarCollapsed}
            trigger={null}
            width="272"
            onBreakpoint={broken =>
                broken
                    ? store.uiStateStore.setSideBarCollapsed(true)
                    : store.uiStateStore.setSideBarCollapsed(false)
            }
            style={{
                position: screen.xs ? 'fixed' : 'relative',
            }}
        >
            {screen.xs && (
                <StyledCloseButton
                    type="text"
                    icon={<CloseOutlined />}
                    size="large"
                    onClick={() => store.uiStateStore.toggleSider()}
                />
            )}
            <StyledDiv>
                <Link to="/dashboard">
                    <img src={BrandingMain} alt="BRy Tecnologia" />
                </Link>
            </StyledDiv>
            <StyledSpace direction="vertical" size="large">
                <Menu
                    theme="light"
                    mode="inline"
                    selectedKeys={[location.pathname]}
                    style={{ transition: 'none' }}
                    onClick={handleMenuClick}
                >
                    <Menu.Item
                        key="/dashboard"
                        onClick={() => {
                            history.push('/dashboard');
                        }}
                        icon={<SearchOutlined />}
                    >
                        <FormattedMessage
                            id="app.route.dashboard.title"
                            defaultMessage="Consulta"
                            description="Consulta"
                        />
                    </Menu.Item>
                    <Menu.Item
                        key="/subject"
                        onClick={() => {
                            history.push('/subject');
                        }}
                        icon={<UserOutlined />}
                    >
                        <FormattedMessage
                            id="app.route.enroll.title"
                            defaultMessage="Sujeitos"
                            description="Sujeitos"
                        />
                    </Menu.Item>
                    <Menu.Item
                        key="/facematch"
                        onClick={() => {
                            history.push('/facematch');
                        }}
                        icon={<ScanOutlined />}
                    >
                        <FormattedMessage
                            id="app.route.facematch.title"
                            defaultMessage="Facematch"
                            description="Facematch"
                        />
                    </Menu.Item>
                    <Menu.Item
                        id="app.route.settings.title"
                        key="/settings"
                        onClick={() => {
                            history.push('/settings');
                        }}
                        icon={<SettingOutlined />}
                    >
                        <FormattedMessage
                            id="app.route.settings.title"
                            defaultMessage="Configurações"
                            description="Configurações"
                        />
                    </Menu.Item>
                </Menu>
            </StyledSpace>
            {/*
            NOT NOW, BUT MAYBE ONE DAY
            <StyledSelect
                defaultValue={store.uiStateStore.currentLocale}
                onChange={handleChangeLocal}
            >
                <Option value="pt-BR">Português - Brasil</Option>
                <Option value="en-US">English - USA</Option>
            </StyledSelect> */}
        </StyledSider>
    );
};

export default observer(SideBar);
