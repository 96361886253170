import { Button, Select, Space, Layout } from 'antd';
import styled from 'styled-components';

const { Sider } = Layout;
export const StyledSider = styled(Sider)`
    z-index: 1000;
    position: relative;
    .ant-layout-sider-children {
        display: flex;
        flex-direction: column;
        overflow: auto;
    }
`;

export const StyledSelect = styled(Select)`
    width: 220px;
    margin-left: 26px;
    margin-top: 40px;
    padding-bottom: 12px;

    @media (min-width: 992px) and (min-height: 622px) {
        position: absolute;
        bottom: 32px;
        margin-top: 0px;
        padding-bottom: 0px;
    }
`;

export const StyledButtonNew = styled(Button)`
    margin: 0 26px 12px;
    .anticon {
        margin-right: 5px;
    }
`;

export const StyledCloseButton = styled(Button)`
    position: absolute;
    right: 0px;
    top: 0;
`;

export const StyledDiv = styled.div`
    justify-content: center;
    padding: 50px 0 35px;
`;

export const StyledSpace = styled(Space)`
    width: 100%;
    margin-bottom: 35px;
`;
