import { FilePdfOutlined } from '@ant-design/icons';
import { Button, Collapse } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useStore } from 'hooks';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import api from 'services/api';
import { saveAs } from 'file-saver';
import { formatDate } from 'helpers/format';
import DatavalidResult from './DatavalidResult';
import SpcResult from './SpcResult';
import { Container, PdfButtonContent } from './styles';

const Result = (): React.ReactElement => {
    const screen = useBreakpoint();
    const intl = useIntl();
    const callback = key => {
        // eslint-disable-next-line
        console.log(key);
    };

    const { store } = useStore();
    const { result } = store.subjectValidationStore;
    const { formData } = store.subjectValidationStore;
    const datavalidResult = result?.datavalid;
    const spcResult = result?.spc;

    const pdfData: any = {
        form: formData,
        datavalid: datavalidResult,
        spc: spcResult,
    };

    const handleExportPdf = async () => {
        try {
            const response: any = await api.post(
                '/api/subject-validation/pdf',
                {
                    pdfData,
                    responseType: 'arraybuffer',
                },
            );
            const blob = new Blob([new Uint8Array(response.data.data)], {
                type: 'application/pdf',
            });
            const dateNow = new Date();
            saveAs(blob, `Consulta BRy-ID ${formatDate(dateNow)}.pdf`);
        } catch (error) {
            store.uiStateStore.setMessage(
                'error',
                intl.formatMessage({
                    id: 'app.my-documents.modal.message.error.download-signed-docs-failed',
                    defaultMessage: 'Não foi possível baixar o documento PDF.',
                }),
                3,
            );
        }
    };

    return (
        <Container mobile={!screen.md}>
            <PdfButtonContent>
                <Button
                    htmlType="submit"
                    type="primary"
                    size="small"
                    icon={<FilePdfOutlined />}
                    onClick={handleExportPdf}
                    // disabled={isSubmitting}
                    // loading={isSubmitting}
                >
                    <FormattedMessage
                        id="app.subject-validation.form.add-photo.pdf-button"
                        defaultMessage="Gerar PDF"
                    />
                </Button>
            </PdfButtonContent>
            <Collapse onChange={callback}>
                <CollapsePanel header="Datavalid" key="1">
                    <DatavalidResult
                        formData={formData}
                        result={datavalidResult}
                    />
                </CollapsePanel>
                <CollapsePanel header="SPC" key="2">
                    <SpcResult result={spcResult} />
                </CollapsePanel>
                <CollapsePanel header="Arpen" key="3" disabled extra="Em breve">
                    <p>Em breve</p>
                </CollapsePanel>
            </Collapse>
        </Container>
    );
};

export default observer(Result);
