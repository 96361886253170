/* eslint-disable @typescript-eslint/no-unused-vars */
import { action, observable, makeObservable } from 'mobx';

export default class SubjectValidationStore {
    @observable formData;

    @observable result;

    @observable takedPhoto;

    rootStore;

    transportLayer;

    // eslint-disable-next-line
    constructor(rootStore, transportLayer) {
        this.rootStore = rootStore;
        makeObservable(this);
        this.transportLayer = transportLayer;
    }

    // eslint-disable-next-line
    @action async fetchSubjectValidation(subjectData): Promise<any> {
        const response = await this.transportLayer.subjectValidation(
            subjectData,
        );
        this.setData(subjectData);
        this.setResult(response.validations);
        this.result = response.validations;
        return this.result;
    }

    // eslint-disable-next-line
    @action setData(formData): void {
        this.formData = formData;
    }

    // eslint-disable-next-line
    @action setResult(result): void {
        this.result = result;
    }

    // eslint-disable-next-line
    @action setTakedPhoto(takedPhoto): void {
        this.takedPhoto = takedPhoto;
    }
}
