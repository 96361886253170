import { CameraOutlined } from '@ant-design/icons';
import { Image } from 'antd';
import { observer } from 'mobx-react';
import React, { useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import Webcam from 'react-webcam';
import { Container, RetakePhotoButton, TakePhotoButton } from './styles';

// const WebcamComponent = () => <Webcam />;

const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: 'user',
};
interface WebcamCaptureProps {
    storeWebcamPhoto: (photo) => void;
    getStoredWebcamPhoto: () => any;
}

const WebcamCapture = ({
    storeWebcamPhoto,
    getStoredWebcamPhoto,
}: WebcamCaptureProps) => {
    // const [image, setImage] = useState('');
    const webcamRef = useRef<Webcam>(null);

    const handleCapture = useCallback(() => {
        let screenShotWidth = 600;
        let screenShotHeight = 336;

        if (
            webcamRef.current?.video?.height != null &&
            webcamRef.current?.video?.videoWidth <
                webcamRef.current?.video?.videoHeight
        ) {
            screenShotWidth = 336;
            screenShotHeight = 600;
        }
        /*  TODO: Testar se o aspectRatio dinamico funciona

        if (webcamRef.current?.video?.videoWidth != null) {
            const videoWidth = parseInt(
                webcamRef.current?.video?.videoWidth?.toString(),
                10,
            );
            const videoHeight = parseInt(
                webcamRef.current?.video?.videoHeight?.toString(),
                10,
            );
            const aspectRatio = videoWidth / videoHeight;
            screenShotHeight = screenShotWidth / aspectRatio;
        } */

        const imageSrc = webcamRef.current?.getScreenshot({
            width: screenShotWidth,
            height: screenShotHeight,
        });
        storeWebcamPhoto(imageSrc);
    }, [webcamRef, storeWebcamPhoto]);

    const handleRecapture = useCallback(() => {
        storeWebcamPhoto('');
    }, [storeWebcamPhoto]);

    return (
        <Container>
            {!getStoredWebcamPhoto() ? (
                <>
                    <Webcam
                        audio={false}
                        height={300}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width="100%"
                        videoConstraints={videoConstraints}
                    />
                    <TakePhotoButton
                        type="primary"
                        onClick={handleCapture}
                        icon={<CameraOutlined />}
                    >
                        <FormattedMessage
                            id="app.subject-validation.form.take-photo"
                            defaultMessage="Tirar foto"
                        />
                    </TakePhotoButton>
                </>
            ) : (
                <>
                    <Image alt="takedphoto" src={getStoredWebcamPhoto()} />
                    <RetakePhotoButton
                        type="default"
                        onClick={handleRecapture}
                        icon={<CameraOutlined />}
                    >
                        <FormattedMessage
                            id="app.subject-validation.form.retake-photo"
                            defaultMessage="Refazer foto"
                        />
                    </RetakePhotoButton>
                </>
            )}
        </Container>
    );
};

export default observer(WebcamCapture);
