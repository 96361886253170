import { Card, Row } from 'antd';
import { Score12Meses } from 'interfaces/subjectValidation';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { capitalizeFirstLetter } from 'helpers/format';
import {
    Container,
    CardGridInfo,
    CardGridItem,
    ScoreProgress,
    ProgressContainer,
    CardGridTittle,
} from './styles';

interface ScoreResultProps {
    result: Score12Meses;
}

const ScoreResult = ({ result }: ScoreResultProps): React.ReactElement => {
    return (
        <Container>
            {result && (
                <Row gutter={[8, 8]}>
                    <Card.Grid style={{ flex: 1 }}>
                        <CardGridTittle>
                            <FormattedMessage
                                id="app.subject-validation.result.spc-result.score-result.score"
                                defaultMessage="Score"
                            />
                        </CardGridTittle>
                        <CardGridInfo>
                            <CardGridItem>
                                <ProgressContainer>
                                    <ScoreProgress
                                        percent={result.score / 10}
                                        gapDegree={100}
                                        strokeWidth={8}
                                        strokeColor={{
                                            '0%': '#36b585',
                                            '30%': '#7dd868',
                                            '70%': '#f9b120',
                                            '100%': '#f86948',
                                        }}
                                        steps={5}
                                        type="dashboard"
                                        format={percent =>
                                            percent ? percent * 10 : percent
                                        }
                                    />
                                </ProgressContainer>
                            </CardGridItem>
                            {result.classe ? (
                                <CardGridItem>
                                    <FormattedMessage
                                        id="app.subject-validation.result.spc-result.score-result.score-class"
                                        defaultMessage="Classe: "
                                    />
                                    <strong> {result.classe}</strong>
                                </CardGridItem>
                            ) : null}
                            {result.horizonte ? (
                                <CardGridItem>
                                    <FormattedMessage
                                        id="app.subject-validation.result.spc-result.score-result.horizon"
                                        defaultMessage="Horizonte: "
                                    />
                                    <strong> {result.horizonte}</strong>
                                    <strong> meses</strong>
                                </CardGridItem>
                            ) : null}
                            {result.tipo_cliente_score ? (
                                <CardGridItem>
                                    <FormattedMessage
                                        id="app.subject-validation.result.spc-result.score-result.customer-type"
                                        defaultMessage="Tipo de cliente: "
                                    />
                                    <strong>
                                        {' '}
                                        {capitalizeFirstLetter(
                                            result.tipo_cliente_score,
                                        )}
                                    </strong>
                                </CardGridItem>
                            ) : null}
                            {result.mesagem_interpretativa_score ? (
                                <CardGridItem>
                                    <FormattedMessage
                                        id="app.subject-validation.result.spc-result.score-result.score-message"
                                        defaultMessage="Mensagem Interpretativa: "
                                    />
                                    <strong>
                                        {' '}
                                        {result.mesagem_interpretativa_score}
                                    </strong>
                                </CardGridItem>
                            ) : null}
                        </CardGridInfo>
                    </Card.Grid>
                </Row>
            )}
        </Container>
    );
};

export default observer(ScoreResult);
