import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { Form, Input, Button, Tooltip, Modal, Grid, message } from 'antd';
import useSWR from 'swr';
import { EditOutlined, LockOutlined } from '@ant-design/icons';
import loadable from '@loadable/component';
import { useHistory } from 'react-router-dom';
import api from '../../../../services/api';
import { useStore } from '../../../../hooks';
import fetcher from '../../../../helpers/fetcher';
import { cpfMask } from '../../../../helpers/mask';
import { Container, Content, Section, SectionTitle } from './styles';
import Footer from '../Footer';

const PasswordStrengthBar = loadable(
    () => import('react-password-strength-bar'),
);

const { useBreakpoint } = Grid;
const { useForm } = Form;

const Profile = (): React.ReactElement => {
    const history = useHistory();
    const screen = useBreakpoint();
    const { store } = useStore();
    const { formatMessage } = useIntl();
    const [form] = useForm();
    const [passwordForm] = useForm();
    const [isChangePasswordVisible, setIsChangePasswordVisible] =
        useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [userHasPassword, setUserHasPassword] = useState(false);

    const user = store.authStore.currentUser;
    const { data, mutate } = useSWR(`/api/v2/users/${user?.id}`, fetcher);

    useEffect(() => {
        let hasPassword = false;
        api.get(`/api/v2/users/${user?.id}/has-password`).then(response => {
            if (response.data) {
                hasPassword = true;
            }
            setUserHasPassword(hasPassword);
        });
    }, [user, userHasPassword]);

    useEffect(() => {
        store.uiStateStore.setShowFooter(false);
    }, [formatMessage, store.uiStateStore]);

    const showChangePasswordModal = () => {
        setIsChangePasswordVisible(true);
    };

    const handleChangePasswordOk = () => {
        passwordForm.validateFields().then(values => {
            api.post(`/api/v2/users/${user?.id}/change-password`, { ...values })
                .then(() => {
                    message.success(
                        formatMessage({
                            id: 'app.settings.profile.change-password-successfully',
                            defaultMessage: 'Senha alterada com sucesso.',
                        }),
                    );
                    setIsChangePasswordVisible(false);
                    passwordForm.resetFields();
                    setNewPassword('');
                })
                .catch(() => {
                    message.error(
                        formatMessage({
                            id: 'app.settings.profile.errors.change-password',
                            defaultMessage:
                                'Não foi possível alterar a senha atual.',
                        }),
                    );
                });
        });
    };

    const handleChangePasswordCancel = () => {
        setIsChangePasswordVisible(false);
        passwordForm.resetFields();
        setNewPassword('');
    };

    const handleSave = () => {
        form.validateFields().then(values => {
            api.patch(`/api/v2/users/${user?.id}`, {
                name: values.name || undefined,
                cpf: values.cpf || undefined,
                email: values.email || undefined,
                password: values.password || undefined,
            })
                .then(response => {
                    if (response.status === 200) {
                        // eslint-disable-next-line
                        const userData: any = response.data;
                        // eslint-disable-next-line
                        mutate(userData).then((mudatedUser: any) => {
                            let updatedUser;

                            if (mudatedUser) {
                                updatedUser = {
                                    id: mudatedUser.id,
                                    name: mudatedUser.name,
                                    cpf: mudatedUser.cpf,
                                    email: mudatedUser.email,
                                };
                            } else {
                                updatedUser = {
                                    id: userData.id,
                                    name: userData.name,
                                    cpf: userData.cpf,
                                    email: userData.email,
                                };
                            }

                            store.authStore.setUser(updatedUser);

                            message.success(
                                formatMessage({
                                    id: 'app.settings.profile.updated-successfully',
                                    defaultMessage:
                                        'Perfil atualizado com sucesso.',
                                }),
                            );
                        });
                    }
                })
                .catch(() => {
                    // eslint-disable-next-line
                    message.error(
                        formatMessage({
                            id: 'app.settings.profile.errors.invalid-data',
                            defaultMessage: 'Erro ao tentar atualizar perfil',
                        }),
                    );
                });
        });
    };

    const handleCancel = () => {
        history.push('/dashboard');
    };

    return (
        <Container>
            <Content mobile={!screen.md}>
                {data && (
                    <Form
                        name="profile-form"
                        form={form}
                        initialValues={data}
                        layout="vertical"
                        requiredMark={false}
                        style={{ width: 320, marginTop: 8 }}
                        validateTrigger="onSubmit"
                    >
                        <Section>
                            <SectionTitle>
                                <FormattedMessage
                                    id="app.settings.profile.personal-data"
                                    defaultMessage="Dados pessoais"
                                />
                            </SectionTitle>
                            <Form.Item
                                name="name"
                                label={
                                    <FormattedMessage
                                        id="app.settings.profile.name"
                                        defaultMessage="Nome"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: formatMessage({
                                            id: 'app.settings.profile.errors.required-field',
                                            defaultMessage: 'Campo obrigatório',
                                        }),
                                    },
                                ]}
                            >
                                <Input maxLength={40} />
                            </Form.Item>
                            <Form.Item
                                name="cpf"
                                label={
                                    <FormattedMessage
                                        id="app.settings.profile.cpf"
                                        defaultMessage="CPF"
                                    />
                                }
                                normalize={cpfMask}
                            >
                                <Input placeholder="123.456.789-00" />
                            </Form.Item>
                            <Form.Item
                                name="email"
                                label={
                                    <FormattedMessage
                                        id="app.settings.profile.email"
                                        defaultMessage="E-mail"
                                    />
                                }
                            >
                                <Input
                                    value={data?.email || undefined}
                                    type="email"
                                />
                            </Form.Item>
                        </Section>

                        <Section>
                            <SectionTitle>
                                <FormattedMessage
                                    id="app.settings.profile.account-data"
                                    defaultMessage="Dados da conta"
                                />
                            </SectionTitle>
                            {!userHasPassword && (
                                <Form.Item
                                    name="password"
                                    label={
                                        <FormattedMessage
                                            id="app.settings.profile.new-password"
                                            defaultMessage="Definir uma senha"
                                        />
                                    }
                                >
                                    <Input.Password prefix={<LockOutlined />} />
                                </Form.Item>
                            )}
                            {userHasPassword && (
                                <Form.Item
                                    label={
                                        <FormattedMessage
                                            id="app.settings.profile.password"
                                            defaultMessage="Senha"
                                        />
                                    }
                                >
                                    <Input
                                        value="******"
                                        disabled
                                        type="password"
                                        prefix={<LockOutlined />}
                                        suffix={
                                            <Tooltip
                                                title={formatMessage({
                                                    id: 'app.settings.profile.change-password',
                                                    defaultMessage:
                                                        'Alterar senha',
                                                })}
                                                placement="bottom"
                                            >
                                                <Button
                                                    type="text"
                                                    size="small"
                                                    icon={<EditOutlined />}
                                                    onClick={() =>
                                                        showChangePasswordModal()
                                                    }
                                                />
                                            </Tooltip>
                                        }
                                    />
                                </Form.Item>
                            )}
                        </Section>
                    </Form>
                )}

                <Modal
                    title={
                        <FormattedMessage
                            id="app.settings.profile.change-password"
                            defaultMessage="Alterar senha"
                        />
                    }
                    visible={isChangePasswordVisible}
                    onOk={handleChangePasswordOk}
                    onCancel={handleChangePasswordCancel}
                    okText={
                        <FormattedMessage
                            id="app.settings.profile.button.save"
                            defaultMessage="Salvar"
                        />
                    }
                    cancelText={
                        <FormattedMessage
                            id="app.settings.profile.button.cancel"
                            defaultMessage="Cancelar"
                        />
                    }
                >
                    <Form
                        form={passwordForm}
                        validateTrigger="onSubmit"
                        requiredMark={false}
                        layout="vertical"
                        style={{ width: 288 }}
                    >
                        <Form.Item
                            name="old_password"
                            label={
                                <FormattedMessage
                                    id="app.settings.profile.current-password"
                                    defaultMessage="Senha atual"
                                />
                            }
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: formatMessage({
                                        id: 'app.settings.profile.errors.required-field',
                                        defaultMessage: 'Campo obrigatório',
                                    }),
                                },
                            ]}
                        >
                            <Input.Password prefix={<LockOutlined />} />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            label={
                                <FormattedMessage
                                    id="app.settings.profile.new-password"
                                    defaultMessage="Nova senha"
                                />
                            }
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: formatMessage({
                                        id: 'app.settings.profile.errors.required-field',
                                        defaultMessage: 'Campo obrigatório',
                                    }),
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (
                                            !value ||
                                            getFieldValue('old_password') ===
                                                value
                                        ) {
                                            return Promise.reject(
                                                new Error(
                                                    formatMessage({
                                                        id: 'app.settings.profile.errors.password-equal',
                                                        defaultMessage:
                                                            'A nova senha deve ser diferente da atual',
                                                    }),
                                                ),
                                            );
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <Input.Password
                                prefix={<LockOutlined />}
                                onChange={e => setNewPassword(e.target.value)}
                            />
                        </Form.Item>
                        <PasswordStrengthBar
                            password={newPassword}
                            scoreWords={[
                                formatMessage({
                                    id: 'app.settings.password-weak',
                                    defaultMessage: 'Fraco',
                                }),
                                formatMessage({
                                    id: 'app.settings.password-okay',
                                    defaultMessage: 'Ok',
                                }),
                                formatMessage({
                                    id: 'app.settings.password-good',
                                    defaultMessage: 'Bom',
                                }),
                                formatMessage({
                                    id: 'app.settings.password-strong',
                                    defaultMessage: 'Forte',
                                }),
                            ]}
                            shortScoreWord={formatMessage({
                                id: 'app.settings.password-too-short',
                                defaultMessage: 'Muito curto',
                            })}
                        />
                    </Form>
                </Modal>
            </Content>
            <Footer onSave={handleSave} onCancel={handleCancel} />
        </Container>
    );
};

export default observer(Profile);
