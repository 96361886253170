import styled from 'styled-components';

interface ContentProps {
    readonly mobile: boolean;
}

export const Container = styled.div``;

export const Content = styled.div<ContentProps>`
    padding: ${props => (props.mobile ? '32px 16px' : '40px 48px 30px 48px')};

    display: flex;

    padding-bottom: 50px;
`;

export const Section = styled.div`
    margin-bottom: 28px;
`;

export const SectionTitle = styled.h2`
    margin-bottom: 20px;

    font-size: 24px;
    line-height: 32px;

    display: flex;
    align-items: center;

    color: #262626;
`;
