/* eslint-disable react/jsx-props-no-spreading */
import jwtDecode from 'jwt-decode';
import React, { useEffect } from 'react';
import { Route, Redirect, useLocation, RouteProps } from 'react-router-dom';

// import Idle from 'components/Idle';
// import { useStore } from 'hooks';
import api from '../services/api';
import { JWT } from '../interfaces/JWT';
import { useStore } from '../hooks';

interface AppRouteProps extends RouteProps {
    layout: React.ElementType;
    isPrivate: boolean;
    redirectTo: string; // (Opcional) Rota para redirecionamento forçado caso o usuário esteja autenticado e a rota atual seja pública
    titleId: string;
}

const AppRoute = ({
    component: Component,
    layout: Layout,
    isPrivate = false,
    redirectTo = '',
    titleId = '',
    ...rest
}: AppRouteProps): React.ReactElement | null => {
    const { store } = useStore();
    const location = useLocation();

    let token = localStorage.getItem('access_token');
    let user = localStorage.getItem('user');

    useEffect(() => {
        store.uiStateStore.setNavBarTitleForMobile(titleId);
    }, [store.uiStateStore, titleId]);

    if (user && token) {
        const dateNow = new Date();

        const decodedToken: JWT = jwtDecode(token);

        if (decodedToken && decodedToken.exp < dateNow.getTime() / 1000) {
            token = null;
            user = null;
            localStorage.clear();
        } else {
            api.defaults.headers.common.Authorization = `Bearer ${token}`;
        }
    }

    if (user && !token) {
        user = null;
        localStorage.clear();
    }

    if (!user && isPrivate) {
        const lastUrl = location.pathname + location.search;
        return <Redirect to={{ pathname: '/login', state: { lastUrl } }} />;
    }

    if (!store.subjectValidationStore.result && location.hash === '#result') {
        return <Redirect to={{ pathname: '/dashboard' }} />;
    }

    // Impede acesso a rotas públicas caso usuário esteja autenticado, por exemplo login, cadastro, recuperação de senha.
    if (user && !isPrivate && redirectTo) {
        return <Redirect to={redirectTo} />;
    }

    if (!Component) return null;

    return (
        <Route
            {...rest}
            render={matchProps => (
                <Layout>
                    <Component {...matchProps} />
                    {/* {user && token && <Idle />} */}
                </Layout>
            )}
        />
    );
};

export default AppRoute;
