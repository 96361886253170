import styled from 'styled-components';
import { Button, Input, Space } from 'antd';
import { Link } from 'react-router-dom';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
`;

export const LogoSigner = styled.div`
    width: 100%;
    margin-top: 40px;
    margin-bottom: 60px;

    img {
        margin: 0 auto;
        width: 160px;
        display: block;
    }

    @media (min-width: 768px) {
        margin-top: 116px;
        margin-bottom: 48px;

        img {
            margin: 0 auto;
            width: 226px;
            display: block;
        }
    }
`;

export const LogoBRy = styled.div`
    display: flex;
    margin-bottom: 32px;
    width: 100%;
    height: 100%;
    align-items: flex-end;

    img {
        margin: 0 auto;
        width: 111px;
        display: block;
    }

    @media (min-width: 768px) {
        margin-top: 94px;
        margin-bottom: 40px;
    }
`;

export const LoginCard = styled.div`
    background-color: #ffffff;
    width: 100%;
    padding: 0 36px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;

    .ant-alert {
        margin-bottom: 32px;
    }

    @media (min-width: 640px) {
        width: 360px;
    }

    @media (min-width: 768px) {
        padding: 40px 36px;
    }
`;

export const Title = styled.h1`
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    display: flex;
    align-items: center;
    text-align: center;
    margin: 0 auto 32px auto;
    color: #262626;
`;

export const StyledInput = styled(Input)`
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 2px;
`;

export const AceptMessage = styled.p`
    font-size: 12px;
    line-height: 20px;
    color: #595959;
`;

export const AlreadyRegistered = styled.p`
    line-height: 22px;
    text-align: center;
    margin-top: 40px;
    color: #595959;

    a {
        color: #009cda;
    }
`;

export const Email = styled.p`
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #262626;
`;

export const Error = styled.p`
    font-size: 14px;
    line-height: 22px;
    color: #f5222d;
    text-align: left;
    margin-bottom: 8px;
`;

export const StyledSpace = styled(Space)`
    display: flex;
`;

export const StyledPasswordRecoveryLink = styled(Link)``;

export const StyledPasswordWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledButton = styled(Button)`
    width: 100%;

    > span {
        margin-right: 12px;
    }
`;

export const StyledGoogleButton = styled(Button)`
    width: 100%;

    :hover {
        color: #db4437;
    }

    > span {
        margin-right: 12px;
    }
`;
