/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Card } from 'antd';

interface ContainerProps {
    readonly mobile: boolean;
}
export const Container = styled.div<ContainerProps>`
    padding: ${props => (props.mobile ? '32px 16px' : '0px 0px 30px 0px')};

    .ant-form-item {
        margin-bottom: 12px;
    }

    .ant-form-item-label {
        padding-bottom: 2px;
    }

    .ant-input,
    .ant-input-affix-wrapper,
    .ant-select-selector {
        border-radius: 2px !important;
    }
`;

export const StyledCard = styled(Card)`
    width: 100%;

    > span {
        margin-right: 12px;
    }
`;

export const Section = styled.div`
    margin-bottom: 28px;
`;

export const CardGridTittle = styled.h2`
    margin-bottom: 20px;

    font-size: 24px;
    line-height: 32px;

    display: flex;
    align-items: center;

    color: #262626;
`;

export const CardGridInfo = styled.div`
    margin-bottom: 20px;
    padding: 20px;
    background-color: #f7f7f7;
    max-height: min-content;
    border-radius: 20px;
    align-items: center;
    color: #262626;
`;

export const PdfButtonContent = styled.div`
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 0px 0px 20px 0px;

    .anticon {
        margin-right: 5px;
    }
`;
