/* eslint-disable @typescript-eslint/no-unused-vars */
import { action, observable, makeAutoObservable } from 'mobx';

export default class SubjectIdentifyStore {
    @observable identifyResponse;

    @observable selfie;

    @observable returnedSelfie;

    rootStore;

    transportLayer;

    // eslint-disable-next-line
    constructor(rootStore, transportLayer) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
        this.transportLayer = transportLayer;
    }

    // eslint-disable-next-line
    @action async identifySubject(selfie: Blob): Promise<any> {
        const response = await this.transportLayer.identifySubject(selfie);
        this.setIdentifyResponse(response);
        this.setReturnedSelfie(response.subject?.selfie);
        if (response.subject) delete response.subject.selfie;
        return response;
    }

    // eslint-disable-next-line
    @action setIdentifyResponse(identifyResponse): void {
        this.identifyResponse = identifyResponse;
    }

    // eslint-disable-next-line
    @action setReturnedSelfie(selfie): void {
        this.returnedSelfie = selfie;
    }

    // eslint-disable-next-line
    @action setSelfie(selfie): void {
        this.selfie = selfie;
    }
}
