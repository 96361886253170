import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import ptBR from 'antd/lib/locale/pt_BR';
import { observer } from 'mobx-react';
import GlobalStyle from './styles/global';
import Routes from './routes';
import './App.less';

import { useStore } from './hooks';

function App() {
    const { store } = useStore();

    return (
        <IntlProvider
            locale={store.uiStateStore.currentLocale}
            key={store.uiStateStore.currentLocale}
            messages={
                store.uiStateStore.messages[store.uiStateStore.currentLocale]
            }
            onError={err => {
                if (err.code === 'MISSING_TRANSLATION') {
                    // eslint-disable-next-line
                    console.warn('Missing translation', err.message);
                    return;
                }
                throw err;
            }}
        >
            <ConfigProvider
                locale={
                    store.uiStateStore.currentLocale === 'en-US' ? enUS : ptBR
                }
            >
                <Router>
                    <Routes />
                    <GlobalStyle />
                </Router>
            </ConfigProvider>
        </IntlProvider>
    );
}

export default observer(App);
