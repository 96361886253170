/* eslint-disable @typescript-eslint/no-unused-vars */
import { action, observable, makeAutoObservable } from 'mobx';

export default class SubjectVerifyWithDocStore {
    @observable searchResponse;

    @observable verifyResponse;

    @observable selfie;

    @observable docPhoto;

    rootStore;

    transportLayer;

    // eslint-disable-next-line
    constructor(rootStore, transportLayer) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
        this.transportLayer = transportLayer;
    }

    // eslint-disable-next-line
    @action async faceMatch(doc: Blob | File , selfie: Blob | File ): Promise<any> {
        const response = await this.transportLayer.faceMatch(doc, selfie);
        this.setVerifyResponse(response);
        return response;
    }

    // eslint-disable-next-line
    @action setSearchResponse(searchResponse): void {
        this.searchResponse = searchResponse;
    }

    // eslint-disable-next-line
    @action setVerifyResponse(verifyResponse): void {
        this.verifyResponse = verifyResponse;
    }

    // eslint-disable-next-line
    @action setDocPhoto(docPhoto): void {
        this.docPhoto = docPhoto;
    }

    // eslint-disable-next-line
    @action setSelfie(selfie): void {
        this.selfie = selfie;
    }
}
