/* eslint-disable @typescript-eslint/no-unused-vars */
import { action, observable, makeAutoObservable } from 'mobx';

export default class SubjectVerifyStore {
    @observable searchResponse;

    @observable verifyResponse;

    @observable selfie;

    @observable returnedSelfie;

    rootStore;

    transportLayer;

    // eslint-disable-next-line
    constructor(rootStore, transportLayer) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
        this.transportLayer = transportLayer;
    }

    // eslint-disable-next-line
    @action async searchSubject(subjectSearchData): Promise<any> {
        const response = await this.transportLayer.searchSubject(
            subjectSearchData,
        );
        this.setReturnedSelfie(response.selfie);
        delete response.selfie;
        this.setSearchResponse(response);
        return response;
    }

    // eslint-disable-next-line
    @action async verifySubject(cpf: string, selfie: Blob): Promise<any> {
        const response = await this.transportLayer.verifySubject(cpf, selfie);
        this.setVerifyResponse(response);
        return response;
    }

    // eslint-disable-next-line
    @action setSearchResponse(searchResponse): void {
        this.searchResponse = searchResponse;
    }

    // eslint-disable-next-line
    @action setVerifyResponse(verifyResponse): void {
        this.verifyResponse = verifyResponse;
    }

    // eslint-disable-next-line
    @action setReturnedSelfie(selfie): void {
        this.returnedSelfie = selfie;
    }

    // eslint-disable-next-line
    @action setSelfie(selfie): void {
        this.selfie = selfie;
    }
}
