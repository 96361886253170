/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
import { observable, action, computed, makeObservable } from 'mobx';
import api from '../services/api';

declare global {
    interface Window {
        localStorage: {
            getItem: (key: string) => any;
            setItem: (key: string, value: string) => any;
        };
    }
}

interface Response {
    success: boolean;
    status?: number;
    error?: string;
}

interface User {
    id: string;
    name: string;
    email: string | null;
    cpf: string | null;
}

export default class AuthStore {
    @observable user: User | null = null;

    @observable isTemp = false;

    rootStore;

    // eslint-disable-next-line
    constructor(rootStore: any) {
        this.rootStore = rootStore;
        makeObservable(this);

        try {
            this.user = JSON.parse(localStorage.getItem('user') || 'null');
        } catch (err) {
            this.user = null;
        }
    }

    @action setUser(user: User): void {
        localStorage.setItem('user', JSON.stringify(user));
        this.user = user;
    }

    @action setIsTemp(isTemp: boolean): void {
        this.isTemp = isTemp;
    }

    @action clearUser(): void {
        this.user = null;
    }

    @computed get currentUser(): User | null {
        return this.user;
    }

    @action async signIn(email: string, password: string): Promise<Response> {
        try {
            const response: any = await api.post('/api/v2/auth/login', {
                email,
                password,
            });
            const token = response.data.access_token;
            const { user } = response.data;

            localStorage.setItem('access_token', token);
            // localStorage.setItem('refresh_token', token.refresh_token);

            this.isTemp = false;

            api.defaults.headers.common.Authorization = `Bearer ${token}`;
            try {
                const userResponse: any = await api.get(
                    `api/v2/users/${user.id}`,
                );
                const { data: userData } = userResponse;
                localStorage.setItem('user', JSON.stringify(userData));
                this.user = userData;
            } catch {
                localStorage.setItem('user', JSON.stringify(user));
                this.user = user;
            }

            return { success: true };
        } catch (err) {
            return { success: false };
        }
    }

    @action async signInWithGoogle(tokenId: string): Promise<Response> {
        try {
            const response: any = await api.post(
                '/api/v2/auth/login-with-google',
                {
                    tokenId,
                },
            );
            const token = response.data.access_token;
            const { user } = response.data;

            localStorage.setItem('access_token', token);
            // localStorage.setItem('refresh_token', token.refresh_token);

            this.isTemp = false;

            api.defaults.headers.common.Authorization = `Bearer ${token}`;
            try {
                const userResponse: any = await api.get(
                    `api/v2/users/${user.id}`,
                );
                const { data: userData } = userResponse;
                localStorage.setItem('user', JSON.stringify(userData));
                this.user = userData;
            } catch {
                localStorage.setItem('user', JSON.stringify(user));
                this.user = user;
            }

            return { success: true };
        } catch (err: any) {
            return { success: false, status: err.response.status };
        }
    }

    // @action async refreshToken(): Promise<Response> {
    //     const token = localStorage.getItem('refresh_token');
    //     try {
    //         const response = await api.post('/login', { refresh_token: token });
    //         console.log(response);

    //         // const { access_token, refresh_token } = response.data;

    //         // localStorage.setItem('token', access_token);
    //         // localStorage.setItem('refresh_token', refresh_token);

    //         // api.defaults.headers.authorization = `Bearer ${access_token}`;

    //         return { success: true };
    //     } catch (err) {
    //         return { success: false };
    //     }
    // }

    @action async recoverPassword(email: string): Promise<Response> {
        try {
            await api.post('/api/v2/recover-password', { email });
            return { success: true };
        } catch (err: any) {
            return { success: false, status: err.response.status };
        }
    }

    @action async resendCode(email: string): Promise<Response> {
        try {
            await api.post('/reSendCode', { email, testemail: email });
            return { success: true };
        } catch (err) {
            return { success: false };
        }
    }

    @action async validateCode(code: string): Promise<Response> {
        try {
            await api.post('/enableUser', { token: code.toLowerCase() });
            return { success: true };
        } catch (err) {
            return { success: false };
        }
    }

    @action async savePassword(
        email: string,
        password: string,
        optIn?: boolean,
    ): Promise<Response> {
        try {
            await api.post('/create-password', { email, password, optIn });
            return { success: true };
        } catch (err: any) {
            return {
                success: false,
                status: err.response.status,
                error: err.response.data,
            };
        }
    }

    @action isUserLoged(id: string): boolean {
        const user = localStorage.getItem('user');

        if (!user) {
            return false;
        }

        const userId = JSON.parse(user).id;

        if (userId !== id) {
            return false;
        }

        return true;
    }

    @action isUserTemp(id: string): boolean {
        if (!this.user) {
            return false;
        }

        const userId = this.user.id;

        if (userId !== id) {
            return false;
        }

        return true;
    }
}
