/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Tabs, Grid } from 'antd';
import {
    CheckOutlined,
    DeleteOutlined,
    FormOutlined,
    QuestionOutlined,
    SearchOutlined,
} from '@ant-design/icons';
import SubjectEnrollForm from './EnrollForm';
import { Container } from './styles';
import SubjectSearchForm from './SearchForm';
import SubjectDeleteForm from './DeleteForm';
import SubjectVerifyForm from './VerifyForm';
import SubjectIdentifyForm from './IdentifyForm';

const { TabPane } = Tabs;
const { useBreakpoint } = Grid;

const Subject = (): React.ReactElement => {
    const screen = useBreakpoint();
    const location = useLocation();
    const history = useHistory();
    const [selectedTab, setSelectedTab] = useState('#form');

    useEffect(() => {
        if (location.hash) {
            setSelectedTab(location.hash);
        } else {
            const hash = '#enroll';
            setSelectedTab(hash);
            history.replace(hash);
        }
    }, [history, location.hash]);

    const handleTabChange = key => {
        setSelectedTab(key);
        history.push(key);
    };

    return (
        <Container mobile={!screen.md}>
            <Tabs activeKey={selectedTab} onChange={handleTabChange}>
                <TabPane
                    tab={
                        <>
                            <FormOutlined />
                            <FormattedMessage
                                id="app.subject.enroll.form.tab"
                                defaultMessage="Cadastrar"
                            />
                        </>
                    }
                    key="#enroll"
                    style={{
                        padding: !screen.md ? '32px 20px 40px 20px' : '0px',
                    }}
                >
                    <SubjectEnrollForm />
                </TabPane>
                <TabPane
                    tab={
                        <>
                            <SearchOutlined />
                            <FormattedMessage
                                id="app.subject.search.form.tab"
                                defaultMessage="Pesquisar"
                            />
                        </>
                    }
                    key="#search"
                    style={{
                        padding: !screen.md ? '32px 20px 40px 20px' : '0px',
                    }}
                >
                    <SubjectSearchForm />
                </TabPane>
                <TabPane
                    tab={
                        <>
                            <DeleteOutlined />
                            <FormattedMessage
                                id="app.subject.delete.form.tab"
                                defaultMessage="Excluir"
                            />
                        </>
                    }
                    key="#delete"
                    style={{
                        padding: !screen.md ? '32px 20px 40px 20px' : '0px',
                    }}
                >
                    <SubjectDeleteForm />
                </TabPane>
                <TabPane
                    tab={
                        <>
                            <CheckOutlined />
                            <FormattedMessage
                                id="app.subject.verify.form.tab"
                                defaultMessage="Verificar"
                            />
                        </>
                    }
                    key="#verify"
                    style={{
                        padding: !screen.md ? '32px 20px 40px 20px' : '0px',
                    }}
                >
                    <SubjectVerifyForm />
                </TabPane>
                <TabPane
                    tab={
                        <>
                            <QuestionOutlined />
                            <FormattedMessage
                                id="app.subject.identify.form.tab"
                                defaultMessage="Identificar"
                            />
                        </>
                    }
                    key="#identify"
                    style={{
                        padding: !screen.md ? '32px 20px 40px 20px' : '0px',
                    }}
                >
                    <SubjectIdentifyForm />
                </TabPane>
            </Tabs>
        </Container>
    );
};

export default Subject;
