import { Button } from 'antd';
import styled from 'styled-components';

interface ContainerProps {
    readonly mobile: boolean;
}

export const Container = styled.div<ContainerProps>`
    padding: ${props => (props.mobile ? '32px 16px' : '40px 48px 30px 0px')};

    .ant-form-item {
        margin-bottom: 12px;
    }

    .ant-form-item-label {
        padding-bottom: 2px;
    }

    .ant-input,
    .ant-input-affix-wrapper,
    .ant-select-selector {
        border-radius: 2px !important;
    }
`;

export const Content = styled.div`
    display: flex;

    padding-bottom: 50px;
`;

export const Section = styled.div`
    margin-bottom: 28px;
`;

export const SectionTitle = styled.h2`
    margin-bottom: 20px;

    font-size: 24px;
    line-height: 32px;

    display: flex;
    align-items: center;

    color: #262626;
`;

export const FileUpload = styled.input`
    line-height: 1.5715;
    position: absolute;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: manipulation;
    height: 32px;
    padding: 4px 15px;
    font-size: 14px;
    border-radius: 2px;
    color: #595959;
    border-color: #d9d9d9;
    background: #fff;
    margin-top: 20px;
}
`;

export const FormContent = styled.div`
    display: flex;
`;

export const StyledTakePhotoButton = styled(Button)`
    svg {
        margin-right: 5px;
    }
`;
