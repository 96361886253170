import styled from 'styled-components';
import { Layout, Badge, Space, Button } from 'antd';

const { Header } = Layout;

export const StyledHeader = styled(Header)`
    background: white;
    color: #262626;
    border-bottom: 2px;
    border-bottom-style: solid;
    border-bottom-color: #d9d9d9;

    padding: 0 16px 0 9px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .ant-avatar-user {
        color: #262626;
    }

    @media (min-width: 576px) {
        padding: 0 32px;
        height: 80px;
    }
`;

export const StyledTitle = styled.span`
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    @media (min-width: 500px) {
        font-weight: normal;
        font-size: 24px;
        line-height: 32px;

        margin-left: 0;
    }
`;

export const StyledSpace = styled(Space)`
    margin-left: auto;

    .ant-avatar {
        &-user {
            margin-left: 8px;
        }
    }
`;

export const StyledBadge = styled(Badge)`
    font-size: 18px;
    vertical-align: middle;
    sup {
        width: 16px;
        height: 16px;
        display: flex;
        padding: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

export const MenuButton = styled(Button)`
    padding-top: 0;
    padding-bottom: 0;
    height: 32px;
    display: flex;
    align-items: center;
`;
