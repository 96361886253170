import axios from 'axios';
import qs from 'qs';

export default axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    },
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
});
