import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, Image, message, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { cpfMask } from 'helpers/mask';
import { isValidCpf } from 'helpers/validation';
import { useStore } from 'hooks';
import { SectionTitle } from 'pages/private/Settings/Profile/styles';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactJson from 'react-json-view';
import {
    Section,
    Container,
    Content,
    ImagePreview,
    ResultContent,
    ResultData,
} from './styles';

interface SearchResult {
    cpf: string;
    nome: string;
}

const SubjectDeleteForm = (): React.ReactElement => {
    const screen = useBreakpoint();
    const [form] = useForm();
    const { store } = useStore();
    const { confirm } = Modal;
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [searchResult, setSearchResult] = useState<SearchResult | null>(null);
    const [selfie, setSelfie] = useState(null);
    const [cpfValidated, setCpfValidated] = useState(false);

    const cpfValidator = (
        _rule: any,
        value: any,
        callback: (error?: string) => void,
    ) => {
        if (isValidCpf(value)) {
            return callback();
        }
        return callback(
            formatMessage({
                id: 'app.settings.profile.errors.cpf.invalid',
                defaultMessage: 'CPF inválido',
            }),
        );
    };

    const handleSearchSubject = () => {
        setSearchResult(null);
        setSelfie(null);
        setLoading(true);
        form.validateFields()
            .then(values => {
                store.subjectDeleteStore
                    .searchSubject(values)
                    .then(() => {
                        message.success(
                            formatMessage({
                                id: 'app.subject.search.form.success',
                                defaultMessage:
                                    'Pesquisa realizada com sucesso.',
                            }),
                        );
                        setSearchResult({
                            cpf: store.subjectDeleteStore.searchResponse.cpf,
                            nome: store.subjectDeleteStore.searchResponse.data
                                .cnh.nome,
                        });
                        setSelfie(store.subjectDeleteStore.returnedSelfie);
                        setLoading(false);
                    })
                    .catch(err => {
                        if (err.response)
                            message.error(err.response.data.message);
                        setLoading(false);
                    });
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const handleClear = () => {
        form.resetFields();
        setSearchResult(null);
        setSelfie(null);
    };

    const handleCpfInputChange = () => {
        const validCpf = isValidCpf(form.getFieldValue('cpf'));
        setCpfValidated(validCpf);
        if (form.getFieldValue('cpf').length === 14) {
            setCpfValidated(validCpf);
            form.validateFields();
        }
        if (form.getFieldValue('cpf').length === 0) {
            handleClear();
        }
    };

    const handleDeleteSubject = () => {
        if (searchResult) {
            store.subjectDeleteStore
                .deleteSubject(searchResult)
                .then(() => {
                    handleClear();
                    message.success(
                        formatMessage({
                            id: 'app.subject.delete.form.success',
                            defaultMessage: 'Sujeito excluído com sucesso.',
                        }),
                    );
                })
                .catch(err => {
                    if (err.response) message.error(err.response.data.message);
                });
        }
    };

    const showDeleteConfirm = () => {
        if (searchResult) {
            confirm({
                title: 'Tem certeza que deseja excluir o sujeito?',
                icon: <ExclamationCircleOutlined />,
                content: `Nome: ${searchResult.nome} \n CPF: ${searchResult.cpf}`,
                okText: formatMessage({
                    id: 'app.confirm.dialog.yes',
                    defaultMessage: 'Sim',
                }),
                okType: 'danger',
                cancelText: formatMessage({
                    id: 'app.confirm.dialog.no',
                    defaultMessage: 'Não',
                }),
                onOk() {
                    handleDeleteSubject();
                },
            });
        }
    };

    return (
        <Container mobile={!screen.md}>
            <Content>
                <Form
                    name="subject-enroll-form"
                    form={form}
                    layout="vertical"
                    requiredMark={false}
                    style={{ width: 420, marginTop: 8 }}
                    validateTrigger="onSubmit"
                >
                    <Section mobile={!screen.md}>
                        <SectionTitle>
                            <FormattedMessage
                                id="app.subject-enroll.form.subject-data"
                                defaultMessage="Dados do sujeito"
                            />
                        </SectionTitle>
                        <h3>
                            <FormattedMessage
                                id="app.subject.delete.form.delete.first.info"
                                defaultMessage="Primeiro, informe qual sujeito você deseja excluir: "
                            />
                        </h3>
                        <Form.Item
                            name="cpf"
                            validateStatus="success"
                            label={
                                <FormattedMessage
                                    id="app.subject-validation.form.cpf"
                                    defaultMessage="CPF *"
                                />
                            }
                            normalize={cpfMask}
                            rules={[
                                {
                                    required: true,
                                    pattern: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
                                    message: formatMessage({
                                        id: 'app.subject-validation.form.errors.cpf-pattern',
                                        defaultMessage:
                                            'Preencha um CPF válido, como 123.456.789-01',
                                    }),
                                },
                                {
                                    validator: cpfValidator,
                                },
                            ]}
                        >
                            <Input.Search
                                placeholder="Ex: 123.456.789-00"
                                onChange={handleCpfInputChange}
                                allowClear
                                enterButton={
                                    <Button
                                        type="primary"
                                        onClick={handleSearchSubject}
                                        disabled={!cpfValidated}
                                        loading={loading}
                                    >
                                        Buscar
                                    </Button>
                                }
                            />
                        </Form.Item>
                    </Section>
                </Form>
            </Content>
            <ResultContent>
                <Section mobile={!screen.md}>
                    {searchResult && (
                        <>
                            <SectionTitle>
                                <FormattedMessage
                                    id="app.subject-enroll.form.result"
                                    defaultMessage="Resultado"
                                />
                            </SectionTitle>
                            <h3>
                                <FormattedMessage
                                    id="app.subject.delete.form.delete.second.info"
                                    defaultMessage="Agora, verifique os dados do sujeito e clique em excluir: "
                                />
                            </h3>
                        </>
                    )}
                </Section>
                <ResultData>
                    {selfie && (
                        <ImagePreview>
                            <Image
                                alt="selfie"
                                src={`data:image/png;base64,${selfie}`}
                                width="300px"
                                height="auto"
                            />
                        </ImagePreview>
                    )}
                    {searchResult && (
                        <>
                            <ReactJson
                                src={searchResult}
                                name={null}
                                enableClipboard={false}
                                displayDataTypes={false}
                                displayObjectSize={false}
                            />
                            <Button
                                type="primary"
                                danger
                                style={{ width: 80, marginTop: 10 }}
                                onClick={showDeleteConfirm}
                            >
                                Excluir
                            </Button>
                        </>
                    )}
                </ResultData>
            </ResultContent>
        </Container>
    );
};

export default SubjectDeleteForm;
