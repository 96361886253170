import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Tabs, Grid } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Container } from './styles';
import Profile from './Profile';

const { TabPane } = Tabs;
const { useBreakpoint } = Grid;

const Settings = (): React.ReactElement => {
    const screen = useBreakpoint();
    const history = useHistory();
    const location = useLocation();
    const [selectedTab, setSelectedTab] = useState('#profile');

    useEffect(() => {
        if (location.hash) {
            setSelectedTab(location.hash);
        } else {
            const hash = '#profile';
            setSelectedTab(hash);
            history.replace(hash);
        }
    }, [history, location.hash]);

    const handleTabChange = key => {
        setSelectedTab(key);
        history.push(key);
    };

    return (
        <Container mobile={!screen.md}>
            <Tabs activeKey={selectedTab} onChange={handleTabChange}>
                <TabPane
                    tab={
                        <>
                            <UserOutlined />
                            <FormattedMessage
                                id="app.settings.my-profile"
                                defaultMessage="Meu perfil"
                            />
                        </>
                    }
                    key="#profile"
                    style={{
                        padding: !screen.md ? '32px 20px 40px 20px' : '0px',
                    }}
                >
                    <Profile />
                </TabPane>
            </Tabs>
        </Container>
    );
};

export default Settings;
