import { Card, Row } from 'antd';
import { InformacoesFinanceiras } from 'interfaces/subjectValidation';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatDate } from 'helpers/format';
import {
    Container,
    CardGridInfo,
    CardGridItem,
    CardGridTittle,
} from './styles';

interface InformacoesFinanceirasResultProps {
    result: InformacoesFinanceiras;
}

const FinancialInformationsResult = ({
    result,
}: InformacoesFinanceirasResultProps): React.ReactElement => {
    return (
        <Container>
            {result && (
                <Row gutter={[8, 8]}>
                    <Card.Grid>
                        <CardGridTittle>
                            <FormattedMessage
                                id="validation.result.spc-result.score-result.financial-pendencies"
                                defaultMessage="Pendências financeiras (resumo)"
                            />
                        </CardGridTittle>
                        <CardGridInfo>
                            <CardGridItem>
                                <FormattedMessage
                                    id="validation.result.spc-result.score-result.quantity"
                                    defaultMessage="Quantidade: "
                                />
                                <strong>
                                    {' '}
                                    {
                                        result.pendencia_financeira.resumo
                                            .quantidade_total
                                    }
                                </strong>
                            </CardGridItem>
                            {result.pendencia_financeira.resumo
                                .quantidade_total > 0 && (
                                <CardGridItem>
                                    <FormattedMessage
                                        id="validation.result.spc-result.score-result.last-occurrence-date"
                                        defaultMessage="Data da última ocorrência:"
                                    />
                                    <strong>
                                        {' '}
                                        {formatDate(
                                            result.pendencia_financeira.resumo
                                                .data_ultima_ocorrencia,
                                        )}
                                    </strong>
                                </CardGridItem>
                            )}
                            {result.pendencia_financeira.resumo
                                .quantidade_total > 0 && (
                                <CardGridItem>
                                    <FormattedMessage
                                        id="validation.result.spc-result.score-result.total-value"
                                        defaultMessage="Valor total: "
                                    />
                                    <strong>
                                        {'R$ '}
                                        {
                                            result.pendencia_financeira.resumo
                                                .valor_total
                                        }
                                    </strong>
                                </CardGridItem>
                            )}
                        </CardGridInfo>
                    </Card.Grid>
                    {result.pendencia_financeira.detalhes.map(pendency => {
                        return (
                            <Card.Grid>
                                <CardGridTittle>
                                    <FormattedMessage
                                        id="validation.result.spc-result.score-result.pendency"
                                        defaultMessage="Pendência"
                                    />
                                </CardGridTittle>
                                <CardGridInfo>
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="validation.result.spc-result.score-result.agreement"
                                            defaultMessage="Contrato:"
                                        />
                                        <strong> {pendency.contrato}</strong>
                                    </CardGridItem>
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="validation.result.spc-result.score-result.occurrence-date"
                                            defaultMessage="Data da ocorrência:"
                                        />
                                        <strong>
                                            {' '}
                                            {formatDate(
                                                pendency.data_ocorrencia,
                                            )}
                                        </strong>
                                    </CardGridItem>
                                    {pendency.filial ? (
                                        <CardGridItem>
                                            <FormattedMessage
                                                id="validation.result.spc-result.score-result.branch"
                                                defaultMessage="Filial:"
                                            />
                                            <strong> {pendency.filial}</strong>
                                        </CardGridItem>
                                    ) : null}
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="validation.result.spc-result.score-result.origin"
                                            defaultMessage="Origem:"
                                        />
                                        <strong> {pendency.origem}</strong>
                                    </CardGridItem>
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="validation.result.spc-result.score-result.occurrence-name"
                                            defaultMessage="Título da ocorrência:"
                                        />
                                        <strong>
                                            {' '}
                                            {pendency.titulo_ocorrencia}
                                        </strong>
                                    </CardGridItem>
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="validation.result.spc-result.score-result.guarantor"
                                            defaultMessage="Avalista:"
                                        />
                                        <strong>
                                            {' '}
                                            {pendency.avalista === 'true'
                                                ? 'Sim'
                                                : 'Não'}
                                        </strong>
                                    </CardGridItem>
                                    {pendency.indicador_subjudice ? (
                                        <CardGridItem>
                                            <FormattedMessage
                                                id="validation.result.spc-result.score-result.subjudice-indicator"
                                                defaultMessage="Indicador subjúdice:"
                                            />
                                            <strong>
                                                {' '}
                                                {pendency.indicador_subjudice ===
                                                'true'
                                                    ? 'Sim'
                                                    : 'Não'}
                                            </strong>
                                        </CardGridItem>
                                    ) : null}
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="validation.result.spc-result.score-result.pendency-value"
                                            defaultMessage="Valor da pendência:"
                                        />
                                        <strong>
                                            {' '}
                                            <strong>
                                                {pendency.moeda_simbolo}
                                            </strong>{' '}
                                            {pendency.valor_pendencia}
                                        </strong>{' '}
                                    </CardGridItem>
                                    {pendency.anotacao ? (
                                        <CardGridItem>
                                            <FormattedMessage
                                                id="validation.result.spc-result.score-result.note"
                                                defaultMessage="Anotação:"
                                            />
                                            <strong>
                                                {' '}
                                                {pendency.anotacao}
                                            </strong>
                                        </CardGridItem>
                                    ) : null}
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="validation.result.spc-result.score-result.city"
                                            defaultMessage="Cidade:"
                                        />
                                        <strong>
                                            {' '}
                                            {pendency.cidade?.nome}
                                        </strong>
                                    </CardGridItem>
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="validation.result.spc-result.score-result.state"
                                            defaultMessage="Estado:"
                                        />
                                        <strong>
                                            {' '}
                                            <strong>
                                                {
                                                    pendency.cidade?.estado
                                                        ?.sigla_uf
                                                }
                                            </strong>
                                        </strong>
                                    </CardGridItem>
                                </CardGridInfo>
                            </Card.Grid>
                        );
                    })}
                    <Card.Grid>
                        <CardGridTittle>
                            <FormattedMessage
                                id="validation.result.spc-result.score-"
                                defaultMessage="SPC (resumo)"
                            />
                        </CardGridTittle>
                        <CardGridInfo>
                            <CardGridItem>
                                <FormattedMessage
                                    id="validation.result.spc-result.score-result.quantity"
                                    defaultMessage="Quantidade: "
                                />
                                <strong> {result.spc.quantidade_total}</strong>
                            </CardGridItem>
                            {result.spc.quantidade_total !== '0' && (
                                <CardGridItem>
                                    <FormattedMessage
                                        id="validation.result.spc-result.score-result.last-occurrence-date"
                                        defaultMessage="Data da última ocorrência:"
                                    />{' '}
                                    <strong>
                                        {formatDate(
                                            result.spc.data_ultima_ocorrencia,
                                        )}
                                    </strong>
                                </CardGridItem>
                            )}
                            {result.spc.quantidade_total !== '0' && (
                                <CardGridItem>
                                    <FormattedMessage
                                        id="validation.result.spc-result.score-result.total-value"
                                        defaultMessage="Valor total: "
                                    />
                                    <strong>
                                        {'R$ '}
                                        {result.spc.valor_total}
                                    </strong>
                                </CardGridItem>
                            )}
                        </CardGridInfo>
                    </Card.Grid>
                    {result.spc.detalhes.map(detail => {
                        return (
                            <Card.Grid>
                                <CardGridTittle>
                                    <FormattedMessage
                                        id="validation.result.spc-result.score-result."
                                        defaultMessage="Serviço de proteção ao crédito"
                                    />
                                </CardGridTittle>
                                <CardGridInfo>
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="validation.result.spc-result.score-result.agreement"
                                            defaultMessage="Nome associado:"
                                        />
                                        <strong>
                                            {' '}
                                            {detail.nome_associado}
                                        </strong>
                                    </CardGridItem>
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="validation.result.spc-result.score-result.occurrence-date"
                                            defaultMessage="Nome da entidade:"
                                        />
                                        <strong> {detail.nome_entidade}</strong>
                                    </CardGridItem>
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="validation.result.spc-result.score-result.branch"
                                            defaultMessage="Valor:"
                                        />
                                        <strong> R$ {detail.valor} </strong>
                                    </CardGridItem>
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="validation.result.spc-result.score-result.origin"
                                            defaultMessage="Instituição financeira possui registro?:"
                                        />

                                        <strong>
                                            {' '}
                                            {detail.registro_instituicao_financeira ===
                                            'true'
                                                ? 'Sim'
                                                : 'Não'}
                                        </strong>
                                    </CardGridItem>
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="validation.result.spc-result.score-result.occurrence-name"
                                            defaultMessage="Data de inclusão:"
                                        />
                                        <strong>
                                            {' '}
                                            {formatDate(detail.data_inclusao)}
                                        </strong>
                                    </CardGridItem>
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="validation.result.spc-result.score-result.guarantor"
                                            defaultMessage="Data de vencimento:"
                                        />
                                        <strong>
                                            {' '}
                                            {formatDate(detail.data_vencimento)}
                                        </strong>
                                    </CardGridItem>
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="validation.result.spc-result.score-result.subjudice-indicator"
                                            defaultMessage="Contrato:"
                                        />
                                        <strong> {detail.contrato}</strong>
                                    </CardGridItem>
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="validation.result.spc-result.score-result.detail-value"
                                            defaultMessage="Comprador fiador avalista:"
                                        />
                                        <strong>
                                            {detail.comprador_fiador_avalista}
                                        </strong>
                                    </CardGridItem>
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="validation.result.spc-result.score-result.note"
                                            defaultMessage="Código da entidade:"
                                        />
                                        <strong>
                                            {' '}
                                            {detail.codigo_entidade}
                                        </strong>
                                    </CardGridItem>
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="validation.result.spc-result.score-result.city"
                                            defaultMessage="Cidade:"
                                        />
                                        <strong> {detail.cidade?.nome}</strong>
                                    </CardGridItem>
                                    <CardGridItem>
                                        <FormattedMessage
                                            id="validation.result.spc-result.score-result.state"
                                            defaultMessage="Estado:"
                                        />
                                        <strong>
                                            {' '}
                                            <strong>
                                                {
                                                    detail.cidade?.estado
                                                        ?.sigla_uf
                                                }
                                            </strong>
                                        </strong>
                                    </CardGridItem>
                                </CardGridInfo>
                            </Card.Grid>
                        );
                    })}
                    <Card.Grid>
                        <CardGridTittle>
                            <FormattedMessage
                                id="validation.result.spc-result.score-"
                                defaultMessage="Protesto (resumo)"
                            />
                        </CardGridTittle>
                        <CardGridInfo>
                            <CardGridItem>
                                <FormattedMessage
                                    id="validation.result.spc-result.score-result.quantity"
                                    defaultMessage="Quantidade: "
                                />
                                <strong>
                                    {' '}
                                    {result.protesto.quantidade_total}
                                </strong>
                            </CardGridItem>
                            {result.protesto.quantidade_total !== '0' && (
                                <CardGridItem>
                                    <FormattedMessage
                                        id="validation.result.spc-result.score-result.last-occurrence-date"
                                        defaultMessage="Data da primeire ocorrência:"
                                    />
                                    <strong>
                                        {' '}
                                        {formatDate(
                                            result.protesto
                                                .data_primeira_ocorrencia,
                                        )}
                                    </strong>
                                </CardGridItem>
                            )}
                            {result.protesto.quantidade_total !== '0' && (
                                <CardGridItem>
                                    <FormattedMessage
                                        id="validation.result.spc-result.score-result.last-occurrence-date"
                                        defaultMessage="Data da última ocorrência:"
                                    />{' '}
                                    <strong>
                                        {formatDate(
                                            result.protesto
                                                .data_ultima_ocorrencia,
                                        )}
                                    </strong>
                                </CardGridItem>
                            )}
                            {result.protesto.quantidade_total !== '0' && (
                                <CardGridItem>
                                    <FormattedMessage
                                        id="validation.result.spc-result.score-result.total-value"
                                        defaultMessage="Valor total: "
                                    />
                                    <strong>
                                        {'R$ '}
                                        {result.protesto.valor_total}
                                    </strong>
                                </CardGridItem>
                            )}
                        </CardGridInfo>
                    </Card.Grid>
                    {result.protesto.detalhes.length > 0 &&
                        result.protesto.detalhes.map(protest => {
                            return (
                                <Card.Grid>
                                    <CardGridTittle>
                                        <FormattedMessage
                                            id="validation.result.spc-result.score-result."
                                            defaultMessage="Protesto"
                                        />
                                    </CardGridTittle>
                                    <CardGridInfo>
                                        <CardGridItem>
                                            <FormattedMessage
                                                id="validation.result.spc-result.score-result.agreement"
                                                defaultMessage="Valor:"
                                            />
                                            <strong>
                                                {'R$ '}
                                                {protest.valor}
                                            </strong>
                                        </CardGridItem>
                                        <CardGridItem>
                                            <FormattedMessage
                                                id="validation.result.spc-result.occurrence-date"
                                                defaultMessage="Data:"
                                            />
                                            <strong>
                                                {' '}
                                                {formatDate(
                                                    protest.data_protesto,
                                                )}
                                            </strong>
                                        </CardGridItem>
                                        <CardGridItem>
                                            <FormattedMessage
                                                id="validation.result.spc-result.branch"
                                                defaultMessage="Nome do cartórtio:"
                                            />
                                            <strong>
                                                {' '}
                                                {protest.cartorio.nome}
                                            </strong>
                                        </CardGridItem>
                                        <CardGridItem>
                                            <FormattedMessage
                                                id="validation.result.spc-result.origin"
                                                defaultMessage="Cidade do cartório:"
                                            />

                                            <strong>
                                                {' '}
                                                {protest.cartorio.cidade.nome}
                                            </strong>
                                        </CardGridItem>
                                        <CardGridItem>
                                            <FormattedMessage
                                                id="validation.result.spc-result.occurrence-name"
                                                defaultMessage="Estado do cartório:"
                                            />
                                            <strong>
                                                {' '}
                                                {
                                                    protest.cartorio.cidade
                                                        .estado.sigla_uf
                                                }
                                            </strong>
                                        </CardGridItem>
                                    </CardGridInfo>
                                </Card.Grid>
                            );
                        })}
                </Row>
            )}
        </Container>
    );
};

export default observer(FinancialInformationsResult);
