import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Form, Input } from 'antd';
import {
    MailOutlined,
    UnlockOutlined,
    GoogleOutlined,
} from '@ant-design/icons';
import { observer } from 'mobx-react';
import { GoogleLogin } from 'react-google-login';
import { useStore } from '../../../hooks';
import logoGray from '../../../assets/images/logo-bry-gray.svg';
// import { useMixPanel } from 'hooks/mixpanel';
import {
    Title,
    // AlreadyRegistered,
    LogoSigner,
    LoginCard,
    Container,
    LogoBRy,
    // StyledPasswordRecoveryLink,
    StyledButton,
    StyledGoogleButton,
} from './styles';

interface LocationProps {
    lastUrl: string;
    name: string;
    email: string;
}

const { useForm } = Form;

const Login = (): React.ReactElement => {
    const intl = useIntl();
    const { store } = useStore();
    const history = useHistory();
    const location = useLocation<LocationProps>();
    const [form] = useForm();
    const [error, setError] = useState(false);
    const clientId =
        '47885853985-297215pkmsj173m3evsmqhru6a5av0lu.apps.googleusercontent.com';
    // const [loading, setLoading] = useState(false);
    // const [recoverEmail, setRecoverEmail] = useState('');
    const onLoginSuccess = async (googleResponse: any) => {
        const { tokenId } = googleResponse;
        const { name, email } = googleResponse.profileObj;
        const resp = await store.authStore.signInWithGoogle(tokenId);
        if (resp?.success) {
            if (location && location.state && location.state.lastUrl) {
                history.push(location.state.lastUrl);
            } else {
                history.push('/dashboard');
            }
        } else if (resp.status === 400) {
            store.uiStateStore.setMessage(
                'error',
                intl.formatMessage({
                    id: 'app.register-with-google.not-possible-register',
                    defaultMessage: 'Não foi possível realizar o login.',
                }),
                3,
            );
        } else if (resp.status === 401) {
            history.push({
                pathname: '/create-account-with-google',
                state: { name, email, tokenId },
            });
        }
        // setError(true);
    };

    const onLoginFailure = (res: any) => {
        // eslint-disable-next-line
        console.log('Login failed:', res);
    };

    const onSubmit = async (data: any) => {
        // setLoading(true);
        const { email, password } = data;
        const resp = await store.authStore.signIn(email, password);

        if (resp?.success) {
            if (location && location.state && location.state.lastUrl) {
                history.push(location.state.lastUrl);
            } else {
                history.push('/dashboard');
            }
        } else {
            setError(true);
        }

        // setLoading(false);
    };

    // useEffect(() => {
    //     store.uiStateStore.resetRecoverWizard();
    // }, [store]);

    useEffect(() => {
        if (location.state && location.state.email) {
            form.setFieldsValue({
                email: location.state.email,
            });
        }
    }, [form, location.state]);
    return (
        <Container>
            <LogoSigner>{/* <img src={logo} alt="Logo" /> */}</LogoSigner>
            <LoginCard>
                {error && (
                    <Alert
                        message={intl.formatMessage(
                            {
                                id: 'app.login.error.user-or-password-incorrect',
                            },
                            {
                                passwordRecoveryLink: (
                                    <Link to="/recover-password">
                                        <FormattedMessage id="app.register.click-here" />
                                    </Link>
                                ),
                                registerLink: (
                                    <Link to="/create-account">
                                        <FormattedMessage id="app.login.click-here" />
                                    </Link>
                                ),
                            },
                        )}
                        type="error"
                        showIcon
                    />
                )}
                <Title>
                    <FormattedMessage id="app.login.title" />
                </Title>

                <Form
                    name="login-form"
                    form={form}
                    size="large"
                    onFinish={data => onSubmit(data)}
                    validateTrigger="onSubmit"
                >
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: intl.formatMessage({
                                    id: 'app.login.error.email-required',
                                }),
                            },
                            {
                                type: 'email',
                                message: intl.formatMessage({
                                    id: 'app.login.error.email-pattern',
                                }),
                            },
                        ]}
                        style={{ textAlign: 'left' }}
                    >
                        <Input
                            placeholder={intl.formatMessage({
                                id: 'app.login.email-placeholder',
                            })}
                            prefix={
                                <MailOutlined style={{ color: '#009CDA' }} />
                            }
                            // onChange={e => setRecoverEmail(e.target.value)}
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: intl.formatMessage({
                                    id: 'app.login.error.password-required',
                                }),
                            },
                        ]}
                        style={{ textAlign: 'left' }}
                    >
                        <Input.Password
                            placeholder={intl.formatMessage({
                                id: 'app.login.password-placeholder',
                            })}
                            prefix={
                                <UnlockOutlined style={{ color: '#009CDA' }} />
                            }
                        />
                    </Form.Item>

                    {/* <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginBottom: 24,
                        }}
                    >
                        <StyledPasswordRecoveryLink
                            to={{
                                pathname: '/recover-password',
                                state: { email: recoverEmail },
                            }}
                        >
                            <FormattedMessage id="app.recover.title" />
                        </StyledPasswordRecoveryLink>
                    </div> */}

                    <Form.Item>
                        <StyledButton
                            htmlType="submit"
                            type="primary"
                            size="large"
                            style={{ width: '100%' }}
                            // loading={loading}
                        >
                            <FormattedMessage id="app.login.confirm-button" />
                        </StyledButton>
                    </Form.Item>
                    <Form.Item>
                        <GoogleLogin
                            clientId={clientId}
                            render={renderProps => (
                                <StyledGoogleButton
                                    onClick={renderProps.onClick}
                                    icon={<GoogleOutlined />}
                                >
                                    <FormattedMessage id="app.login-with-google.confirm-button" />
                                </StyledGoogleButton>
                            )}
                            buttonText="Entrar com Google"
                            onSuccess={onLoginSuccess}
                            onFailure={onLoginFailure}
                            cookiePolicy="single_host_origin"
                        />
                    </Form.Item>
                </Form>

                {/*
                ************** Remove comment after e-mail validation has been added to backend ***************
                <AlreadyRegistered>
                    <FormattedMessage
                        id="app.login.already-registered"
                        values={{
                            link: (
                                <Link to="/create-account">
                                    <FormattedMessage id="app.login.register.title" />
                                </Link>
                            ),
                        }}
                    />
                </AlreadyRegistered> */}
            </LoginCard>
            <LogoBRy>
                <img src={logoGray} alt="Logo" />
            </LogoBRy>
        </Container>
    );
};

export default observer(Login);
