/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Card, Progress } from 'antd';

export const Container = styled.div`
    padding: 20px;
`;

export const CardFail = styled(Card)`
    display: flex;
    border: none;
    align-items: center;
    justify-content: center;
`;

export const CardNoData = styled(Card)`
    display: flex;
    border: none;
    align-items: center;
    justify-content: center;
`;

export const CardFailInfo = styled.div`
    background-color: #f7f8fb;
    padding: 10px;
    border-radius: 8px;
    svg {
        color: red;
        margin: 0px 20px;
    }
`;

export const CardNoDataInfo = styled.div`
    background-color: #f7f8fb;
    padding: 20px;
    border-radius: 8px;
    height: auto;

    svg {
        color: grey;
        margin-right: 20px;
    }
`;

export const ProgressSimilarity = styled(Progress)``;
