import React, { useCallback, useState } from 'react';
import { MobXProviderContext } from 'mobx-react';
import useSWR from 'swr';
import QueryString from 'qs';
import RootStore from '../stores';
import fetcher from '../helpers/fetcher';

const ERROR_RETRY_COUNT = 3;

export function useStore(): Record<string, RootStore> {
    return React.useContext(MobXProviderContext);
}

export const useViewport = (): { width: number } => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    return { width };
};

export const useStateRef = (
    // eslint-disable-next-line
    processNode: any,
    // eslint-disable-next-line
): [any, (newNode: any) => void] => {
    const [node, setNode] = useState<React.ReactNode | null>(null);
    const setRef = useCallback(
        newNode => {
            setNode(processNode(newNode));
        },
        [processNode],
    );
    return [node, setRef];
};

// eslint-disable-next-line
export const useSubscriptions = () => {
    const { data, error, mutate } = useSWR(`/subscriptions`, fetcher, {
        errorRetryCount: ERROR_RETRY_COUNT,
    });

    return {
        data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    };
};

// eslint-disable-next-line
export const useSubscriptionSchedules = () => {
    const { data, error, mutate } = useSWR(`/schedule-subscriptions`, fetcher);

    return {
        data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    };
};

// eslint-disable-next-line
export const useUsage = () => {
    const { data, error, mutate } = useSWR('/usage', fetcher, {
        errorRetryCount: ERROR_RETRY_COUNT,
    });

    return {
        data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    };
};

// eslint-disable-next-line
export const usePlanTransactions = (params?: {
    limit: number;
    page: number;
}) => {
    const query = QueryString.stringify(params);

    const { data, error, mutate } = useSWR(
        query ? `/plan-transactions?${query}` : '/plan-transactions',
        fetcher,
    );

    return {
        data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    };
};
