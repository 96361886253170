import { ExclamationCircleOutlined, FrownOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { SpcResultInterface } from 'interfaces/subjectValidation';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import FinancialInformationsResult from './FinancialInformationsResult';
import PersonalDataResult from './PersonalDataResult';
import ScoreResult from './ScoreResult';
import {
    CardFail,
    CardFailInfo,
    CardNoData,
    CardNoDataInfo,
    Container,
} from './styles';

interface SpcResultProps {
    result: SpcResultInterface;
}

const { TabPane } = Tabs;

const SpcResult = ({ result }: SpcResultProps): React.ReactElement => {
    const screen = useBreakpoint();
    const [selectedTab, setSelectedTab] = useState('#personaldata');
    const personalDataResult = result?.data?.buscar_pf;
    const scoreResult = result?.data?.score_12_meses;
    const financialInformationsResult = result?.data?.informacoes_financeiras;

    const handleTabChange = key => {
        setSelectedTab(key);
    };

    return (
        <Container>
            {result.success && (
                <>
                    {result.data.buscar_pf.nome ===
                    'CADASTRO NAO LOCALIZADO' ? (
                        <CardNoData>
                            <CardNoDataInfo>
                                <FrownOutlined />
                                <FormattedMessage
                                    id="app.subject-validation.result.spc.no-data"
                                    defaultMessage="A consulta no SPC não encontrou nenhum dado com esse CPF."
                                />
                            </CardNoDataInfo>
                        </CardNoData>
                    ) : (
                        <Tabs
                            activeKey={selectedTab}
                            onChange={handleTabChange}
                        >
                            <TabPane
                                tab={
                                    <>
                                        <FormattedMessage
                                            id="app.subject-validation.result.spc.personal-data"
                                            defaultMessage="Dados de pessoa física"
                                        />
                                    </>
                                }
                                key="#personaldata"
                                style={{
                                    padding: !screen.md
                                        ? '32px 20px 40px 20px'
                                        : '0px',
                                }}
                            >
                                <PersonalDataResult
                                    result={personalDataResult}
                                />
                            </TabPane>
                            <TabPane
                                tab={
                                    <>
                                        <FormattedMessage
                                            id="app.subject-validation.result.spc.score"
                                            defaultMessage="Score"
                                        />
                                    </>
                                }
                                key="#score"
                                style={{
                                    padding: !screen.md
                                        ? '32px 20px 40px 20px'
                                        : '0px',
                                }}
                            >
                                <ScoreResult result={scoreResult} />
                            </TabPane>
                            <TabPane
                                tab={
                                    <>
                                        <FormattedMessage
                                            id="app.subject-validation.result.spc.financial-informations"
                                            defaultMessage="Informações financeiras"
                                        />
                                    </>
                                }
                                key="#financial"
                                style={{
                                    padding: !screen.md
                                        ? '32px 20px 40px 20px'
                                        : '0px',
                                }}
                            >
                                <FinancialInformationsResult
                                    result={financialInformationsResult}
                                />
                            </TabPane>
                        </Tabs>
                    )}
                </>
            )}

            {!result.success && (
                <CardFail>
                    <CardFailInfo>
                        <ExclamationCircleOutlined />
                        <FormattedMessage
                            id="app.subject-validation.result.spc.fail"
                            defaultMessage="A validação com o SPC falhou. Tente novamente mais tarde."
                        />
                    </CardFailInfo>
                </CardFail>
            )}
        </Container>
    );
};

export default observer(SpcResult);
